import {useLocation} from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {StickyLink} from 'sections/News/NewsLayout/styles.js';
import {useArticleImages} from './ArticleImages';

export const News_55 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Akcja mikołajkowa 2022',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '09 / 12 / 2022',
    description: 'Zbliża się magiczny czas i akcja, na którą czekaliśmy caaały rok!',
    slug: '/wydarzenia/akcja-mikolajkowa-2022',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉</span>
            <span role="img" aria-label="">🎄</span>
            Zbliża się magiczny czas i akcja na którą czekaliśmy caaały rok!
            <br/>
            <br/>
            Nie tylko my... to czas najbardziej wyczekiwany przez naszych <strong>podopiecznych</strong>
            <span role="img" aria-label=""> 🥰</span>
            <br/>
            <br/>
            <span role="img" aria-label=""> 🔸</span>
            O czym marzą dzieciaki, kiedy zbliża się grudzień? O <stron>prezentach</stron>
            <span role="img" aria-label=""> ‼️</span>
            <br/>
            <br/>
            Jesteśmy bardzo szczęśliwi, że kolejny raz możemy zostać #ŚWIĘTYMI #MIKOŁAJAMI <span role="img" aria-label=""> 🎅️</span>
            <br/>
            <br/>
            Otrzymaliśmy listy przygotowane i napisane przez naszych podopiecznych i wraz z naszymi pracowitymi elfami z <strong>wieeelkimi</strong> sercami, zabieramy się do pracy! <br/>
            <strong>Na czym polega akcja</strong>
          </p>,
      },
      second: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉 </span>
            Jeśli jeszcze nie wiecie, Mikołajki to już nasza coroczna tradycja!
            <br/>
            Trzeci raz organizujemy tę piękną akcję! Nasi podopieczni mają za zadanie napisać listy do <strong>Świętego Mikołaja</strong>, w których zdradzą, o czym marzą i co chcieliby dostać 6 grudnia.
            <br/>
            <span role="img" aria-label="">👉 </span>
            Następnie my wraz z WASZĄ pomocą zrobimy wszystko, co w naszej mocy, aby przynajmniej raz w roku zrealizować marzenia każdego z dzieci <br/>

            To dla nas punkt honoru, aby nasi podopieczni dostali dokładnie to, o czym marzą. Jak do tej pory nigdy nie zawiedliśmy żadnego dziecka, więc i w tym roku damy z siebie wszystko.
            <span role="img" aria-label=""> 🧑 </span>
            <span role="img" aria-label="">🎄</span>
            <br/>
            <span role="img" aria-label="">🎁 </span>
            W jaki sposób możecie się zaangażować w tę akcję? Dołączcie do naszego Mikołajkowego teamu
          </p>,
      },
      third: {
        title: <></>,
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🎄</span>
              Wybierzcie jeden list (lub więcej!) napisany przez naszego podopiecznego i spełnijcie jego marzenia.
            </li>
            <li>
              <span role="img" aria-label="">🎄</span>
              Nie macie możliwości, by osobiście kupić i dostarczyć prezenty?
              <br/>
              Przekażcie nam fundusz
              <span role="img" aria-label=""> ● </span>
              <a href="https://wsparcie.fundacjaswiatbezbarier.pl/" rel="noopener" target="_blank">https://wsparcie.fundacjaswiatbezbarier.pl/</a>
              <span role="img" aria-label=""> ●</span>
              a my zrobimy to w Waszym imieniu - przygotujemy piękny prezent:)
            </li>
            <li>
              <span role="img" aria-label="">🎄</span>
              Podarujcie nam vouchery, gadżety firmowe lub wszystko inne, co mogłoby dodatkowo sprawić radość naszym dzieciakom
            </li>
          </ul>,
      },
      fourth: {
        title: '',
        text:
          <p>
            Zachęcamy do wzięcia udziału w tej <strong>pięknej</strong> akcji: możesz to zrobić wspólnie z rodziną, przyjaciółmi lub współpracownikami. <br/>
            <a href="https://www.facebook.com/hashtag/zr%C3%B3bmyrazemco%C5%9Bdobrego" target="_blank" rel="noopener">#zróbmyRAZEMcośDOBREGO!</a>
            <br/>
            <br/>
            W zeszłym roku sprawiliśmy mnóstwo radości naszym podopiecznym. <br/>
            <strong>Radość́</strong> dzieci i ich wdzięczność nakręciły nas mocno do działania na kolejnych kilka miesięcy!
            <br/>
            <br/>
            PODSUMOWANIE AKCJI MIKOŁAJKOWEJ 2021: <br/>
            <a href="https://www.fundacjaswiatbezbarier.pl/wydarzenia/final-akcji-mikolaj-2021" rel="noopener" target="_blank">https://www.fundacjaswiatbezbarier.pl/wydarzenia/final-akcji-mikolaj-2021</a>
            <br/>
            PODSUMOWANIE AKCJI MIKOŁAJKOWEJ 2020: <br/>
            <a href="https://www.fundacjaswiatbezbarier.pl/wydarzenia/mikolajki-2020" rel="noopener" target="_blank">https://www.fundacjaswiatbezbarier.pl/wydarzenia/mikolajki-2020</a>
          </p>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
  };
};

