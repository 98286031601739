import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"

import {useEventImages} from "./EventImages"

export const News_5 = () => {
  const images = useEventImages();
  const location = useLocation()
  return {
    title:            "Akcja Mikołaj 2021",
    hero_image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "12 / 11 / 2021",
    description:
                      "Święta zbliżają się już wielkimi krokami! Co prawda jest jeszcze listopad, ale już czuć tę świąteczną aurę. Dla nas jest to szczególny czas - przygotowań, organizacji i planowania wielkiej akcji - Mikołaj 2021. ",
    slug:             "/wydarzenia/mikolajki-2021",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: "Mikołaj 2021",
        text:  (
                 <>
                   Święta zbliżają się już wielkimi krokami! Co prawda jest jeszcze listopada, ale już czuć tę świąteczną aurę. Dla nas jest to szczególny czas - przygotowań, organizacji i planowania wielkiej akcji - Mikołaj 2021. Nasze zadanie to obdarowanie podopiecznych Fundacji Świat Bez Barier prezentami. Prezentami wyjątkowymi. Prezentami, które wywołają wielki i prawdziwy uśmiech na twarzy. <br/><br/>
                   Zobaczcie, jak wyglądała nasza akcja mikołajkowa rok temu. Radość, szczęście i ekscytacja. W tym roku stawiamy sobie ten sam cel! To jak? Pomożecie?
                 </>
               ),
      },
      second: {
        title: "",
        text:  <>
                 <b>Dobro zawsze wraca, więc spraw, aby 6 grudnia pojawił się uśmiech na twarzy dzieci, którymi opiekuje się Fundacja Świat Bez Barier.</b>
                 <br/>
                 Chcemy powtórzyć sukces z poprzedniego roku, dlatego potrzebujemy skompletować 26 spersonalizowanych prezentów dla podopiecznych z ośrodka opiekuńczo-wychowawczego, zebrać środki na opłacenie tematycznych wyjazdów podczas ferii zimowych.
                 <br/>
                 <br/>
                 <b>Czekamy do 5 grudnia.</b>
               </>,
      },
      third:  {
        title: "Jak możesz pomóc ?",
        text:  <>
                 - Wspomóż zbiórkę dowolnym datkiem, który zostanie przeznaczony na zakup prezentów.<br/>
                 - Posiadasz nowe zabawki, przybory szkolne, ubrania, które chciałbyś przekazać innym? Zgłoś się do nas!<br/><br/>
                 Potrzebujesz więcej informacji? Magda chętnie udzieli Ci wskazówek i sugestii. <br/>
                 Napisz na <a href="mailto:biuro@fundacjaswiatbezbarier.pl"><b>biuro@fundacjaswiatbezbarier.pl</b></a><br/>
                 lub zadzwoń: <a href="tel:+48 693 380 754"><b>+48 693 380 754</b></a>
               </>,
      }
    },
    images:           {
      first:  {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
      second: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img2.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
