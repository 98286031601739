import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_51 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Piknik Rodzinny 3.09.2022 - PODSUMOWANIE",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "03 / 09 / 2022",
    description:      "3 września na terenie Osiedla Projektant wspólnie z Beniaminek Soccer School Rzeszów zorganizowaliśmy Charytatywny Piknik Rodzinny.",
    slug:             "/wydarzenia/podsumowanie-pikniku",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:      {
        title: <></>,
        text:
               <p>
                 <strong>3 września na terenie Osiedla Projektant wspólnie z Beniaminek Soccer School Rzeszów zorganizowaliśmy Charytatywny Piknik Rodzinny</strong> <span role="img" aria-label=''>👨‍👩‍👧‍👦</span>
               </p>
      },
      second:     {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>⚽️</span> Piknik rozpoczął się o godzinie 11 przyjacielskim <strong>meczem Team Fundacja Świat Bez Barier vs Beniaminek Rodzice.</strong> Następnie odbyły się towarzyskie rozgrywki wszystkich chętnych dzieciaków, w tym podopiecznych naszej Fundacji oraz zawodników Beniaminka. <br/>
                 <span role="img" aria-label=''>🏉️</span> Nasi partnerzy z Rugby Rzeszów zorganizowali strefę, w której wszystkie chętne dzieci mogły spróbować swoich sił w rugby. <br/> <br/>
                 Zaraz po sportowych atrakcjach wszyscy mogliśmy przenieść się do świata magii i iluzji dzięki pokazowi Iluzjonisty Przemysława Wilka!
               </p>
      },
      third:      {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>🍽</span> Wszyscy obecni na pikniku mogli skosztować pysznych przysmaków przygotowanych przez naszych partnerów <strong>Smaczną Kluskę, Bake my Day oraz prywatnych darczyńców.</strong> Mini burgery czy smakowite ciasteczka rozchodziły się w mgnieniu oka! <span role="img" aria-label=''>😋</span>
               </p>
      },
      fourth:     {
        title: <></>,
        text:
               <p>
                 Na dzieci, które odwiedziły nasz Rodzinny Piknik Charytatywny, czekało wiele atrakcji. Był <strong>duży dmuchany zamek</strong> <span role="img" aria-label=''>🏰</span>, bez którego chyba nie może się odbyć żaden piknik! Na dziecięcych buźkach pojawiły się <strong>kolorowe malowidła</strong> <span role="img" aria-label=''>🎨</span>, a we włosach <strong>barwne warkoczyki</strong> <span role="img" aria-label=''>💈</span>. Nie zabrakło <strong>balonów z helem</strong> i baloników, z których nasze wolontariuszki czarowały przeróżne kształty - od mieczy po zwierzątka <span role="img" aria-label=''>🎈</span>! Wszyscy chętni mogli zrobić sobie zwariowane <strong>zdjęcie w foto-lustrze</strong> albo przybić piątkę z ogroooomnym <strong>Minionkiem!</strong><br/>
                 Podczas naszego wydarzenia odbyły się również <strong>kreatywne warsztaty tworzenia bransoletek z koralików</strong>, które poprowadziła niezastąpiona <strong>Ola z „Wool on wood”</strong> <span role="img" aria-label=''>📿</span>.
               </p>
      },
      fifth:      {
        title: '',
        text:  <p>
                 Miłośników zwierząt (i nie tylko!) zachwyciła wizyta <strong>mięciutkich i kochanych alpak</strong>, które można było nie tylko nakarmić, ale też przytulić 🦙. Nie zabrakło również miejsca na oprowadzanki na <strong>pięknych koniach</strong> <span role="img" aria-label=''>🐴</span>.
               </p>
      },
      sixth:      {
        title: '',
        text:  <p>
                 Nasi wspaniali wolontariusze serwowali <strong>aromatyczne kiełbaski i szaszłyki z grilla</strong> <span role="img" aria-label=''>🍖</span>, a z głośników wybrzmiewała muzyka <strong>zaprzyjaźnionego DJ-a</strong> <span role="img" aria-label=''>🎶</span>.
               </p>
      },
      seventh:    {
        title: '',
        text:  <p>
                 To było wspaniałe wydarzenie z rodzinną i przyjemną atmosferą. Cudownie jest obserwować radość na twarzach najmłodszych, a tej nie brakowało w tym dniu nikomu - szczególnie naszym podopiecznym, którzy wspólnie z nami tworzyli ten piknik <span role="img" aria-label=''>💜</span>. <br/> <br/>

                 Charytatywny Piknik Rodzinny nie odbyłby się bez wsparcia naszych sponsorów: firm InteractiveVision i Ania Catering. Nie odbyłby się również bez poświęconego czasu i ogromnych serc naszych partnerów, prywatnych darczyńców, wolontariuszy oraz naszych bliskich, którzy włożyli w to wydarzenie mnóstwo dobrej energii i czasu, spędzając z nami ten dzień.
                 <br/>
                 <strong>3 WRZEŚNIA PRZECHODZI DO HISTORII JAKO PIĘKNY DZIEŃ, A MY JESTEŚMY DUMNI, ŻE UDAŁO NAM SIĘ ZORGANIZOWAĆ TAKI EVENT️</strong><span role="img" aria-label=''>❤</span>
               </p>
      },
      eighth:     {
        title: '',
        text:  <p>

                 <strong>Wszystkie zebrane podczas Charytatywnego Pikniku Rodzinnego darowizny przekazujemy na wspieranie naszych podopiecznych, poprzez m.in. finansowanie kursów edukacyjnych. Dzięki Wam mogliśmy już w tym miesiącu opłacić kurs na prawo jazdy jednemu z naszych podopiecznych, a to dopiero początek!</strong> <span role="img" aria-label=''>💪🏻</span><br/> <br/>
                 <strong>RAZEM MOŻEMY WIĘCEJ</strong> - każdy najmniejszy sukces dodaje nam skrzydeł i sprawia, że mamy wiele nowych pomysłów i mnóstwo energii na tworzenie podobnych wydarzeń. <br/>
                 Jeszcze raz bardzo, bardzo dziękujemy wszystkim osobom, które dołożyły od siebie cegiełkę podczas Charytatywnego Pikniku Rodzinnego. Podziękowania kierujemy nie tylko do wolontariuszy, partnerów i sponsorów, ale przede wszystkim do każdej osoby, która odwiedziła nas tego dnia i wrzuciła datek do fundacyjnej skarbonki. <strong>Bez Was by się nie udało - jesteście wspaniali!</strong>
               </p>
      },
      ninth:      {
        title: <><span role="img" aria-label=''>🟣</span> Organizatorzy:</>,
        text:  <p>
                 Fundacja Świat Bez Barier -&nbsp;<a href="https://www.facebook.com/FundacjaSwiatBezBarier/" rel="noreferrer" target="_blank">https://www.facebook.com/FundacjaSwiatBezBarier/</a> <br/>
                 Beniaminek Soccer Schools Rzeszów -&nbsp;<a href="https://www.facebook.com/profile.php?id=100063748805675" rel="noreferrer" target="_blank">https://www.facebook.com/profile.php?id=100063748805675</a>
               </p>
      },
      tenth:      {
        title: <><span role="img" aria-label=''>🟣</span> Sponsorzy:</>,
        text:  <p>
                 Agencja Interaktywna InteractiveVision -&nbsp<a href="https://www.facebook.com/interactivevision/" rel="noreferrer" target="_blank">https://www.facebook.com/interactivevision/</a> <br/>
                 Ania Catering -&nbsp;<a href="https://www.facebook.com/aniacatering/" rel="noreferrer" target="_blank">https://www.facebook.com/aniacatering/</a>
               </p>
      },
      eleventh:   {
        title: <><span role="img" aria-label=''>🟣</span> Partnerzy:</>,
        text:  <p>
                 wool.on.wood -&nbsp; <a href="https://www.facebook.com/profile.php?id=100063906589372" rel="noreferrer" target="_blank">https://www.facebook.com/profile.php?id=100063906589372</a> <br/>

                 Rugby Rzeszów -&nbsp;<a href="https://www.facebook.com/RugbyRzeszow/" rel="noreferrer" target="_blank">https://www.facebook.com/RugbyRzeszow/</a> <br/>

                 Spółdzielnia Mieszkaniowa Projektant -&nbsp; <a href="https://www.facebook.com/smprojektant/" rel="noreferrer" target="_blank">https://www.facebook.com/smprojektant/</a> <br/>

                 Smaczna Kluska -&nbsp;<a href="https://www.facebook.com/profile.php?id=100063630215402" rel="noreferrer" target="_blank">https://www.facebook.com/profile.php?id=100063630215402</a><br/>

                 Bake My Day -&nbsp; <a href="https://www.facebook.com/bakemydayphl/" rel="noreferrer" target="_blank">https://www.facebook.com/bakemydayphl/</a><br/>

                 Pani Balon -&nbsp;<a href="https://www.facebook.com/PaniBalon/" rel="noreferrer" target="_blank">https://www.facebook.com/PaniBalon/</a><br/>

                 Grilloholicy -&nbsp;<a href="https://www.facebook.com/Grilloholicy/" rel="noreferrer" target="_blank">https://www.facebook.com/Grilloholicy/</a><br/>

                 KSCzarni -&nbsp; <a href="https://www.facebook.com/KSCzarniRzeszow/" rel="noreferrer" target="_blank">https://www.facebook.com/KSCzarniRzeszow/</a><br/>

                 Lody Koral -&nbsp; <a href="https://www.facebook.com/PPLKORAL/" rel="noreferrer" target="_blank">https://www.facebook.com/PPLKORAL/</a><br/>

                 AlpakaTeam - Przygoda z Alpaką -&nbsp;<a href="https://www.facebook.com/alpakirzeszow/" rel="noreferrer" target="_blank">https://www.facebook.com/alpakirzeszow/</a><br/>

                 Fundacja Hipoterapia -&nbsp; <a href="https://www.facebook.com/FundacjaHipoterapia/" rel="noreferrer" target="_blank">https://www.facebook.com/FundacjaHipoterapia/</a><br/>

                 Stadnina koni Palomino - <a href="https://www.facebook.com/Stadnina" rel="noreferrer" target="_blank">https://www.facebook.com/Stadnina</a><br/>

                 Fotolustro SHINE -&nbsp;<a href="https://www.facebook.com/fotolustroshine/" rel="noreferrer" target="_blank">https://www.facebook.com/fotolustroshine/</a><br/>

                 Bajkolandia - Animacje dla dzieci -&nbsp;<a href="https://www.facebook.com/Bajkolandia-Animacje-dla-dzieci-163375272048789" rel="noreferrer" target="_blank">https://www.facebook.com/Bajkolandia-Animacje-dla-dzieci-163375272048789</a><br/>

                 Iluzjonista Przemysław Wilk -&nbsp;<a href="https://www.facebook.com/iluzjonistaPrzemek" rel="noreferrer" target="_blank">https://www.facebook.com/iluzjonistaPrzemek</a><br/>

                 Salon Fryzjerski Paulina Wapińska - <a href="https://www.facebook.com/profile.php?id=100052711220180" rel="noreferrer" target="_blank">https://www.facebook.com/profile.php?id=100052711220180</a>
               </p>
      },
      twelfth:    {
        title: <><span role="img" aria-label=''>🟣</span> Patroni Medialni:</>,

        text: <p>
                Gazeta Codzienna Nowiny <br/>
                Portal informacyjny nowiny24.pl -&nbsp;<a href="https://www.facebook.com/nowiny24/" rel="noreferrer" target="_blank">https://www.facebook.com/nowiny24/</a> <br/>
                Intro.media -&nbsp; <a href="https://www.facebook.com/intro.mediaWSIiZ/" rel="noreferrer" target="_blank">https://www.facebook.com/intro.mediaWSIiZ/</a>
              </p>
      },
      thirteenth: {
        title: <><span role="img" aria-label=''>📸</span> ZDJĘCIA W GALERII AUTORSTWA</>,
        text:  <p>
                 Agnieszki Majchrowicz <a href="https://www.facebook.com/majchrowicz.photo" rel="noreferrer" target="_blank">https://www.facebook.com/majchrowicz.photo</a>
               </p>
      },
      fourteenth: {
        title: '',
        text:  <p>
                 Zajrzyjcie też na nasze media społecznościowe:<br/>

                 <span role="img" aria-label=''>→</span>Facebook:&nbsp;  <a href="https://www.facebook.com/FundacjaSwiatBezBarier" rel="noreferrer" target="_blank">https://www.facebook.com/FundacjaSwiatBezBarier</a><br/>

                 <span role="img" aria-label=''>→</span> Instagram (sprawdźcie relacje wyróżnione):&nbsp;
                 <a href="https://www.instagram.com/fundacjaswiatbezbarier/" rel="noreferrer" target="_blank">@fundacjaswiatbezbarier</a>
               </p>
      }
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },

    gallery: {
      first: {
        images: [
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_13.childImageSharp.fluid}/>,
          <Image fluid={images.img_14.childImageSharp.fluid}/>,
          <Image fluid={images.img_16.childImageSharp.fluid}/>,
          <Image fluid={images.img_17.childImageSharp.fluid}/>,
          <Image fluid={images.img_18.childImageSharp.fluid}/>,
          <Image fluid={images.img_19.childImageSharp.fluid}/>,
          <Image fluid={images.img_20.childImageSharp.fluid}/>,
          <Image fluid={images.img_23.childImageSharp.fluid}/>,
          <Image fluid={images.img_24.childImageSharp.fluid}/>,
          <Image fluid={images.img_25.childImageSharp.fluid}/>,
          <Image fluid={images.img_26.childImageSharp.fluid}/>,
          <Image fluid={images.img_28.childImageSharp.fluid}/>,
          <Image fluid={images.img_29.childImageSharp.fluid}/>,
          <Image fluid={images.img_30.childImageSharp.fluid}/>,
          <Image fluid={images.img_31.childImageSharp.fluid}/>,
          <Image fluid={images.img_32.childImageSharp.fluid}/>,
          <Image fluid={images.img_34.childImageSharp.fluid}/>,
          <Image fluid={images.img_35.childImageSharp.fluid}/>,
          <Image fluid={images.img_36.childImageSharp.fluid}/>,
          <Image fluid={images.img_37.childImageSharp.fluid}/>,
          <Image fluid={images.img_38.childImageSharp.fluid}/>,
          <Image fluid={images.img_39.childImageSharp.fluid}/>,
          <Image fluid={images.img_40.childImageSharp.fluid}/>,
          <Image fluid={images.img_41.childImageSharp.fluid}/>,
          <Image fluid={images.img_42.childImageSharp.fluid}/>,
          <Image fluid={images.img_43.childImageSharp.fluid}/>,
          <Image fluid={images.img_44.childImageSharp.fluid}/>,
          <Image fluid={images.img_45.childImageSharp.fluid}/>,
          <Image fluid={images.img_47.childImageSharp.fluid}/>,
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_15.childImageSharp.fluid}/>,
          <Image fluid={images.img_21.childImageSharp.fluid}/>,
          <Image fluid={images.img_22.childImageSharp.fluid}/>,
          <Image fluid={images.img_27.childImageSharp.fluid}/>,
          <Image fluid={images.img_33.childImageSharp.fluid}/>,

        ]
      },
    }
  }
}

