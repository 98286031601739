import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_26 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Spotkanie z Panią Marią, szefową firmy Drewpol",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "18 / 03 / 2022",
    description:      "Pomoc lokalnych firm jest zawsze dla nas bardzo istotna i wartościowa. Działając lokalnie, zawsze możemy wspólnie zrobić więcej dla naszej społeczności.",
    slug:             "/wydarzenia/spotkanie-w-firmie-drewpol",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: null,
        text:  <>
                 <p>Mówi się, że nie bez powodu niektórzy ludzie stają na naszej drodze. A dzisiejszym spotkaniu z panią Marią - szefową firmy Drewpol - możemy się z tym zgodzić w 100%!❤️</p>
                 <p>Pani Marysia to osoba o wielkim sercu, niesamowicie serdeczna i ciepła. Bez wahania udostępniła nam magazyn w swojej firmie. Teraz będziemy mogli spokojnie gromadzić dary dla dzieci i mieć miejsce, w którym możemy je rozdzielać i organizować 😊</p>
                 <p> Pomoc lokalnych firm jest zawsze dla nas bardzo istotna i wartościowa. Działając lokalnie, zawsze możemy wspólnie zrobić więcej dla naszej społeczności.</p>
                 <p>Pani Marysiu i cała firmo Drewpol - w imieniu naszych dzieciaków bardzo, bardzo dziękujemy! ❤️</p>
                 <p>👉 Jeśli chcecie poznać tę firmę bliżej - zapraszamy na ich stronę: <a href="http://drewpol24.pl/" target="_blank" rel="noreferrer">www.drewpol24.pl</a> 🥰</p>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
  }
}
