import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_49 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Fundacja x Fosa",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "19 / 08 / 2022",
    description:      "GRAMY RAZEM dla naszych podopiecznych - koncert i animacje dla dzieci w Fosie",
    slug:             "/wydarzenia/fundacja-x-fosa",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: <>GRAMY RAZEM dla naszych podopiecznych - koncert i animacje dla dzieci w Fosie </>,
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️️ Jako Fundacja Świat Bez Barier organizujemy różne eventy, dzięki którym możemy stworzyć lepszy świat dla naszych podopiecznych <span role="img" aria-label=''>❤</span>️ Na 28 sierpnia razem z Fosą przygotowaliśmy coś zarówno dla tych młodszych, jak i trochę starszych <span role="img" aria-label=''>😉</span>️
               </p>
      },
      second: {
        title: <>FOSA DLA DZIECI </>,
        text:
               <p>
                 28.08.2022 w godz. 12:00-16:00 serdecznie zapraszamy na animacje dla dzieci oraz zabawy integracyjno-sportowe <span role="img" aria-label=''>😊</span> <br/> <br/>
                 <span role="img" aria-label=''>📌</span> Gdzie? Fosa Rzeszów, aleja Pod Kasztanami 12
               </p>
      },
      third:  {
        title: <><span role="img" aria-label=''>🟣</span> Co zaplanowaliśmy dla dzieci?</>,
        text:
               <ul>
                 <li>- Zabawy ruchowe</li>
                 <li>- Morze baniek mydlanych</li>
                 <li>- Przeciąganie liny</li>
                 <li>- Wyścigi na tropach</li>
                 <li>- Wyścigi w zwariowanych okularach</li>
                 <li>- Strzelanie do lewitujących kulek</li>
                 <li>- Zabawa w nadmuchiwanych kulach Bumper Ball</li>
               </ul>
      },
      fourth: {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>→ </span> A dla rodziców? <span role="img" aria-label=''>🤔</span> Relaks na naszych wygodnych leżakach z zimną lemoniadą! <span role="img" aria-label=''>🍋</span> W tym czasie my zajmiemy się Twoją pociechą. <br/>

                 <span role="img" aria-label=''>→ </span> Animacje poprowadzi niezawodny B3Team <span role="img" aria-label=''>😊</span> <a href="https://www.facebook.com/B3TeamPL" target="_blank" rel="noreferrer">(https://www.facebook.com/B3TeamPL)</a>
               </p>
      },
      fifth:  {
        title: <>FOSA WIECZOREM</>,
        text:
               <p>
                 Po atrakcjach dla dzieci mamy coś również dla rodziców <span role="img" aria-label=''>😊</span> Wieczorem odbędzie się koncert charytatywny Bartłomieja „Gibsona” Filipa <span role="img" aria-label=''>🎶</span> <br/>

                 <span role="img" aria-label=''>📌</span> Gdzie? Fosa Rzeszów <br/>
                 <span role="img" aria-label=''>📅</span> Kiedy? 28.08.2022 o 19:00
               </p>
      },
      sixth:  {
        title: <><span role="img" aria-label=''>🟣</span> Jak możesz pomóc podczas wydarzenia?</>,
        text:
               <p>
                 <span role="img" aria-label=''>→ </span> Zamów drinka "Bez Barier", który zostanie przygotowany specjalnie na tę okazję 🍸 Część dochodu z jego sprzedaży zostanie przeznaczona na fundację <span role="img" aria-label=''>😊</span> <br/>

                 PS Drinka będzie można zamówić już od godz. 12:00 <span role="img" aria-label=''>🍸</span> <br/>

                 <span role="img" aria-label=''>▪</span>️️ ️ Pomaganie nigdy nie było tak proste. Dołącz do nas - nie może Cię zabraknąć! <span role="img" aria-label=''>😉</span>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
  }
}

