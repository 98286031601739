import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_44 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Wspieraj nas kupując przez FaniMani.pl",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "20 / 06 / 2022",
    description:      "▪️ Kochani! Od dzisiaj możecie nas wesprzeć robiąc zakupy przez FaniMani ❤️",
    slug:             "/wydarzenia/aplikacja-fani-mani",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️ Kochani! Od dzisiaj możecie nas wesprzeć robiąc zakupy przez FaniMani <span role="img" aria-label=''>❤️</span>
               </p>

      },
      second: {
        title: <> <span role="img" aria-label=''>️🟣</span> Wystarczy, że:</>,
        text:
               <ul>
                 <li>
                   <span role="img" aria-label=''>️1️⃣</span> Zainstalujecie w swoim telefonie aplikację mobilną FaniMani i przejdziecie z niej przed samymi zakupami do sklepu.
                 </li>
                 <li>
                   <span role="img" aria-label=''>2️⃣</span> Dodacie rozszerzenie "Przypominajka FaniMani" do swojej przeglądarki.
                 </li>
               </ul>
      },
      third:  {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>→ </span>
                 W ten sposób otrzymamy darowiznę od Waszych zakupów online, również tych zrobionych z telefonu <span role="img" aria-label=''>📱</span>
               </p>
      },
      fourth: {
        title: '',
        text:  <p>
                 <span role="img" aria-label=''>▪ </span>
                 ️ Nic nie dopłacicie ponad kwotę zakupów, a my dzięki temu będziemy mogli stworzyć lepszy świat dla naszych podopiecznych <span role="img" aria-label=''>🥰</span>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
  }
}

