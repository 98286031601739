import {graphql, useStaticQuery} from "gatsby"

export const useArticleImages = () => {
    return useStaticQuery(graphql`
        query {
            thumb: file(relativePath: { eq: "img/Blog/article_40/thumb.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            event: file(relativePath: { eq: "img/Blog/article_40/event.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_1: file(relativePath: { eq: "img/Blog/article_40/img_1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_2: file(relativePath: { eq: "img/Blog/article_40/img_2.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_3: file(relativePath: { eq: "img/Blog/article_40/img_3.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_4: file(relativePath: { eq: "img/Blog/article_40/img_4.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_5: file(relativePath: { eq: "img/Blog/article_40/img_5.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_6: file(relativePath: { eq: "img/Blog/article_40/img_6.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_7: file(relativePath: { eq: "img/Blog/article_40/img_7.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_8: file(relativePath: { eq: "img/Blog/article_40/img_8.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_9: file(relativePath: { eq: "img/Blog/article_40/img_9.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_10: file(relativePath: { eq: "img/Blog/article_40/img_10.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_11: file(relativePath: { eq: "img/Blog/article_40/img_11.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_12: file(relativePath: { eq: "img/Blog/article_40/img_12.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_13: file(relativePath: { eq: "img/Blog/article_40/img_13.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_14: file(relativePath: { eq: "img/Blog/article_40/img_14.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_15: file(relativePath: { eq: "img/Blog/article_40/img_15.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_16: file(relativePath: { eq: "img/Blog/article_40/img_16.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)
}