import NewsName from 'types/News';
import { News_10 } from './article_10/ArticleData';
import { News_11 } from './article_11/ArticleData';
import { News_12 } from './article_12/ArticleData';
import { News_13 } from './article_13/ArticleData';
import { News_14 } from './article_14/ArticleData';
import { News_15 } from './article_15/ArticleData';
import { News_16 } from './article_16/ArticleData';
import { News_17 } from './article_17/ArticleData';
import { News_18 } from './article_18/ArticleData';
import { News_19 } from './article_19/ArticleData';
import { News_20 } from './article_20/ArticleData';
import { News_21 } from './article_21/ArticleData';
import { News_22 } from './article_22/ArticleData';
import { News_23 } from './article_23/ArticleData';
import { News_24 } from './article_24/ArticleData';
import { News_25 } from './article_25/ArticleData';
import { News_26 } from './article_26/ArticleData';
import { News_27 } from './article_27/ArticleData';
import { News_28 } from './article_28/ArticleData';
import { News_29 } from './article_29/ArticleData';
import { News_30 } from './article_30/ArticleData';
import { News_31 } from './article_31/ArticleData';
import { News_32 } from './article_32/ArticleData';
import { News_33 } from './article_33/ArticleData';
import { News_34 } from './article_34/ArticleData';
import { News_35 } from './article_35/ArticleData';
import { News_36 } from './article_36/ArticleData';
import { News_37 } from './article_37/ArticleData';
import { News_38 } from './article_38/ArticleData';
import { News_39 } from './article_39/ArticleData';
import { News_40 } from './article_40/ArticleData';
import { News_41 } from './article_41/ArticleData';
import { News_42 } from './article_42/ArticleData';
import { News_43 } from './article_43/ArticleData';
import { News_44 } from './article_44/ArticleData';
import { News_45 } from './article_45/ArticleData';
import { News_46 } from './article_46/ArticleData';
import { News_47 } from './article_47/ArticleData';
import { News_48 } from './article_48/ArticleData';
import { News_49 } from './article_49/ArticleData';
import { News_50 } from './article_50/ArticleData';
import { News_51 } from './article_51/ArticleData';
import { News_52 } from './article_52/ArticleData';
import { News_53 } from './article_53/ArticleData';
import { News_54 } from './article_54/ArticleData';
import { News_55 } from './article_55/ArticleData';
import { News_56 } from './article_56/ArticleData';
import { News_57 } from './article_57/ArticleData';
import { News_58 } from './article_58/ArticleData';
import { News_59 } from './article_59/ArticleData';
import { News_60 } from './article_60/ArticleData';
import { News_61 } from './article_61/ArticleData';
import { News_62 } from './article_62/ArticleData';
import { News_6 } from './article_6/ArticleData';
import { News_7 } from './article_7/ArticleData';
import { News_8 } from './article_8/ArticleData';
import { News_9 } from './article_9/ArticleData';
import { News_1 } from './fundacja/FundationData';
import { News_4 } from './mikolajki/MikolajkiData';
import { News_5 } from './mikolajki2021/EventData';
import { News_3 } from './naszZespol/OurTeamData';
import { News_2 } from './nowyRok/NewYearData';

const useNewsData = type => {
  const data = {
    [NewsName.NEWS_1]: News_1(),
    [NewsName.NEWS_2]: News_2(),
    [NewsName.NEWS_3]: News_3(),
    [NewsName.NEWS_4]: News_4(),
    [NewsName.NEWS_6]: News_6(),
    [NewsName.NEWS_7]: News_7(),
    [NewsName.NEWS_8]: News_8(),
    [NewsName.NEWS_14]: News_14(),
    [NewsName.NEWS_9]: News_9(),
    [NewsName.NEWS_5]: News_5(),
    [NewsName.NEWS_10]: News_10(),
    [NewsName.NEWS_11]: News_11(),
    [NewsName.NEWS_15]: News_15(),
    [NewsName.NEWS_13]: News_13(),
    [NewsName.NEWS_12]: News_12(),
    [NewsName.NEWS_16]: News_16(),
    [NewsName.NEWS_17]: News_17(),
    [NewsName.NEWS_18]: News_18(),
    [NewsName.NEWS_19]: News_19(),
    [NewsName.NEWS_20]: News_20(),
    [NewsName.NEWS_21]: News_21(),
    [NewsName.NEWS_22]: News_22(),
    [NewsName.NEWS_23]: News_23(),
    [NewsName.NEWS_24]: News_24(),
    [NewsName.NEWS_25]: News_25(),
    [NewsName.NEWS_26]: News_26(),
    [NewsName.NEWS_27]: News_27(),
    [NewsName.NEWS_28]: News_28(),
    [NewsName.NEWS_29]: News_29(),
    [NewsName.NEWS_30]: News_30(),
    [NewsName.NEWS_31]: News_31(),
    [NewsName.NEWS_32]: News_32(),
    [NewsName.NEWS_33]: News_33(),
    [NewsName.NEWS_34]: News_34(),
    [NewsName.NEWS_35]: News_35(),
    [NewsName.NEWS_36]: News_36(),
    [NewsName.NEWS_37]: News_37(),
    [NewsName.NEWS_38]: News_38(),
    [NewsName.NEWS_39]: News_39(),
    [NewsName.NEWS_40]: News_40(),
    [NewsName.NEWS_41]: News_41(),
    [NewsName.NEWS_42]: News_42(),
    [NewsName.NEWS_43]: News_43(),
    [NewsName.NEWS_44]: News_44(),
    [NewsName.NEWS_45]: News_45(),
    [NewsName.NEWS_46]: News_46(),
    [NewsName.NEWS_47]: News_47(),
    [NewsName.NEWS_49]: News_49(),
    [NewsName.NEWS_48]: News_48(),
    [NewsName.NEWS_50]: News_50(),
    [NewsName.NEWS_51]: News_51(),
    [NewsName.NEWS_52]: News_52(),
    [NewsName.NEWS_53]: News_53(),
    [NewsName.NEWS_54]: News_54(),
    [NewsName.NEWS_55]: News_55(),
    [NewsName.NEWS_56]: News_56(),
    [NewsName.NEWS_57]: News_57(),
    [NewsName.NEWS_58]: News_58(),
    [NewsName.NEWS_59]: News_59(),
    [NewsName.NEWS_60]: News_60(),
    [NewsName.NEWS_61]: News_61(),
    [NewsName.NEWS_62]: News_62(),
  };
  if (!type) {
    return data;
  }
  return data[type];
};

export default useNewsData;
