import { useStaticQuery, graphql } from "gatsby"

export const useMikolajkiImages = () => {
  const query = useStaticQuery(graphql`
    query {
      #MIKOLAJKI 2020
      hero: file(relativePath: { eq: "img/Blog/mikolajki-2020/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      thumb: file(relativePath: { eq: "img/Blog/mikolajki-2020/thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      event: file(relativePath: { eq: "img/Blog/mikolajki-2020/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_1: file(relativePath: { eq: "img/Blog/mikolajki-2020/img_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_2: file(relativePath: { eq: "img/Blog/mikolajki-2020/img_22.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_3: file(relativePath: { eq: "img/Blog/mikolajki-2020/img_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_4: file(relativePath: { eq: "img/Blog/mikolajki-2020/img_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      #END
    }
  `)
  return query
}
