import {graphql, useStaticQuery} from "gatsby"

export const useArticleImages = () => {
    return useStaticQuery(graphql`
      query {
          thumb: file(relativePath: { eq: "img/Blog/article_37/thumb.png" }) {
              childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          event: file(relativePath: { eq: "img/Blog/article_37/event.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img_1: file(relativePath: { eq: "img/Blog/article_37/img_1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }  
            img_2: file(relativePath: { eq: "img/Blog/article_37/img_2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }   
             img_3: file(relativePath: { eq: "img/Blog/article_37/img_3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
             img_4: file(relativePath: { eq: "img/Blog/article_37/img_4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
             img_5: file(relativePath: { eq: "img/Blog/article_37/img_5.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
             img_6: file(relativePath: { eq: "img/Blog/article_37/img_6.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }  
               img_7: file(relativePath: { eq: "img/Blog/article_37/img_7.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }                                                             
      }
  `)
}
