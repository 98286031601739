import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_48 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "CHARYTATYWNY PIKNIK RODZINNY",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "30 / 08 / 2022",
    description:      "Już 3 września, na naszym Charytatywnym Pikniku Rodzinnym na osiedlu Projektant czeka na Was mnóstwo atrakcji! ",
    slug:             "/wydarzenia/piknik-rodzinny",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:   {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️️ Już 3 września, na naszym Charytatywnym Pikniku Rodzinnym na osiedlu Projektant czeka na Was mnóstwo atrakcji! <span role="img" aria-label=''>😍</span>️️ <br/>
                 Dołączcie do wydarzenia na Facebooku <span role="img" aria-label=''> 👉</span>️️ <a href="https://www.facebook.com/events/1552112958537145/" target="_blank">https://www.facebook.com/events/1552112958537145/</a> i bądźcie na bieżąco.
               </p>
      },
      second:  {
        title: <><span role="img" aria-label=''>🟡</span>️️ ATRAKCJE DLA DUŻYCH I MAŁYCH</>,
        text:
               <>
                 <ul>
                   <li>- Rugby Rzeszów - będzie można wykonać szarżę, zmierzyć się z rugbowym slalomem i przede wszystkim zapoznać się z jajowatą piłką <span role="img" aria-label=''>🏉</span>️️</li>
                   <li>- Wool.on.Wood - warsztaty tworzenia makramy</li>
                   <li>- Dmuchane zamki</li>
                   <li>- Wata cukrowa i popcorn</li>
                   <li>- DJ</li>
                   <li>- Grill</li>
                   <li>- Słodki stół</li>
                   <li>- Kolorowanie włosów</li>
                   <li>- Alpaki (od 13:00 do 14:00)</li>
                   <li>- Przejażdżki na kucyku (od 14:00 do 16:00)</li>
                   <li>- Gigantyczne bańki mydlane</li>
                   <li>- Balony</li>
                 </ul>
               </>
      },
      third:   {
        title: <><span role="img" aria-label=''>🟡</span> ROZGRYWKI PIŁKARSKIE</>,
        text:
               <ul>
                 <li>
                   <span role="img" aria-label=''>▪</span>️️ 11:00 - Przyjacielski mecz charytatywny Team Fundacja Świat Bez Barier VS Beniaminek Rodzice
                 </li>
                 <li>
                   <span role="img" aria-label=''>▪</span>️️ 13:00 - Rozgrywki Dzieci Beniaminek wspólnie z podopiecznymi Fundacji
                 </li>
                 <li>
                   <span role="img" aria-label=''>▪</span>️️ 14:30 - Towarzyskie rozgrywki piłki nożnej dla wszystkich chętnych dzieci
                 </li>
               </ul>
      },
      fourth:  {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>️️→ </span> Wspaniała zabawa to również świetna okazja do pomocy innym. Przyjdź ze swoim dzieckiem i wesprzyj Fundację Świat Bez Barier <span role="img" aria-label=''>️️💜️</span> <br/>
                 <strong>Cały dochód z pikniku zostanie przeznaczony na wsparcie naszych podopiecznych <span role="img" aria-label=''>️️☺️</span> <br/> <br/></strong>

                 Nie może Was zabraknąć <span role="img" aria-label=''>😄</span> <br/>
                 Do zobaczenia! <span role="img" aria-label=''>🥰</span>
               </p>
      },
      fifth:   {
        title: <><span role="img" aria-label=''>🟣</span> Organizatorzy:</>,
        text:
               <p>
                 Fundacja Świat Bez Barier - <a href=" https://www.facebook.com/FundacjaSwiatBezBarier/" target="_blank" rel="noreferrer"> https://www.facebook.com/FundacjaSwiatBezBarier/</a> <br/>

                 Beniaminek Soccer Schools Rzeszów - <a href="https://www.facebook.com/profile.php?id=100063748805675" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100063748805675</a>
               </p>
      },
      sixth:   {
        title: <><span role="img" aria-label=''>🟣</span> Sponsorzy:</>,
        text:
               <p>
                 Agencja Interaktywna InteractiveVision - <a href=" https://www.facebook.com/interactivevision/" target="_blank" rel="noreferrer"> https://www.facebook.com/interactivevision/</a> <br/>

                 Ania Catering - <a href="https://www.facebook.com/aniacatering/" target="_blank" rel="noreferrer">https://www.facebook.com/aniacatering/</a>
               </p>
      },
      seventh: {
        title: <><span role="img" aria-label=''>🟣</span> Partnerzy:</>,
        text:
               <p>
                 wool.on.wood - <a href="https://www.facebook.com/profile.php?id=100063906589372" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100063906589372</a> <br/>

                 Rugby Rzeszów - <a href="https://www.facebook.com/RugbyRzeszow/" target="_blank" rel="noreferrer">https://www.facebook.com/RugbyRzeszow/</a> <br/>

                 Spółdzielnia Mieszkaniowa Projektant - <a href="https://www.facebook.com/smprojektant/" target="_blank" rel="noreferrer">https://www.facebook.com/smprojektant/</a> <br/>

                 Smaczna Kluska - <a href="https://www.facebook.com/profile.php?id=100063630215402" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100063630215402</a> <br/>

                 Bake My Day - <a href="https://www.facebook.com/bakemydayphl/" target="_blank" rel="noreferrer">https://www.facebook.com/bakemydayphl/</a> <br/>

                 Pani Balon - <a href="https://www.facebook.com/PaniBalon/" target="_blank" rel="noreferrer">https://www.facebook.com/PaniBalon/</a> <br/>

                 Grilloholicy - <a href="https://www.facebook.com/Grilloholicy/" target="_blank" rel="noreferrer">https://www.facebook.com/Grilloholicy/</a> <br/>

                 KSCzarni - <a href="https://www.facebook.com/KSCzarniRzeszow/" target="_blank" rel="noreferrer">https://www.facebook.com/KSCzarniRzeszow/</a> <br/>

                 Lody Koral - <a href="https://www.facebook.com/PPLKORAL/" target="_blank" rel="noreferrer">https://www.facebook.com/PPLKORAL/</a> <br/>

                 AlpakaTeam - Przygoda z Alpaką - <a href="https://www.facebook.com/alpakirzeszow/" target="_blank" rel="noreferrer">https://www.facebook.com/alpakirzeszow/</a> <br/>

                 Fundacja Hipoterapia - <a href="https://www.facebook.com/FundacjaHipoterapia/" target="_blank" rel="noreferrer">https://www.facebook.com/FundacjaHipoterapia/</a> <br/>

                 Fotolustro SHINE - <a href="https://www.facebook.com/fotolustroshine/" target="_blank" rel="noreferrer">https://www.facebook.com/fotolustroshine/</a> <br/>

                 Bajkolandia - Animacje dla dzieci -  <a href="https://www.facebook.com/Bajkolandia-Animacje-dla-dzieci-163375272048789" target="_blank" rel="noreferrer">https://www.facebook.com/Bajkolandia-Animacje-dla-dzieci-163375272048789</a> <br/>

                 Iluzjonista Przemysław Wilk - <a href="https://www.facebook.com/iluzjonistaPrzemek" target="_blank" rel="noreferrer">https://www.facebook.com/iluzjonistaPrzemek</a>
               </p>
      },
      eighth:  {
        title: <><span role="img" aria-label=''>🟣</span> Patroni Medialni:</>,
        text:
               <p>
                 Gazeta Codzienna Nowiny <br/>
                 Portal informacyjny nowiny24.pl - <a href="https://www.facebook.com/nowiny24/" target="_blank" rel="noreferrer">https://www.facebook.com/nowiny24/</a> <br/>

                 Intro.media - <a href="https://www.facebook.com/intro.mediaWSIiZ/" target="_blank" rel="noreferrer">https://www.facebook.com/intro.mediaWSIiZ/</a> <br/>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
  }
}

