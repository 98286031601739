import React from "react";
import Image from "gatsby-image";
import { useLocation } from "@reach/router";

import FbIcon from "assets/img/svg/fb.svg";
import LinkedIcon from "assets/img/svg/linkedin.svg";
import TwitterIcon from "assets/img/svg/tw.svg";
import { StickyLink } from "sections/News/NewsLayout/styles.js";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { useTeamImages } from "./OurTeamImages";

export const News_3 = () => {
  const images = useTeamImages();
  const location = useLocation();
  return {
    title: "Poznaj nas bliżej.",
    hero_image: <Image fluid={images.hero.childImageSharp.fluid} />,
    detail_image: <Image fluid={images.thumb.childImageSharp.fluid} />,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid} />,
    date: "12 / 02 / 2020",
    description:
      "Poznaj nasz zespół ! Oto nasz zespół, wspólnymi siłami pracujemy nadsprawną działalnością fundacji. Każdy dokłada swoją cegiełkę.",
    slug: "/wydarzenia/nasz-zespol",
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon} />
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon} />
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon} />
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: "Sprawdź, co u nas słychać!",
        text: (
          <>
            Poznaj nasz zespół! Wspólnymi siłami pracujemy nad sprawną działalnością fundacji. Każdy dokłada swoją cegiełkę, bo razem możemy zdziałać jak najwięcej. Mimo że czasy wciąż są ciężkie, czekamy cierpliwie i powolutku planujemy ambitny start naszych projektów, które poprowadzą ku lepszej przyszłości.
          </>
        ),
      },
    },
  }
}
