import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_14 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Obozy konne dla naszych podopiecznych ",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "17 / 08 / 2021",
    description:
                      "Jula, Ela, Ola oraz Damian codziennie uczestniczą w zajęciach jazdy konnej i uczą się wielu innych przydatnych obowiązków.",
    slug:             "/wydarzenia/obozy-konne-dla-naszych-podopiecznych",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
    texts:            {
      first:  {
        title: "",
        text:  <>Jula, Ela, Ola oraz Damian codziennie uczestniczą w zajęciach jazdy konnej i uczą się wielu innych przydatnych obowiązków. Przede wszystkim są bardzo wdzięczni za to, że mogli się tu znaleźć <span role="img" aria-label="smiling face emoji with closed eyes">😊</span></>
      },
      second: {
        title: '',
        text:  <>
                 Zachęcamy Was do wsparcia, abyśmy dalej mogli razem spełniać więcej marzeń!
                 <span role="img" aria-label="face with heads emoji"> 🤗</span>
                 <br/>
                 Pozdrawiamy!
               </>
      }
    },
    lists:            {
      first: {
        title: "",
        items: [
          <>Dzięki Waszej pomocy nasi podopieczni pojechali na obozy konne <span role="img" aria-label="horse emoji">🐎</span> </>,
          <>Spełniło się marzenie aż 4 dzieciaków, za co z całego serca dziękujemy! <span role="img" aria-label="heart emoji">❤️</span></>,
        ],
      },
    },
  }
}
