import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_32 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Rzeszów dla Ukrainy - pierwsze zajęcia!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "04 / 04 / 2022",
    description:      "W minionym tygodniu odbyły się pierwsze zajęcia języka polskiego w ramach naszego projektu „Rzeszów dla Ukrainy”",
    slug:             "/wydarzenia/rzeszow-dla-ukrainy-pierwsze-zajecia",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: null,
        text:  <>
                 <p><span role='img' aria-label=''>▪️</span> Kochani, w minionym tygodniu odbyły się pierwsze zajęcia języka polskiego w ramach naszego projektu „Rzeszów dla Ukrainy” <span role="img" aria-label="Ukrainian flag">🇺🇦</span></p>
                 <p>→ W piątek wiedzę przekazywaliśmy trzem grupom ukraińskich mam i dzieci. Zajęcia bardzo nam się podobały! Mamy nadzieję, że uczestnikom również <span role="img" aria-label="">🥰 &nbsp;</span> Wierzymy, że już niedługo wspólnie osiągniemy zamierzone cele <span role="img" aria-label="">💙 </span><span role="img" aria-label="">💛</span></p>
               </>
      },
      second: {
        title: <><span role="img" aria-label="">🔵</span> Zajęcia będą odbywać się regularnie w każdy poniedziałek i piątek w godzinach:</>,
        text:  <>
                 <ul>
                   <li><b> <span role='img' aria-label=''>👉</span>  &nbsp;&nbsp;poniedziałki:</b></li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język polski 10:15 - 11:00</li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język angielski 11:15 - 12:00</li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język polski 12:15 - 13:00</li>
                 </ul>
                 <br/>
                 <ul>
                   <li><b><span role='img' aria-label=''>👉</span> &nbsp;&nbsp;piątki:</b></li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język polski 9:45 - 10:30</li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język angielski 10:45 - 11:30</li>
                   <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;język polski 11:45 - 12:30</li>
                 </ul>
               </>
      },
      third:  {
        title: <><span role='img' aria-label=''>🟡 &nbsp;</span> Gdzie?</>,
        text:  <>
                 <p>→ W <a href="https://www.facebook.com/badaboomrzeszow/" target='_blank'>Badaboom</a> na ul.Architektów 14A w Rzeszowie <span role='img' aria-label=''>🏠</span></p>
                 <p>Po zajęciach zawsze znajdziemy czas na chwilę rozmowy i pomoc w bieżących sprawach <span role='img' aria-label=''>❤️</span></p>
               </>

      },
      fourth: {
        title: <><span role='img' aria-label=''>🔵 &nbsp;</span> Jak możecie pomóc?</>,
        text:  <>
                 <p>→ Poprzez wpłatę dowolnej kwoty na konto naszej fundacji. Wykorzystamy je na pokrycie kosztów materiałów edukacyjnych. Lekcji udzielamy charytatywnie, nie pobieramy za nie żadnych opłat <span role='img' aria-label=''>😊</span></p>
                 <p>
                   <span role='img' aria-label=''>👉</span>&nbsp; &nbsp; Link do zbiórki: <a href="https://wsparcie.fundacjaswiatbezbarier.pl/?utm_source=FACEBOOK&utm_medium=IV%20-%20WK&utm_campaign=POST%20FB%20IG&fbclid=IwAR3W_rEb_flNMIt4zlEAf8I8zZSR00-ylm4z5U2z0NCb0qH7hGllFMQx2ZU" target='_blank'>fundacjaswiatbezbarier-wsparcie</a> <span role='img' aria-label=''>❤️</span>
                 </p>
                 <p><span role='img' aria-label=''>▪️</span> Liczymy na Waszą pomoc! <span role='img' aria-label=''>❤️</span></p>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}

