import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_59 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Nasze plany na 2023!',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '20 / 01 / 2023',
    description: 'Kochani, witajcie w nowym, 𝟮𝟬𝟮𝟯 roku!',
    slug: '/wydarzenia/nasze-plany-na-2023',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <><span role="img" aria-label="">👉 </span> Kochani, witajcie w nowym, 𝟮𝟬𝟮𝟯 roku! <span role="img" aria-label=""> 😊</span></>,
        text:
          <p>
            Jeszcze raz w tym miejscu chcieliśmy Wam bardzo mocno podziękować za ubiegły rok.
            Za wsparcie, jakie okazaliście nam i&nbsp;naszym podopiecznym.
            <br/>
            Dzięki Wam udało nam się spełnić wiele marzeń dzieci i&nbsp;celów, jakie sobie założyliśmy!
          </p>
      },
      second: {
        title: <><span role="img" aria-label="">💜 </span>Nie zwalniamy tempa! <span role="img" aria-label="">💪</span></>,
        text: <>
          <p>
            Po świątecznym lenistwie czas zakasać rękawy i&nbsp;zaplanować działania na najbliższe miesiące
            → Będzie się działo, obiecujemy!
            <br/>
            Chcemy, żeby wszystkie dzieci miały równe szanse w nauce czy rozwijaniu swoich pasji. Niektóre z nich na początku mają trochę trudniej przez sytuację rodzinną czy brak funduszy - dlatego jesteśmy <strong>MY</strong> i&nbsp;robimy wszystko, aby te szanse z&nbsp;resztą dzieciaków wyrównać.
            <br/>
            <br/>
            <span role="img" aria-label="">👉 </span> W najbliższych miesiącach chcemy zorganizować kilka wydarzeń i&nbsp;akcji, dzięki którym możliwe będzie zebranie funduszy na dalsze działania.
            <br/>
            Te działania to wsparcie edukacyjne podopiecznych Fundacji, opłacanie kursów czy korepetycji.
            <br/>
            <br/>
            Nie uda nam się to bez Waszej pomocy, dlatego prosimy, abyście byli obok nas. Jeśli nie macie możliwości wesprzeć nas finansowo, udostępniajcie i&nbsp;lajkujcie nasze posty, poszerzając grono odbiorców. To zwiększa nasze szanse
          </p>
        </>,
      },
      third: {
        title: <><span role="img" aria-label="">!! </span> Plany na 2023 mamy ambitne:</>,
        text: <>
          <ul>
            <li>* w dalszym ciągu chcemy finansować korepetycje m.in. z&nbsp;języka angielskiego czy matematyki,</li>
            <li>* chcemy zabierać dzieciaki w każdy piątek do kina czy ulubioną ściankę wspinaczkową,</li>
            <li>* chcemy wysłać dzieciaki na letnie obozy/kolonie,</li>
            <li>* chcemy pokazywać im, że są dla nas WAŻNE i&nbsp;że wierzymy w to, że mogą zawojować świat!</li>
          </ul>
          <p>
            <br/>
            Pomożecie nam? <span role="img" aria-label=""> 🥰</span>
            <br/>
            <br/>
            <span role="img" aria-label="">❌ </span>
             Poszukujemy też firm, które mogłyby przekazać dla naszych podopiecznych vouchery do kina czy do miejsc rozrywki.
            <br/>
            Mamy pod opieką ponad 20 dzieciaków!
            <span role="img" aria-label=""> 😊</span>
            <br/>
            <br/>
            <span role="img" aria-label="">🔻</span>
            <a href="https://wsparcie.fundacjaswiatbezbarier.pl/" rel="noreferrer" target="_blank">https://wsparcie.fundacjaswiatbezbarier.pl/</a>
            <span role="img" aria-label="">🔻</span>
          </p>
        </>
      }
    },
    images: {
      first: {
        show: true,
        full: true,
        image:
          [
            {
              image:
                <Image fluid={images.hero.childImageSharp.fluid}/>,
              description: '',
            },
          ],
      },
    },
  };
};

