import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_33 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Podsumowanie transportu do Ukrainy",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "12 / 04 / 2022",
        description: "Finalnie na transport przeznaczony do sierocińca i szpitala wojskowego na Ukrainie udało nam się przygotować 60 pełnych paczek. To o wiele więcej, niż się spodziewaliśmy!",
        slug: "/wydarzenia/podsumowanie-transportu",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: <><span role='img' aria-label=''>▪</span>&nbsp;Kochani, jesteście niesamowici! <span role='img'
                                                                                                      aria-label=''>🥰</span></>,
                text: <>
                    <p><span role='img' aria-label=''>→</span> Finalnie na transport przeznaczony do sierocińca i
                        szpitala wojskowego na Ukrainie udało nam się przygotować 60 pełnych paczek. To o wiele więcej,
                        niż się spodziewaliśmy! <span role='img' aria-label=''>❤️</span></p>
                </>
            },
            second: {
                title: '',
                text: <>
                    <p>Jesteśmy niezwykle wzruszeni, jak wspaniale wygląda lokalna inicjatywa, jak wspaniale nasze
                        społeczeństwo się jednoczy i pomaga w konkretnym celu <span role='img' aria-label=''>🥺</span>
                    </p>
                </>
            },
            third: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>🔵</span> Dzięki Waszemu wsparciu udało się dostarczyć wszystkie
                        rzeczy z listy i sprawić, że na twarzach wielu dzieci przebywających w sierocińcu pojawił się
                        uśmiech. Vasyl - osoba o ogromnym sercu i z wielkim zaangażowaniem w niesieniu pomocy swoim
                        rodakom - szczęśliwie i bez problemów dostarczył transport <span role='img' aria-label=''>😊</span></p>
                </>

            },
            fourth: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>🟡</span> Zdjęcia, które dodajemy, rozrywają nasze serca i
                        pokazują prawdziwy obraz wojny, którego najbardziej doświadczają ci najmniejsi <span role='img'
                                                                                                             aria-label=''>😔</span> Jednak
                        zdjęcia te również motywują nas do długotrwałego wspierania sierocińca, organizowania kolejnych
                        zbiórek i jakiejkolwiek innej formy pomocy tym maluchom, które przecież nie są niczemu
                        winne... <br/> <br/>

                        <span role='img' aria-label=''>→</span> Jako fundacja działająca na rzecz dzieci w potrzebie
                        zawsze z wielkim zaangażowaniem będziemy organizować podobne inicjatywy, a wsparcie
                        potrzebujących dzieci zawsze będzie dla nas priorytetem <span role='img' aria-label=''>❤️</span>
                    </p>
            },
            fifth: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span> Równocześnie informujemy, że prowadzimy stałą zbiórkę
                        niezbędnych rzeczy dla sierocińca i szpitala. Prosimy o kontakt telefoniczny lub wiadomość
                        prywatną, jeśli chcieliby Państwo coś przekazać. W najbliższym czasie dodamy post z listą
                        najpotrzebniejszych rzeczy <span role='img' aria-label=''>📝</span></p>
                </>
            },
        },
        gallery: [
            <Image fluid={images.img_1.childImageSharp.fluid} />,
            <Image fluid={images.img_2.childImageSharp.fluid} />,
            <Image fluid={images.img_3.childImageSharp.fluid} />,
            <Image fluid={images.img_4.childImageSharp.fluid} />,
            <Image fluid={images.img_5.childImageSharp.fluid} />,
            <Image fluid={images.img_6.childImageSharp.fluid} />,
            <Image fluid={images.img_7.childImageSharp.fluid} />,
            <Image fluid={images.img_8.childImageSharp.fluid} />,
            <Image fluid={images.img_9.childImageSharp.fluid} />,
            <Image fluid={images.img_10.childImageSharp.fluid} />,
        ],
        images:           {
            first: {
                show:  true,
                full:  true,
                image: [
                    {
                        image:       <Image fluid={images.img_11.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            },
        },
    }
}

