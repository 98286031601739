import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_24 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Podsumowanie obozu jeździeckiego!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "28 / 02 / 2022",
    description:
                      "Dziękujemy z całego serca wszystkim, którzy przyczynili się do sfinansowania ich wyjazdu.",
    slug:             "/wydarzenia/podsumowanie-obozu-jezdzieckiego",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 ️Zuzia i Emilka zakończyły już swój obóz jeździecki. 🐎 <br/>
                 ️Zdjęcia mówią same za siebie - było super!😍<br/>
                 ️Dziewczyny bardzo dużo się nauczyły i z wielką radością uczestniczyły w każdych zajęciach. A teraz marzą o tym, by pojechać na kolejny obóz w lecie!😊<br/>
                 ️Dziękujemy z całego serca wszystkim, którzy przyczynili się do sfinansowania ich wyjazdu.❤️<br/>
                 ️Obecnie rozpoczynamy nowe projekty, przy których również liczymy na Wasze wsparcie!🤗<br/>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_4.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_5.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
