import {useLocation} from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {StickyLink} from 'sections/News/NewsLayout/styles.js';
import {useArticleImages} from './ArticleImages';

export const News_54 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Podsumowanie charytatywnego biegu "Piątka dla Fundacji"',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '24 / 10 / 2022',
    description: 'W sobotę razem z parkrun Rzeszów i PODIUM zorganizowaliśmy Charytatywny Bieg "Piątka dla Fundacji" na dystansie 5 km nad Wisłokiem w Rzeszowie',
    slug: '/wydarzenia/podsumowanie-charytatywnego-biegu-piatka-dla-fundacji',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">▪ </span>
            W sobotę razem z parkrun Rzeszów i PODIUM zorganizowaliśmy Charytatywny Bieg "Piątka dla Fundacji" na dystansie 5 km nad Wisłokiem w Rzeszowie <span role="img" aria-label=""> 🏃‍️‍</span>
            <br/>
            <br/>
            <span role="img" aria-label="">→ </span>
            To był pogodny, bardzo aktywny poranek pełen wzruszeń i radości!
            <span role="img" aria-label=""> 🌞 </span>
            Jesteśmy bardzo szczęśliwi na myśl o tym, jak wielu z Was zapisało się na bieg i dorzuciło swoją cegiełkę na rzecz naszych podopiecznych! <span role="img" aria-label=""> ❤️</span>
          </p>,
      },
      second: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉 </span>
            Dzięki Wam udało się zebrać: <strong>𝟲𝟮𝟵𝟬 𝘇ł</strong>!! - za tę kwotę będziemy mogli opłacić trzymiesięczne korepetycje dla naszych podopiecznych oraz kurs zawodowy dla podopiecznej startującej w dorosłość! <span role="img" aria-label=""> 💪</span>
            <br/>
            W biegu wzięło udział <strong>𝟭𝟬𝟯 𝗯𝗶𝗲𝗴𝗮𝗰𝘇𝘆!</strong>
            <span role="img" aria-label=""> 💜️</span>
            <span role="img" aria-label=""> 🏃️</span>
            <span role="img" aria-label=""> 🏃‍♀ ️</span>
            Mamy nadzieję, że tak jak my spędziliście miło sobotni poranek i cały weekend! Nas endorfiny do dzisiaj nie opuszczają! <span role="img" aria-label="">🤩️</span>
            <br/>
            <br/>
            Dziękujemy naszym partnerom i sponsorom, którzy zapewnili dla uczestników biegu wspaniałe nagrody.
            <span role="img" aria-label="">❤️</span>
          </p>,
      },
      third: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">▪ </span>
            ️ W biegu wzięło udział <strong>𝟭𝟬𝟯 𝗯𝗶𝗲𝗴𝗮𝗰𝘇𝘆</strong>! Mamy nadzieję, że tak jak my spędziliście miło sobotni poranek i cały weekend! Nas endorfiny do dzisiaj nie opuszczają!<br/>
            <br/>
            W czasie biegu dla dorosłych odbędzie się również bieg dla dzieciaków. Dla każdego dziecka biorącego udział w biegu przewidzieliśmy słodki upominek i medal. <br/>
            <br/>
            Po biegu zapraszamy wszystkich chętnych na rodzinną jogę oraz zajęcia i zabawy sportowe dla wszystkich chętnych dzieci. <br/>
            <br/>
            Bieg dla dorosłych rozpocznie się o godzinie 9:00. <br/>
            <br/>
            <span role="img" aria-label="">▪ </span>
            ️Na mecie na każdego uczestnika będzie czekać słodki poczęstunek, gorąca kawa oraz herbata i woda.
            <br/>
            <br/>
            Aby podziękować biegaczom za okazane wsparcie dla Fundacji, wraz z partnerami wydarzenia przygotowaliśmy upominki oraz loterię z nagrodami-niespodziankami. <br/>
            <br/>
            Mamy nadzieję, że we wspólnym gronie aktywnie rozpoczniemy weekend <span role="img" aria-label=""> 💪 </span>
            <span role="img" aria-label="">🏁</span>
          </p>,
      },
      fourth: {
        title: 'Organizatorzy:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Fundacja Świat Bez Barier - <a href="https://www.facebook.com/FundacjaSwiatBezBarier" target="_blank" rel="noreferrer">https://www.facebook.com/FundacjaSwiatBezBarier</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Parkrun Rzeszów - <a href="https://www.facebook.com/parkrunrzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/parkrunrzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              PODIUM - <a href="https://www.facebook.com/podium.ultrastylzycia" target="_blank" rel="noreferrer">https://www.facebook.com/podium.ultrastylzycia</a>
            </li>
          </ul>,
      },
      fifth: {
        title: 'Partnerzy:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Manufaktura Smaku Patryk Mach - <a href="https://www.facebook.com/profile.php?id=100063733760824" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100063733760824</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszów Rockets - <a href="https://www.facebook.com/rzeszowrockets" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowrockets</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              ButyJana - <a href="https://www.facebook.com/butyjana" target="_blank" rel="noreferrer">https://www.facebook.com/butyjana</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Wyższa Szkoła Informatyki i Zarządzania w Rzeszowie - <a href="https://www.facebook.com/WSIiZwRzeszowie" target="_blank" rel="noreferrer">https://www.facebook.com/WSIiZwRzeszowie</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszowska Szkoła Salsy - <a href="https://www.facebook.com/rzeszowskaszkolasalsy" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowskaszkolasalsy</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Lindy dOt - <a href="https://www.facebook.com/lindydOt" target="_blank" rel="noreferrer">https://www.facebook.com/lindydOt</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Zuzove - kosmetyki naturalne - <a href="https://www.facebook.com/profile.php?id=100039929813675" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100039929813675</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              JeMy Rzeszów - <a href="https://www.facebook.com/FundacjaSwiatBezBarier" target="_blank" rel="noreferrer">https://www.facebook.com/FundacjaSwiatBezBarier</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Salon Fryzjerski Lady’s D&E - <a href="https://www.facebook.com/Ladysstudio" target="_blank" rel="noreferrer">https://www.facebook.com/Ladysstudio</a>
            </li>


            <li>
              <span role="img" aria-label="">🔸</span>
              Sanomed - <a href="https://www.facebook.com/SANOMED.gabinet.rehabilitacji" target="_blank" rel="noreferrer">https://www.facebook.com/SANOMED.gabinet.rehabilitacji</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Umbrella Pole Dance Studio - <a href="https://www.facebook.com/umbrellapoledance" target="_blank" rel="noreferrer">https://www.facebook.com/umbrellapoledance</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Lord Jack - <a href="🔸 Lord Jack - https://www.facebook.com/LordJackRzeszow" target="_blank" rel="noreferrer">🔸 Lord Jack - https://www.facebook.com/LordJackRzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Ania Catering - <a href="https://www.facebook.com/aniacatering" target="_blank" rel="noreferrer">https://www.facebook.com/aniacatering</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Peron Baltoro - <a href="https://www.facebook.com/peronbaltoro.rzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/peronbaltoro.rzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Stary Browar Rzeszowski - <a href="https://www.facebook.com/BrowarRzeszowski" target="_blank" rel="noreferrer">https://www.facebook.com/BrowarRzeszowski</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Agencja Interaktywna InteractiveVision - <a href="https://www.facebook.com/interactivevision" target="_blank" rel="noreferrer">https://www.facebook.com/interactivevision</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Siłownia CoolGym - <a href="https://www.facebook.com/coolturystagym" target="_blank" rel="noreferrer">https://www.facebook.com/coolturystagym</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Kozlovna Rzeszów - <a href="https://www.facebook.com/kozlovnarzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/kozlovnarzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Sylveco - <a href="https://www.facebook.com/Sylvecopl" target="_blank" rel="noreferrer">https://www.facebook.com/Sylvecopl</a>
            </li>
          </ul>,
      },
      sixth: {
        title: 'Patroni medialni:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Super Nowości - <a href="https://www.facebook.com/SuperNowosci24" target="_blank" rel="noreferrer">https://www.facebook.com/SuperNowosci24</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszów Info - <a href="https://www.facebook.com/rzeszowinfo" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowinfo</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Intro.Media - <a href="https://www.facebook.com/intro.mediaWSIiZ" target="_blank" rel="noreferrer">https://www.facebook.com/intro.mediaWSIiZ</a>
            </li>
          </ul>,
      },
      seventh: {
        title: '',
        text: <>
          <p>
            Serdeczne podziękowania kierujemy również do firm, które zorganizowały swoje drużyny i wspólnie pobiegły po marzenia naszych podopiecznych. <a href="https://www.facebook.com/coolturystagym" rel="noreferrer" target="_blank">https://www.facebook.com/coolturystagym</a>, <a href="https://www.facebook.com/polskieeplatnosci" rel="noreferrer" target="_blank">https://www.facebook.com/polskieeplatnosci</a> - jesteście wielcy! <br/>
            Dziękujemy wszystkim dzieciakom, które wzięły udział w biegu dla dzieci i wprowadziły tego dnia mnóstwo uśmiechu na nasze twarze! Mogliśmy obserwować debiut szesnastu małych biegaczy <span role="img" aria-label="">🙌</span>
          </p>
        </>,
      },
      eighth: {
        title: '',
        text: <>
          <p>
            <span role="img" aria-label="">👉</span>
            Przede wszystkim OGROMNE podziękowania wędrują do wszystkich tych, którzy przybyli na wydarzenie i przebiegli #piątkędlafundacji, wspierając nasze działania na rzecz podopiecznych <span role="img" aria-label="">❤️</span> Bez Was by się nie udało!
            <br/>
            <br/>
            Pokazaliście, jak środowisko sportowe, środowisko biegaczy ma piękne i hojne serca! Cieszymy się, że dołączyliśmy do rzeszowskiej, biegowej rodziny i że zostaliśmy tak miło przyjęci! <span role="img" aria-label="">😊</span>
            <br/>
            <br/>
            Jeśli już piszemy o sportowcach, to chcemy wspomnieć w tym miejscu o naszych wspaniałych gościach, którzy odwiedzili nas w sobotę i pokazali, że sport to piękne emocje, radość i współpraca <span role="img" aria-label="">💪</span>
            <span role="img" aria-label="">🌞</span>
            :
          </p>
          <ul>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Łukasz Różański oraz Tymoteusz Jakubowski z Rzeszowski Klub Bokserski ,,Wisłok"
            </li>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Michał Bartusik
            </li>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Andrzej Jodłowski
            </li>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Paweł Sanecki
            </li>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Renata Grabska
            </li>
            <li>
              <span role="img" aria-label="">▪</span>
              ️ Robert Trebi Corab
            </li>
          </ul>
          <p>
            <br/>
            <br/>
            <span role="img" aria-label="">🟣</span>
            Razem zrobiliśmy dużo DOBREGO! Do zobaczenia na kolejnych wydarzeniach - zachęcamy do dalszej obserwacji naszych mediów społecznościowych, gdzie zamieścimy fotorelacje z eventu oraz informacje o kolejnych akcjach <span role="img" aria-label="">❤️</span>️
            <br/>
            <br/>
            <span role="img" aria-label="">▪</span>
            ️ To było pierwsze wydarzenie sportowe, jakie mieliśmy przyjemność organizować i mamy nadzieję, że nie ostatnie! Jak myślicie, dobrze nam poszło? <span role="img" aria-label="">😊</span>
            <br/>
            <br/>
            __________ <br/>
            Fotograf: <br/>
            <span role="img" aria-label="">📸</span>
            Jakub Głogowski <br/>
            Kacper Porada <a href="https://www.facebook.com/profile.php?id=100069653350294" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100069653350294</a>
          </p>
        </>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_13.childImageSharp.fluid}/>,
          <Image fluid={images.img_14.childImageSharp.fluid}/>,
          <Image fluid={images.img_16.childImageSharp.fluid}/>,
          <Image fluid={images.img_17.childImageSharp.fluid}/>,
          <Image fluid={images.img_18.childImageSharp.fluid}/>,
          <Image fluid={images.img_19.childImageSharp.fluid}/>,
          <Image fluid={images.img_20.childImageSharp.fluid}/>,
          <Image fluid={images.img_23.childImageSharp.fluid}/>,
          <Image fluid={images.img_24.childImageSharp.fluid}/>,
          <Image fluid={images.img_25.childImageSharp.fluid}/>,
          <Image fluid={images.img_26.childImageSharp.fluid}/>,
          <Image fluid={images.img_28.childImageSharp.fluid}/>,
          <Image fluid={images.img_29.childImageSharp.fluid}/>,
          <Image fluid={images.img_30.childImageSharp.fluid}/>,
          <Image fluid={images.img_31.childImageSharp.fluid}/>,
          <Image fluid={images.img_32.childImageSharp.fluid}/>,
          <Image fluid={images.img_34.childImageSharp.fluid}/>,
          <Image fluid={images.img_35.childImageSharp.fluid}/>,
          <Image fluid={images.img_36.childImageSharp.fluid}/>,
          <Image fluid={images.img_37.childImageSharp.fluid}/>,
        ],
      },
    },
  };
};

