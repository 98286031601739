import {useLocation} from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {StickyLink} from 'sections/News/NewsLayout/styles.js';
import {useArticleImages} from './ArticleImages';

export const News_52 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Ubrania do oddania',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '10 / 10 / 2022',
    description: 'Wspieraj nas przez Ubrania do oddania!',
    slug: '/wydarzenia/ubrania-do-oddania',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <>
          <span role="img" aria-label="">👉️</span>
          Wspieraj nas przez Ubrania do oddania!</>,
        text:
          <p>
            Czy wiesz, że oddając ubrania na „Ubrania do Oddania”, możesz wesprzeć działalność naszej Fundacji? <span role="img" aria-label=""> 😊</span>
          </p>,
      },
      second: {
        title: <>
          <span role="img" aria-label="">🟣</span>
          Jak to zrobić?</>,
        text:
          <ul>
            <li>
              1. Spakuj ubrania w kartony, możesz oddać także buty, torebki oraz akcesoria.
            </li>
            <li>
              2. Zarejestruj się na <a href="https://bit.ly/ubrania-do-oddania" rel="noreferrer" target="_blank">https://bit.ly/ubrania-do-oddania</a> i wybierz wsparcie dla Fundacji Świat Bez Barier.
            </li>
            <li>
              3. Zamów darmowego kuriera i przekaż mu spakowane kartony.
            </li>
            <li>
              4. To wszystko! Każdy przekazany 1 kg ubrań to 1 zł na wsparcie działalności naszej Fundacji.
            </li>
          </ul>,
      },
      third: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">🔸</span>
            Pamiętaj, żeby ubrania, które chcesz przesłać były czyste i nie zniszczone <span role="img" aria-label="">😉</span>
          </p>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },

    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
        ],
      },
    },
  };
};

