import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_23 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Pomoc dla obywateli Ukrainy!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "26 / 02 / 2022",
    description:
                      "Jako Fundacja działająca na rzecz dzieci mocno solidaryzujemy się z naszymi sąsiadami ,a zwłaszcza z dziećmi, które straciły swój dom , stabilność a niektóre z nich rodzinę. ",
    slug:             "/wydarzenia/pomoc-dla-obywateli-ukrainy",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 💙 &nbsp;&nbsp;Jako Fundacja działająca na rzecz dzieci mocno solidaryzujemy się z naszymi sąsiadami ,a zwłaszcza z dziećmi, które straciły swój dom , stabilność a niektóre z nich rodzinę.<br/>
                 💛 &nbsp;&nbsp;Doskonale zdajemy sobie sprawę jaki ma to wpływ na ich życie i jak zachwiało się ich poczucie bezpieczeństwa.<br/>
                 💙 &nbsp;&nbsp;Nasza Fundacja kieruje się głównie dobrem dziecka, zrobimy co w naszej mocy aby pomóc dzieciom które trafiły lub trafią do Polski.<br/>
                 💛 &nbsp;&nbsp;Jeśli ktokolwiek potrzebuje pomocy dla swoich dzieci prosimy o kontakt: 693380754 lub o wysłanie wiadomości prywatnej.<br/>
                 💙 &nbsp;&nbsp;W poniedziałek będziemy planowali długoterminowa pomoc dla Ukraińskich dzieci i poinformujemy was w jaki sposób możecie nas w tym wesprzeć.<br/>
                 💛 &nbsp;&nbsp;Pamiętajcie dobro wraca! Całym sercem jesteśmy z Wami !
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
