import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_38 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Zbiórka z Polskie Radio Rzeszów",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "28 / 04 / 2022",
        description: "Kochani! Razem z Polskie Radio Rzeszów organizujemy zbiórkę najpotrzebniejszych darów dla ukraińskich żołnierzy walczących na froncie oraz ich rodzin",
        slug: "/wydarzenia/zbiorka-z-polskie-radio-rzeszow",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span> Kochani! Razem z <a href="https://www.facebook.com/PolskieRadioRzeszow/" target="_blank" rel='noopener'>Polskie Radio Rzeszów</a> organizujemy zbiórkę najpotrzebniejszych darów dla ukraińskich żołnierzy walczących na froncie oraz ich
                        rodzin. <br/> <br/>
                        <span role='img' aria-label=''>→</span> Dziennikarze Polskiego Radia Rzeszów byli już na Ukrainie
                        z pomocą humanitarną kilka razy. Byli m.in. w Kijowie, Buczy czy Irpieniu. <br/> <br/>
                        <span role='img' aria-label=''>👉</span> Aktualnie dla rodzin żyjących na wschodzie oraz
                        żołnierzy tam stacjonujących najpotrzebniejsze są maski przeciwgazowe, które zapewnią im
                        bezpieczeństwo <span role='img' aria-label=''>❗</span>️
                    </p>
                </>
            },
            second: {
                title: <><span role='img' aria-label=''>🔵</span> Jak możesz pomóc? </>,
                text: <>
                    <p>
                        <span role='img' aria-label=''>1️⃣</span> Dokonaj wpłaty na konto Fundacji - sam zdecyduj, ile
                        chciałbyś przeznaczyć na ten cel. Dzięki otrzymanym funduszom zapewnimy ukraińskim rodzinom
                        żyjącym na wschodzie kraju to, czego będą potrzebować najbardziej. <br/> <br/>
                        <span role='img' aria-label=''>→</span> Po zakończonej akcji napiszemy, jakie rzeczy zostały
                        kupione za zgromadzone środki <span role='img' aria-label=''>😊</span> <br/>

                        <span role='img' aria-label=''>👉</span> Link do zbiórki: <a
                        href="https://wsparcie.fundacjaswiatbezbarier.pl/?utm_source=FACEBOOK&utm_medium=IV%20-%20WK&utm_campaign=POST%20FB%20IG&fbclid=IwAR3sAc4fWXAvFbLUv4JV0axPXG8WjJjQUymbopWRIcXXHrW1lXIgj0hxicw"
                        target='_blank'>
                        fundacjaswiatbezbarier-wsparcie</a><span role='img' aria-label=''> ❤</span> <br/> <br/>️

                        <span role='img' aria-label=''>2️⃣</span> Przynieś rzeczy, które ratują życie: <br/>
                        <ul>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;żywność długoterminowa
                            </li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;rękawiczki wojskowe</li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;maski przeciwgazowe</li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;kamizelki kuloodporne
                            </li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;środki przeciwbólowe</li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;środki opatrunkowe</li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;środki higieny osobistej (szampony,
                                żele pod prysznic, chusteczki nawilżane, pampersy dla dorosłych, maszynki do golenia,
                                mydła)
                            </li>
                            <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;komplety mundurowe</li>
                        </ul>
                    </p>
                </>

            },
            third: {
                title: <><span role='img' aria-label=''>🟡</span> Punkty odbioru:</>,
                text: <p>
                    <span role='img' aria-label=''>▪</span> Siedziba Polskiego Radia Rzeszów - ul.Zamkowa 3 (prosimy o
                    kontakt <a href="tel:669276067">669276067</a> przed przywiezieniem paczki) <br/>
                    <span role='img' aria-label=''>▪</span> Siedziba firmy InteractiveVision - Rzeszów, ul.Przemysłowa
                    14b
                </p>
            },
            fourth: {
                title: <><span role='img' aria-label=''>🔵</span> Kontakt:</>,
                text: <p>
                    <span role='img' aria-label=''>☎</span> <a href="tel:178521501">17 85 21 501</a> lub <span
                    role='img' aria-label=''>📞</span>
                    <a href="tel:669276067">669 276 067</a> (Polskie Radio Rzeszów) <br/>
                    <span role='img' aria-label=''>📞</span> <a href="tel:693380754">693 380 754</a> (Fundacja Świat Bez
                    Barier)
                </p>
            },
        },
        images:           {
            first: {
                show:  true,
                full:  true,
                image: [
                    {
                        image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            },
        },
    }
}

