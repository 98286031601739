import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_15 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Finał Akcji Mikołaj 2021",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "06 / 12 / 2021",
    description:
                      "Oto jakże piękny finał naszej tegorocznej akcji Mikołaj 2021!",
    slug:             "/wydarzenia/final-akcji-mikolaj-2021",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: "",
        text:  <>
                 Oto jakże piękny finał naszej tegorocznej akcji Mikołaj 2021! <span role="img" aria-label="santa emoji">🎅🏻</span> <br/>
                 Uwaga! Wzruszenie gwarantowane! <span role="img" aria-label="face with heads emoji">🤗</span>
               </>
      },
      second: {
        title: '',
        text:  <>
                 Sukces? Zdecydowanie tak! <br/>
                 Czy było warto? Oczywiście! <br/>
                 Z roku na rok udowadniacie, że jesteście wspaniali <span role="img" aria-label="smiling face emoji with closed eyes">☺️</span> <br/>
                 Bez WAS by się nie udało. Dziękujemy z całego serca - my, ale przede wszystkim wdzięczne dzieci <span role="img" aria-label="heart emoji">❤️</span>
               </>
      }
    },
    lists:            {
      first: {
        title: "Dzięki Waszej wspaniałej nieocenionej pomocy udało się:",
        items: [
          <>przygotować 29 pięknych prezentów - w pełni spełniliśmy marzenia z listów, brawo! <span role="img" aria-label="present emoji">🎁</span></>,
          <>obdarować ośrodek voucherami między innymi na wyjście na gokarty, laserowy paintball, do centrum wspinaczkowego Flywall, muzeum Dobranocek oraz na kurs fotografii i wycieczkę w góry <span role="img" aria-label="voucher emoji">🎟</span></>,
          <>zakupić przybory edukacyjne takie jak: globusy, atlasy, pomoce naukowe, maty edukacyjne, edukacyjne zabawki i wiele innych <span role="img" aria-label="checkmark emoji">✅</span></>,
          <>wyposażyć kącik muzyczny w niezbędne elementy, które pomogą Jackowi prowadzić jego zajęcia <span role="img" aria-label="keyboard emoji">🎹</span></>
        ],
      },
    },
    gallery:          [
      <Image fluid={images.img_1.childImageSharp.fluid}/>,
      <Image fluid={images.img_5.childImageSharp.fluid}/>,
      <Image fluid={images.img_6.childImageSharp.fluid}/>,
      <Image fluid={images.img_7.childImageSharp.fluid}/>,
      <Image fluid={images.img_9.childImageSharp.fluid}/>,
      <Image fluid={images.img_10.childImageSharp.fluid}/>,
      <Image fluid={images.img_2.childImageSharp.fluid}/>,
      <Image fluid={images.img_3.childImageSharp.fluid}/>,
      <Image fluid={images.img_4.childImageSharp.fluid}/>,
    ],
  }
}
