import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_6 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Akcja “Podaruj Dzieciom Książke“",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "05 / 03 / 2021",
    description:
                      "Nasza Fundacja wychodzi z kolejną inicjatywą - podaruj naszym podopiecznym książkę.",
    slug:             "/wydarzenia/akcja-podaruj-dzieciom-ksiazke",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: "",
        text:  <>Nasza Fundacja wychodzi z kolejną inicjatywą - podaruj naszym podopiecznym książkę! <span role="img" aria-label="book emoji"> 📖</span>
          <br/>
          Istotą fundacji jest poszerzanie horyzontów, motywacja i edukacja. Wierzymy, że dzięki zapewnieniu im dostępu do edukacyjnych, rozwijających wyobraźnie i pouczających książek poszerzymy ich horyzonty <span role="img" aria-label="smiling face emoji"> 😊</span>
               </>,
      },
      second: {
        title: "",
        text:  <><b>Cel akcji:</b> zebranie różnorodnych książek, aby zbudować biblioteczkę naszym podopiecznym. <span role="img" aria-label="books emoji">📚</span> </>
      },
      third:  {
        title: "",
        text:  <>Jeśli chcesz dołączyć się do akcji, skontaktuj sie z nami w wiadomości prywatnej lub zadzwoń: <a href="tel:693380754">+48&nbsp;693&nbsp;380&nbsp;754</a> <span role="img" aria-label="phone emoji">📞</span></>,
      },
    },

    lists:  {
      first: {
        title: <>Jak <span role="img" aria-label="question mark emoji">❓</span></>,
        items: [
          'Podaruj nam książkę, która jest w dobrym stanie',
          <>Kup nową książke z zasugerowanej przez nas listy. Z wielką uwagą staraliśmy sie dobrać tytuły książek, jakie chcielibyśmy podarowac naszym podpiecznym. Bardzo zależy nam na ich zdobyciu, więc będziemy niezmiernie wdzięczni za zakupienie chodź jednego tytułu <span role="img" aria-label="praying emoji">🙏🏻</span></>,
        ],
      },
    },
    images: {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
