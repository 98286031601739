import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_45 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Piknik z okazji zakończenia roku szkolnego",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "14 / 07 / 2022",
    description:      "Z okazji zakończenia roku szkolnego wybraliśmy się z naszymi podopiecznymi na piknik.",
    slug:             "/wydarzenia/piknik",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️ Z okazji zakończenia roku szkolnego wybraliśmy się z naszymi podopiecznymi na piknik<span role="img" aria-label=''>❤️</span>
               </p>
      },
      second: {
        title: '',
        text:
        <p>
          <span role="img" aria-label=''>→</span>️  Nie zabrakło gier planszowych, rozgrywek sportowych oraz pysznego jedzenia 😍 Nie było czasu na nudę, a jeśli ktoś potrzebował odpoczynku - miał do dyspozycji mnóstwo miłych kocyków <span role="img" aria-label=''>🥰</span>️
        </p>
      },
      third:  {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️ To był wspaniały dzień 😊 Wracaliśmy z uśmiechami na twarzach i już nie możemy się doczekać kolejnych wspólnych wyjść
                 <span role="img" aria-label=''>❤</span>️️
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
    gallery:          {
      first: {
        images: [
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
        ]
      },
    }
  }
}

