import {graphql, useStaticQuery} from "gatsby"

export const useArticleImages = () => {
  const query = useStaticQuery(graphql`
      query {
          thumb: file(relativePath: { eq: "img/Blog/article_19/thumb.png" }) {
              childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          event: file(relativePath: { eq: "img/Blog/article_19/event.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img_1: file(relativePath: { eq: "img/Blog/article_19/img_1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 595, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }          
          img_2: file(relativePath: { eq: "img/Blog/article_19/img_2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 595, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }          
          img_3: file(relativePath: { eq: "img/Blog/article_19/img_3.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `)
  return query
}
