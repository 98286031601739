import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_16 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Nowy keyboard - gwarancja ogólnorozwojowych zajęć muzycznych",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "25 / 01 / 2022",
    description:
                      "Dzięki Wam zakupiliśmy nowy keyboard, który posłuży Jackowi - utalentowanemu i oddanemu opiekunowi - do prowadzenia zajęć muzycznych",
    slug:             "/wydarzenia/nowy-keyboard",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: "",
        text:  <>
                 ️Dzięki Wam zakupiliśmy nowy keyboard, który posłuży Jackowi - utalentowanemu i oddanemu opiekunowi - do prowadzenia zajęć muzycznych 🎹 <br />
                 ️Jacek od dawna prowadzi zajęcia z dzieciakami, a nowy sprzęt był niezbędnym elementem, dzięki któremu może je kontynuować. Teraz zajęcia będą efektywniejsze i ciekawsze! 🎼<br />
                 A skoro już o tym mowa - trwają intensywne próby z wykorzystaniem nowego instrumentu 😎<br />
                 Już niedługo odbędzie się mini koncert w wykonaniu niektórych z naszych podopiecznych, a zapewniamy, że są to przepięknie śpiewające perełki 🎤<br />
                 Pozostańcie z nami! A my podzielimy się wrażeniami z tego wydarzenia 😊<br />
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
