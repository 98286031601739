import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_9 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Centrum Inwestycji Deweloperskich współpraca",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "11 / 10 / 2021",
    description:
                      "Z radością informujemy, że Centrum Inwestycji Deweloperskich Rzeszów rozpoczęło wspaniałą akcję dla naszej Fundacji",
    slug:             "/wydarzenia/wsparcie-od-centrum-inwestycji-deweloperskich-rzeszow",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:  <>Dowolne kwoty możecie również wpłacać stacjonarnie - w biurze czeka na Was specjalna skarbonka. Znajdziecie ich pod adresem <span role="img" aria-label="arrow down emoji">⤵️</span>
          <br/>
          <strong>ul. Króla Kazimierza 20/3, Rzeszów 35-061 </strong> <span role="img" aria-label="pin emoji">📍</span> <br/>
               </>
      },
      second: {
        title: '',
        text:  <>
                 Jesteśmy bardzo wdzięczni za okazane serduszko i chęć pomocy - razem zdziałamy więcej! <span role="img" aria-label="heart emoji">❤️</span>
                 <br/> Pamiętajcie, że Święta zbliżają się wielkimi krokami, a my chcemy sprawić, by były one dla wszystkich wyjątkowe <span role="img" aria-label="smiling face emoji">😊</span>
               </>,
      },
      third:  {
        title: '',
        text:  'Firmy, które chciałyby się dołączyć do akcji, prosimy o kontakt. Z chęcią udzielimy wszelkich wskazówek i odpowiemy na pytania.',
      }
    },
    lists:            {
      first: {
        title: "",
        items: [
          <>Z radością informujemy, że Centrum Inwestycji Deweloperskich Rzeszów rozpoczęło wspaniałą akcję dla naszej Fundacji <span role="img" aria-label="exclamation mark emoji">❗</span></>,
          <>Aż do grudnia za każde sprzedane mieszkanie Fundacja otrzyma 300 zł na poczet zbliżającej się wielkimi krokami Akcji Mikołaj 2021 <span role="img" aria-label="santa emoji">🎅🏻</span></>,
        ],
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
