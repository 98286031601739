import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"
import { Link } from "gatsby"

export const Container = styled(GlobalWrapper)`
  padding: 96px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`

export const Wrapper = styled(SectionWrapper)`
  position: relative;
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        height: auto;
        display: flex;
        flex-direction: column;
      }
    }
  }
`

export const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  opacity: 0;
  transition: 0.2s opacity ease;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    opacity: 0 !important;
  }
`

export const SlideWrapper = styled(Link)`
  background: #ffffff;
  height: 100%;
  cursor: pointer;
  &:hover {
    ${Hover} {
      opacity: 1;
    }
  }
`

export const ImageBox = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    margin: 0 auto;
  }
`

export const TextBox = styled.div`
  background-color: #fff;
  padding: 20px 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 18px;
  }
`

export const Date = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 12px;
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
  }
`
