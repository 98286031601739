import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_57 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Podsumowanie 2022',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '03 / 01 / 2023',
    description: 'Zakończył się 𝟮𝟬𝟮𝟮 rok, który przyniósł nam wiele wspaniałych chwil...',
    slug: '/wydarzenia/podsumowanie-2022',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉 </span>
            Zakończył się 𝟮𝟬𝟮𝟮 rok, który przyniósł nam wiele wspaniałych chwil...
            <br/>
            Chcemy w tym miejscu podsumować ostatnie 365 dni i przypomnieć, co udało nam dokonać i
            jakie wydarzenia organizowaliśmy.
          </p>
      },
      second: {
        title: <>W 𝟮𝟬𝟮𝟮 roku opłaciliśmy naszym podopiecznym:</>,
        text: <>
          <ul>
            <li><span role="img" aria-label="">→ </span> 112 godzin korepetycji,</li>
            <li><span role="img" aria-label="">→ </span> 2 kursy na prawo jazdy,</li>
            <li><span role="img" aria-label="">→ </span> 2 kursy zawodowe,</li>
            <li><span role="img" aria-label="">→ </span> 14 pobytów na koloniach letnich i zimowych,</li>
            <li><span role="img" aria-label="">→ </span> niezliczoną liczbę wspólnych wyjść na różne atrakcje: do sal zabaw, do kina, na lody, do parków rozrywki (ścianki wspinaczkowe, flypark itp).</li>
          </ul>
        </>,
      },
      third: {
        title: "",
        text: <>
          <ul>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Styczeń: opłaciliśmy kurs prawa jazdy dla naszej podopiecznej.
            </li>

            <li>
              <span role="img" aria-label="">🔸 </span>
              Luty: opłaciliśmy tygodniowe zimowe obozy konne dla 4 dzieciaków.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Marzec: organizowaliśmy zbiórki dla Ukrainy, wysłaliśmy łącznie 6 transportów - głównie do
              sierocińców dla dzieci i do szpitali.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Kwiecień: zorganizowaliśmy zbiórkę wraz z Polskie Radio Rzeszów na wsparcie dla szpitali
              wojskowych.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Maj: prowadziliśmy bezpłatne zajęcia z języka polskiego dla uchodźców z Ukrainy.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Czerwiec: Dzień Dziecka dla podopiecznych - zorganizowaliśmy słodki poczęstunek i małe
              prezenty. Zorganizowaliśmy piknik i animacje sportowe na zakończenie roku dla naszych podopiecznych.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Lipiec: opłaciliśmy 9-dniowe obozy konne dla 8 dzieciaków.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Sierpień: zorganizowaliśmy animacje dla dzieci i koncert charytatywny w <a href="https://www.instagram.com/fosarzeszow/" target="_blank" rel="noreferrer">@fosarzeszow.</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Wrzesień: zorganizowaliśmy Rodzinny Piknik Charytatywny.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>
              Październik: zorganizowaliśmy nasz pierwszy, charytatywny bieg "Piątka dla Fundacji". Wzięło
              w nim udział 103 biegaczy.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>

              Listopad: objęliśmy patronatem akcję <a href="https://www.instagram.com/explore/tags/fortepiandlarzeszowa/" target="_blank" rel="noreferrer">#FortepianDlaRzeszowa</a>.
            </li>
            <li>
              <span role="img" aria-label="">🔸 </span>

              Grudzień: zorganizowaliśmy AKCJĘ Mikołaj 2022, dzięki której 24 podopiecznych otrzymało
              spersonalizowane prezenty od Świętego Mikołaja. Wspólnie z Urzędem Miasta Rzeszowa oraz <a href="https://www.instagram.com/estradarzeszow/" target="_blank" rel="noreferrer">@estradarzeszow</a> zorganizowaliśmy również "Świąteczne Kolędowanie przy Rzeszowskim Fortepianie" w ramach Świątecznego Miasteczka.
            </li>
          </ul>

          <br/>
          <br/>
          <span role="img" aria-label="">🌻 </span>
          To wszystko udało się osiągnąć dzięki ogromnemu wsparciu wszystkich naszych wolontariuszy, sponsorów i partnerów!
          <br/>
          <br/>
          <strong>DZIĘ-KU-JE-MY</strong>! za wsparcie rzeczowe i finansowe! <span role="img" aria-label="">❤️</span>
        </>
      }
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
  };
};

