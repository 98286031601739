import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_41 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Obozy konne",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "26 / 05 / 2022",
        description: "Zbieramy fundusze na letnie obozy jeździeckie dla naszych podopiecznych.️",
        slug: "/wydarzenia/obozy-konne",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text:
                    <p>
                        ▪️ Kochani, zbieramy fundusze na letnie obozy jeździeckie dla naszych podopiecznych ☀️
                    </p>

            },
            second: {
                title: '',
                text:
                    <p>
                        O wyjeździe na obóz marzy 10 dzieci. Bardzo byśmy chcieli, aby w tym roku każde z nich mogło
                        spełnić to marzenie, dlatego zachęcamy do wsparcia projektu  <span role='img' aria-label=''>❤️</span>
                        <br/>
                        <span role='img' aria-label=''>👉 ️</span> Koszt jednego uczestnika to 2000 zł za 9 dni.
                    </p>
            },
            third: {
                title: '',
                text:
                    <p>
                        W tamtym roku udało nam się zabrać 4 podopiecznych. Wspierając zbiórkę możecie sprawić, że tym
                        razem uśmiech pojawi się aż na 10 twarzach  <span role='img' aria-label=''>😊</span>
                        <br/>
                        <span role='img' aria-label=''>▪</span> ️ Liczymy na Was! ️
                    </p>
            },
            fourth: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>🟣</span>  Link do wsparcia: <a href="https://wsparcie.fundacjaswiatbezbarier.pl/pomoc" rel="noreferrer"
                                                target='_blank'>https://wsparcie.fundacjaswiatbezbarier.pl/pomoc</a>
                        <br/>
                        <br/>
                        lub
                        <br/> <br/>
                        <span role='img' aria-label=''>🟣</span> Możesz wykonać przelew tradycyjny: <br/>
                        ➖ Numer Rachunku: 42 1600 1462 1898 0457 3000 0003 <br/>
                        Fundacja Świat Bez Barier, ul.Przemysłowa 14b, 35-105 Rzeszów <br/>
                        Tytuł: Obozy Letnie <br/>
                        <br/> <br/>
                        <span role='img' aria-label=''>▪</span>️ Każda przelana złotówka zostanie przekazana na poczet naszych podopiecznych! 😊
                        <br/> <br/>
                        <span role='img' aria-label=''>✉</span>️️ <a href="mailto:biuro@fundacjaswiatbezbarier.pl">biuro@fundacjaswiatbezbarier.pl</a> <br/>
                        <span role='img' aria-label=''>☎️</span>️️ <a href="tel:693380754">693380754</a>
                    </p>
            },
        },
        images: {
            first: {
                show: true,
                full: true,
                image: [
                    {
                        image: <Image fluid={images.img_1.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            }
        },
    }
}

