import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_46 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "I Turnus Obozów konnych",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "18 / 07 / 2022",
    description:      "Przedstawiamy Wam fotorelację z I turnusu obozu jeździeckiego, na który pojechała dwójka naszych podopiecznych - Emilka i Karol ",
    slug:             "/wydarzenia/pierwszy-turnus-obozow-konnych",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️️ Przedstawiamy Wam fotorelację z I turnusu obozu jeździeckiego, na który pojechała dwójka naszych podopiecznych - Emilka i Karol <span role="img" aria-label=''>❤</span>️ <br/> <br/>
                 <span role="img" aria-label=''>→</span>️️ Codziennie uczyliśmy się dyscypliny - wstawaliśmy wcześnie rano, aby nakarmić i pielęgnować konie <span role="img" aria-label=''>🐴</span>️ Dzieci z wielką determinacją zgłębiały tajniki jazdy konnej - uczyły się nie tylko techniki, ale również cierpliwości i pokory. Konie są wspaniałymi nauczycielami <span role="img" aria-label=''>😊</span>️
               </p>
      },
      second: {
        title: '',
        text:
               <p>
                 <span role="img" aria-label=''>👉</span>️ O takim obozie marzy jeszcze 6 naszych podopiecznych. Liczymy na to, że dzięki Waszej pomocy te marzenia również uda się spełnić <span role="img" aria-label=''>❤️</span>️
               </p>
      },
      third:  {
        title: <><span role="img" aria-label=''>️️🟣</span> Jak możecie pomóc?</>,
        text:
               <ul>
                 <li>➖ Wpłacając dowolną kwotę poprzez naszą stronę: <a href="https://wsparcie.fundacjaswiatbezbarier.pl/" rel="noreferrer" target='_blank'>https://wsparcie.fundacjaswiatbezbarier.pl/</a></li>
                 <li>➖ Biorąc udział w naszych licytacjach (możecie również przekazać własne przedmioty na ten cel - zapraszamy do kontaktu <span role="img" aria-label=''>😊</span>)</li>
                 <li>➖ Śledząc naszą stronę (szykujemy kilka akcji, w których warto wziąć udział - więcej informacji już wkrótce <span role="img" aria-label=''>️️❤</span>️)</li>
               </ul>
      },
      fourth: {
        title: <><span role="img" aria-label=''>🟡</span> Możecie wykonać również przelew tradycyjny:</>,
        text:
               <ul>
                 <li>
                   <span role="img" aria-label=''>➖</span> Numer Rachunku: 42 1600 1462 1898 0457 3000 0003 <br/>
                   Fundacja Świat Bez Barier, ul.Przemysłowa 14b, 35-105 Rzeszów <br/>
                   Tytuł: <strong>Obozy Letnie</strong>
                 </li>
               </ul>
      },
      fifth:  {
        title: <>▪️ Każda przelana złotówka zostanie przekazana na poczet naszych podopiecznych! <span role="img" aria-label=''>😊</span></>,
        text:
               <p>
                 <span role="img" aria-label=''>✉ </span>️ <a href="mailto:biuro@fundacjaswiatbezbarier.pl ">biuro@fundacjaswiatbezbarier.pl </a>
                 <br/>
                 <span role="img" aria-label=''>☎️ </span> <a href="tel:693380754">693380754</a>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
    gallery:          {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_13.childImageSharp.fluid}/>,
          <Image fluid={images.img_14.childImageSharp.fluid}/>,
          <Image fluid={images.img_15.childImageSharp.fluid}/>,
          <Image fluid={images.img_16.childImageSharp.fluid}/>,
          <Image fluid={images.img_17.childImageSharp.fluid}/>,
          <Image fluid={images.img_18.childImageSharp.fluid}/>,
          <Image fluid={images.img_19.childImageSharp.fluid}/>,
          <Image fluid={images.img_20.childImageSharp.fluid}/>,
          <Image fluid={images.img_21.childImageSharp.fluid}/>,
        ]
      },
    }
  }
}

