import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_22 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Półmetek obozu jeździeckiego już za nami!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "23 / 02 / 2022",
    description:
                      "Właśnie minęła połowa naszego obozu jeździeckiego.",
    slug:             "/wydarzenia/polmetek-obozu-jezdzieckiego-juz-za-nami",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 Właśnie minęła połowa naszego obozu jeździeckiego. Emilka i Zuzia jak do tej pory nauczyły się samodzielnie kłusować i świetnie prowadzić konia. Jesteśmy bardzo dumni z naszych dziewczyn, brawo!
                 Każdego dnia uczą się one dużej dyscypliny związanej z jazdą konną, która niewątpliwie jest wymagającym sportem. Jednak codzienne dyżury w domu i w stajni nie są im straszne! Na obozie jest czas i na zabawę, i na obowiązki, z których dziewczyny wywiązują się na medal
                 Pomóżcie nam kontynuować naszą misję i sprawiać, że więcej naszych podopiecznych będzie miało szansę doświadczyć i nauczyć się nowych rzeczy. Wiecie, co robić!
                 Link poniżej :<br/>
                 <a href="https://wsparcie.fundacjaswiatbezbarier.pl/">https://wsparcie.fundacjaswiatbezbarier.pl/</a>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
