export const NEWS_1 = 'NEWS_1';
export const NEWS_2 = 'NEWS_2';
export const NEWS_3 = 'NEWS_3';
export const NEWS_4 = 'NEWS_4';
export const NEWS_5 = 'NEWS_5';
export const NEWS_6 = 'NEWS_6';
export const NEWS_7 = 'NEWS_7';
export const NEWS_8 = 'NEWS_8';
export const NEWS_9 = 'NEWS_9';
export const NEWS_10 = 'NEWS_10';
export const NEWS_11 = 'NEWS_11';
export const NEWS_12 = 'NEWS_12';
export const NEWS_13 = 'NEWS_13';
export const NEWS_14 = 'NEWS_14';
export const NEWS_15 = 'NEWS_15';
export const NEWS_16 = 'NEWS_16';
export const NEWS_17 = 'NEWS_17';
export const NEWS_18 = 'NEWS_18';
export const NEWS_19 = 'NEWS_19';
export const NEWS_20 = 'NEWS_20';
export const NEWS_21 = 'NEWS_21';
export const NEWS_22 = 'NEWS_22';
export const NEWS_23 = 'NEWS_23';
export const NEWS_24 = 'NEWS_24';
export const NEWS_25 = 'NEWS_25';
export const NEWS_26 = 'NEWS_26';
export const NEWS_27 = 'NEWS_27';
export const NEWS_28 = 'NEWS_28';
export const NEWS_29 = 'NEWS_29';
export const NEWS_30 = 'NEWS_30';
export const NEWS_31 = 'NEWS_31';
export const NEWS_32 = 'NEWS_32';
export const NEWS_33 = 'NEWS_33';
export const NEWS_34 = 'NEWS_34';
export const NEWS_35 = 'NEWS_35';
export const NEWS_36 = 'NEWS_36';
export const NEWS_37 = 'NEWS_37';
export const NEWS_38 = 'NEWS_38';
export const NEWS_39 = 'NEWS_39';
export const NEWS_40 = 'NEWS_40';
export const NEWS_41 = 'NEWS_41';
export const NEWS_42 = 'NEWS_42';
export const NEWS_43 = 'NEWS_43';
export const NEWS_44 = 'NEWS_44';
export const NEWS_45 = 'NEWS_45';
export const NEWS_46 = 'NEWS_46';
export const NEWS_47 = 'NEWS_47';
export const NEWS_48 = 'NEWS_48';
export const NEWS_49 = 'NEWS_49';
export const NEWS_50 = 'NEWS_50';
export const NEWS_51 = 'NEWS_51';
export const NEWS_52 = 'NEWS_52';
export const NEWS_53 = 'NEWS_53';
export const NEWS_54 = 'NEWS_54';
export const NEWS_55 = 'NEWS_55';
export const NEWS_56 = 'NEWS_56';
export const NEWS_57 = 'NEWS_57';
export const NEWS_58 = 'NEWS_58';
export const NEWS_59 = 'NEWS_59';
export const NEWS_60 = 'NEWS_60';
export const NEWS_61 = 'NEWS_61';
export const NEWS_62 = 'NEWS_62';

export default {
  NEWS_1,
  NEWS_2,
  NEWS_3,
  NEWS_4,
  NEWS_5,
  NEWS_6,
  NEWS_7,
  NEWS_8,
  NEWS_9,
  NEWS_10,
  NEWS_11,
  NEWS_12,
  NEWS_13,
  NEWS_14,
  NEWS_15,
  NEWS_16,
  NEWS_17,
  NEWS_18,
  NEWS_19,
  NEWS_20,
  NEWS_21,
  NEWS_22,
  NEWS_23,
  NEWS_24,
  NEWS_25,
  NEWS_26,
  NEWS_27,
  NEWS_28,
  NEWS_29,
  NEWS_30,
  NEWS_31,
  NEWS_32,
  NEWS_33,
  NEWS_34,
  NEWS_35,
  NEWS_36,
  NEWS_37,
  NEWS_38,
  NEWS_39,
  NEWS_40,
  NEWS_41,
  NEWS_42,
  NEWS_43,
  NEWS_44,
  NEWS_45,
  NEWS_46,
  NEWS_47,
  NEWS_48,
  NEWS_49,
  NEWS_50,
  NEWS_51,
  NEWS_52,
  NEWS_53,
  NEWS_54,
  NEWS_55,
  NEWS_56,
  NEWS_57,
  NEWS_58,
  NEWS_59,
  NEWS_60,
  NEWS_61,
  NEWS_62,
};
