import React from "react"

import { Container } from "./styles"
import { Header } from "components/Typography"
import { SliderNav } from "components/SliderNav"

export const HeaderWithNav = ({
  title,
  prevClass,
  nextClass,
  align,
  length,
}) => {
  return (
    <Container align={align}>
      <Header>{title}</Header>
      <SliderNav prevClass={prevClass} nextClass={nextClass} length={length} />
    </Container>
  )
}
