import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_40 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Podsumowanie zbiórki z Polskie Radio Rzeszów",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "26 / 05 / 2022",
        description: "Nasza wspólna akcja z Polskim Radiem Rzeszów dobiegła końca.",
        slug: "/wydarzenia/podsumowanie-zbiorki-z-polskie-radio-rzeszow",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: 'Nasza wspólna akcja z Polskim Radiem Rzeszów dobiegła końca',
                text:
                    <>
                        <p>
                            Tym razem, dzięki Waszemu wsparciu, <b>udało się zebrać ponad 5 tysięcy złotych</b> oraz żywność
                            długoterminową i leki. <br/>
                            Dzięki Waszym wpłatom zakupiliśmy:
                        </p>
                        <ul>
                            <li><span role='img' aria-label=''>➖</span> rękawiczki taktyczne</li>
                            <li><span role='img' aria-label=''>➖</span> opaski uciskowe</li>
                            <li><span role='img' aria-label=''>➖</span> maski przeciwgazowe</li>
                            <li><span role='img' aria-label=''>➖</span> filtry do masek</li>
                        </ul>
                    </>

            },
            second: {
                title: '',
                text:
                    <p>
                        Wszystkie wyżej wymienione rzeczy wraz z Polskim Radiem Rzeszów zostały dostarczone osobiście do potrzebujących żołnierzy i ich rodzin walczących na Ukrainie. <br/>
                        Poniżej możecie zobaczyć relację z transportu. <br/>
                        Dziękujemy raz jeszcze za Wasze wielkie serca i ciągłą chęć niesienia pomocy! <br/>
                        <b>Fundacja Świat Bez Barier i Polskie Radio Rzeszów</b>
                    </p>
            },
            third: {
                title: 'Dary trafiły:',
                text: <>
                    <p>
                        - do centrum misyjnego sióstr Pallotynek w Gródku Jagiellońskim, a stamtąd m.in. do lotników w
                        Żytomierzu
                    </p>
                    <ul>
                        <li>
                            <span role='img' aria-label=''>➖</span> do żołnierzy obrony terytorialnej;
                        </li>
                        <li>
                            <span role='img' aria-label=''>➖</span> do szpitali i przywiezionych tam rannych z frontu żołnierzy w obwodzie Dniepropetrowskim;
                        </li>
                        <li>
                            <span role='img' aria-label=''>➖</span> do tymczasowego ośrodka dla ludzi uciekających ze wschodu Ukrainy.
                        </li>
                    </ul> <br/> <br/>
                    <b>FOT. POLSKIE RADIO RZESZÓW</b>
                </>

            },
        },
        images: {
            first: {
                show: true,
                full: true,
                image: [
                    {
                        image: <Image fluid={images.img_1.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            }
        },
        gallery: [
            <Image fluid={images.img_2.childImageSharp.fluid}/>,
            <Image fluid={images.img_3.childImageSharp.fluid}/>,
            <Image fluid={images.img_4.childImageSharp.fluid}/>,
            <Image fluid={images.img_5.childImageSharp.fluid}/>,
            <Image fluid={images.img_6.childImageSharp.fluid}/>,
            <Image fluid={images.img_7.childImageSharp.fluid}/>,
            <Image fluid={images.img_8.childImageSharp.fluid}/>,
            <Image fluid={images.img_12.childImageSharp.fluid}/>,
            <Image fluid={images.img_10.childImageSharp.fluid}/>,
            <Image fluid={images.img_11.childImageSharp.fluid}/>,
            <Image fluid={images.img_9.childImageSharp.fluid}/>,
            <Image fluid={images.img_13.childImageSharp.fluid}/>,
            <Image fluid={images.img_14.childImageSharp.fluid}/>,
            <Image fluid={images.img_15.childImageSharp.fluid}/>,
            <Image fluid={images.img_16.childImageSharp.fluid}/>,
        ],
    }
}

