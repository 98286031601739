import {graphql, useStaticQuery} from 'gatsby';

export const useArticleImages = () => {
  return useStaticQuery(graphql`
      query {
          thumb: file(relativePath: { eq: "img/Blog/article_53/thumb.png" }) {
              childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          event: file(relativePath: { eq: "img/Blog/article_53/event.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }

          hero: file(relativePath: { eq: "img/Blog/article_53/hero.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }

          img_1: file(relativePath: { eq: "img/Blog/article_53/img_1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }

 
      }
  `);
};
