import React from "react"
import Image from "gatsby-image"
import { useLocation } from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import { StickyLink } from "sections/News/NewsLayout/styles.js"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import { useFundationImages } from "./FundationImages"

export const News_1 = () => {
  const images = useFundationImages();
  const location = useLocation();

  return {
    date: "01 / 06 / 2019",
    title: "Dziś powstała nasza fundacja - Fundacja świat bez Barier.",
    hero: <Image fluid={images.hero.childImageSharp.fluid} />,
    description:
      "I jak w zeszłym roku, udało nam się sukcesywnie skompletować wszystkie prezenty, które były spełnieniem indywidualnych marzeń naszych dzieciaków.",
    slug: "/wydarzenia/nasza-fundacja",
    detail_image: <Image fluid={images.thumb.childImageSharp.fluid} />,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid} />,
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon} />
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon} />
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon} />
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: "Narodziny fundacji w Rzeszowie",
        text: (
          <>
            Dziś powstała nasza fundacja. Pół roku wcześniej, działając jeszcze
            jako osoby prywatne, zorganizowaliśmy spontaniczną zbiórkę prezentów
            dla podopiecznych z ośrodka opiekuńczo-wychowawczego w Rzeszowie.
            Dzięki wsparciu przyjaciół i osób indywidualnych udało nam się
            skompletować 27 pełnych prezentów, zakupić 10 tabletów i 2 laptopy.
            To spontaniczne wydarzenie zainspirowało nas do zrobienia większego
            kroku - założenia Fundacji. I tak rozpoczęła się nasz przygoda z
            pomaganiem dzieciom.
          </>
        ),
      },
    },
  }
}
