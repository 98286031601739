import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_20 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Zbieramy fundusze",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "17 / 02 / 2022",
    description:
                      "Dwoje naszych podopiecznych jedzie z nami w niedzielę na obóz jeździecki",
    slug:             "/wydarzenia/oboz-jezdzicki-dla-podopiecznych",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 Kochani! 2 naszych podopiecznych jedzie z nami w niedzielę na obóz jeździecki 🐎 <br/>
                 Aby pokryć jego koszty, niestety wykorzystaliśmy już prawie cały budżet. Dlatego prosimy Was o wsparcie! W każdy piątek zabieramy naszych podopiecznych w różne miejsca, by pokazać im nowe aktywności i atrakcje, np. na ściankę wspinaczkową, gokarty itp ☺️
                 Nawet najdrobniejsze wpłaty mogą nam pomóc w realizacji celów. Wejdźcie na naszą stronę i wspomóżcie realizacje naszych projektów z dziećmi 🥰
                 Bez Was się nie uda! Każdą przelaną złotówkę wykorzystujemy wyłącznie na rozwój naszych podopiecznych 🙏
                 PS Możecie również przekazać nam vouchery na wyjścia do różnych miejsc w Rzeszowie - jesteśmy pewni, że nasze dzieci będą nimi zachwycone! ❤️
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
