import { useStaticQuery, graphql } from "gatsby"

export const useFundationImages = () => {
  const query = useStaticQuery(graphql`
    query {
      #Fundacja 2020
      hero: file(relativePath: { eq: "img/Blog/fundacja/fundacja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      thumb: file(relativePath: { eq: "img/Blog/fundacja/thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      event: file(relativePath: { eq: "img/Blog/fundacja/fundacja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      #END
    }
  `)
  return query
}
