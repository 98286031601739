import styled, { css } from "styled-components"

import PrevIcon from "assets/img/svg/prev_slide.svg"
import NextIcon from "assets/img/svg/next_slide.svg"
import PrevIconHover from "assets/img/svg/prev_slide_hover.svg"
import NextIconHover from "assets/img/svg/next_slide_hover.svg"

const styles = css`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  background-color: transparent;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 30px;
    height: 30px;
    margin: 0 4px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    position: relative;
    right: initial;
    display: flex;
    justify-content: center;
    margin: 24px 0 24px 0;
  }
`

export const PrevButton = styled.button`
  background-image: url(${PrevIcon});
  ${styles};
  &:hover {
    background-image: url(${PrevIconHover});
  }
`

export const NextButton = styled.button`
  background-image: url(${NextIcon});
  ${styles};
  &:hover {
    background-image: url(${NextIconHover});
  }
`
