import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_29 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Pomoc dla szpitala i sierocińca w Ukrainie",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "25 / 03 / 2022",
    description:      "Kochani, włączamy się w akcję zorganizowania bezpośredniego transportu do sierocińca i szpitala w Iwano-Frankowsku w Ukrainie.",
    slug:             "/wydarzenia/pomoc-dla-szpitala-i-sierocinca-w-ukrainie",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:   {
        title: null,
        text:  <>
                 <p>Kochani, włączamy się w akcję zorganizowania bezpośredniego transportu do sierocińca i szpitala w Iwano-Frankowsku w Ukrainie 🇺🇦</p>
                 <p>Potrzeby są duże, więc i tym razem liczymy na Waszą pomoc 🙏</p>
               </>
      },
      second:  {
        title: <>🔵 Jak możecie pomóc?</>,
        text:  <>
                 <ul>
                   <li>👉 &nbsp;&nbsp;Wpłacając dowolną kwotę na: <a href="https://www.wsparcie.fundacjaswiatbezbarier.pl" target="_blank">www.wsparcie.fundacjaswiatbezbarier.pl</a></li>
                   <li>👉 &nbsp;&nbsp;Całość pozyskanych środków zostanie przeznaczona na zakup potrzebnych rzeczy.</li>
                   <li>👉 &nbsp;&nbsp;Przekazując nam rzeczy z list znajdujących się poniżej</li>
                 </ul>
               </>
      },
      third:   {
        title: <>🔹 Dla sierocińca:</>,
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;proszki do prania, mydła, szampony, kremy na odparzenia (np. alantan),</li>
                   <li>➖ &nbsp;&nbsp;leki dla dzieci (smecta, elektrolity, ibum, pedicetamol, syropy na kaszel itp.),</li>
                   <li>➖ &nbsp;&nbsp;pieluchy, podkłady, chusteczki nawilżane,</li>
                   <li>➖ &nbsp;&nbsp;jedzenie dla dzieci,</li>
                   <li>➖ &nbsp;&nbsp;owsianki dla dzieci,</li>
                   <li>➖ &nbsp;&nbsp;pościele, prześcieradła,</li>
                   <li>➖ &nbsp;&nbsp;duże ręczniki,</li>
                   <li>➖ &nbsp;&nbsp;papier toaletowy.</li>
                 </ul>
               </>
      },
      fourth:  {
        title: <>🔹 Dla szpitala:</>,
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;pampersy dla dorosłych (‼️),</li>
                   <li>➖ &nbsp;&nbsp;podkłady szpitalne,</li>
                   <li>➖ &nbsp;&nbsp;środki hemostatyczne,</li>
                   <li>➖ &nbsp;&nbsp;leki bez recepty (dexak, środki uspokajające, na biegunkę - węgiel biały i czarny, nifuroksazyd itp.)</li>
                   <li>➖ &nbsp;&nbsp;nadtlenek wodoru (woda utleniona),</li>
                   <li>➖ &nbsp;&nbsp;bandaże,</li>
                   <li>➖ &nbsp;&nbsp;serwety, prześcieradła medyczne,</li>
                   <li>➖ &nbsp;&nbsp;cienkie męskie skarpety (nowe),</li>
                   <li>➖ &nbsp;&nbsp;uprząż opaski uciskowej,</li>
                   <li>➖ &nbsp;&nbsp;ciśnieniomierze,</li>
                   <li>➖ &nbsp;&nbsp;termometry.</li>
                 </ul>
               </>
      },
      fifth:   {
        title: <>🔸 Produkty higieniczne:</>,
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;maszynki do golenia jednorazowe,</li>
                   <li>➖ &nbsp;&nbsp;pianki do golenia,</li>
                   <li>➖ &nbsp;&nbsp;wilgotne chusteczki,</li>
                   <li>➖ &nbsp;&nbsp;detergenty (mydła, szampony).</li>
                 </ul>
               </>
      },
      sixth:   {
        title: <>🔸 Żywność:</>,
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;produkty typu fast food (zupki chińskie),</li>
                   <li>➖ &nbsp;&nbsp;napoje energetyczne,</li>
                   <li>➖ &nbsp;&nbsp;batoniki, słodycze,</li>
                   <li>➖ &nbsp;&nbsp;mleko skondensowane, UHT,</li>
                   <li>➖ &nbsp;&nbsp;czekolada,</li>
                   <li>➖ &nbsp;&nbsp;herbata, kawa.</li>
                 </ul>
               </>,
      },
      seventh: {
        title: null,
        text:  <>
                 <p>Wszystkie produkty zostaną przekazane w najbliższym transporcie. Dziękujemy za pomoc! ❤️</p>
                 <br/>
                 <p>📌 &nbsp;&nbsp;<b>Punkt odbioru: Rzeszów, ul. Przemysłowa 14b</b>🏠</p>
                 <br/>
                 <p>Jeśli nie możesz osobiście dostarczyć rzeczy, zadzwoń, a odbierzemy je od Ciebie!</p>
                 <a href="tel:693380754">☎️ &nbsp;&nbsp;693380754</a>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
  }
}

