import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_56 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Finał akcji MIKOŁAJ 2022',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '12 / 12 / 2022',
    description: 'Mimo, że od tego czasu minęło już kilka dni, te piękne emocje i pozytywna energia nadal nam towarzyszą!',
    slug: '/wydarzenia/final-akcji-mikolaj-2022',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉 </span>
            Finał akcji MIKOŁAJ 2022 już za nami...
            <span role="img" aria-label=""> 🎅 </span>
            <br/>
            Mimo, że od tego czasu minęło już kilka dni, te piękne emocje i pozytywna energia nadal nam
            towarzyszą!
            <br/>
            <br/>
            ➡ 2 grudnia spotkaliśmy się w siedzibie naszego biura wraz z wszystkimi chętnymi osobami podczas pakowania prezentów dla naszych podopiecznych.
            Oprócz wolontariuszy Fundacji Świat Bez Barier i pracowników firmy InteractiveVision wspomogli nas pracownicy Polskich ePłatności, Rzeszów Rockets, Fryzjerzy Rzeszów, Visanto, rodzice oraz dzieciaki z Beniaminek Soccer School Rzeszów, PODIUM oraz prywatne osoby i&nbsp;nasi przyjaciele.
            <br/>
            <br/>
            Przez kilka godzin przygotowywaliśmy spersonalizowane prezenty dla dzieciaków, według ich marzeń zapisanych w listach do Świętego Mikołaja.
            <br/>
            <br/>
            <span role="img" aria-label="">👉 </span>
            Marzenia były przeróżne, ale każde z nich było tak samo ważne. Zrobiliśmy wszystko, aby zrealizować wszystkie punkty z listów!
            <br/>
            <br/>
            <span role="img" aria-label="">🎅 </span>
            Po pracowitych przygotowaniach nastąpił najbardziej wyczekiwany dzień - szczególnie przez naszych podopiecznych, ale i przez nas oraz wszystkie osoby, które wsparły akcję.
            <br/>
            <br/>
            4 grudnia wraz z najważniejszą personą tego dnia - Świętym Mikołajem - i naszymi wolontariuszami wyruszyliśmy w podróż do miejsca, gdzie czekały na nas dzieci.
            <br/>
            <br/>
            <span role="img" aria-label="">🎄 </span>
            Dzięki dziewczynom z Bake my Day i Smaczna Kluska udało się przygotować smakowite poczęstunki dla podopiecznych.
            <br/>
            <br/>
            Przy akompaniamencie kolęd i świątecznych melodii nastąpiło obdarowywanie dzieciaków prezentami przez Świętego Mikołaja!
            <br/>
            <br/>
            <span role="img" aria-label="">➡ </span>
            Radosnym okrzykom nie było końca!
            Emilka - jedna z naszych podopiecznych - stwierdziła, że <strong>“to najlepsze święta w jej życiu”</strong>. To
            było najpiękniejsze podziękowanie dla nas wszystkich za całą pracę, jaką wykonaliśmy przez
            ostatni czas!
            <span role="img" aria-label=""> 🥰 </span>
            <span role="img" aria-label=""> ❄</span>
            <br/>
            <br/>
            To jest właśnie nasz cel - sprawić, aby dzieci uwierzyły w to, że czeka na nich bardzo dużo dobrego, bo są warte każdego wsparcia!
            A my jesteśmy i działamy dla nich!
            <span role="img" aria-label=""> 💜 </span>
            <br/>
            <br/>
            <span role="img" aria-label="">👉 </span>
            Wraz z naszymi sponsorami, partnerami i osobami prywatnymi przygotowaliśmy 24 spersonalizowane prezenty. Każdy z podopiecznych otrzymał również paczkę ze słodyczami i
            kosmetykami.
            Dzieciaki dostały również bilety na mecz futbolu amerykańskiego oraz gadżety od Rzeszów
            Rockets!
            <span role="img" aria-label=""> 💪 </span>
            Nie zabrakło dodatkowych upominków
            <span role="img" aria-label=""> ❤</span>
          </p>,
      },
      second: {
        title: <>Dziękujemy:</>,
        text: <>
          <span role="img" aria-label="">🔸 </span>
          Beniaminek Soccer Schools Rzeszów
          <a href="https://www.facebook.com/profile.php? id=100063748805675" rel="noreferrer" target="_blank"> https://www.facebook.com/profile.php? id=100063748805675</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Pracownikom Polskie Płatności
          <a href="https://www.facebook.com/polskieeplatnosci" rel="noreferrer" target="_blank"> https://www.facebook.com/polskieeplatnosci</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Ekipie i przyjaciołom Przystanek Rzeszów
          <a href="https://www.facebook.com/przystanekrzeszow" rel="noreferrer" target="_blank"> https://www.facebook.com/przystanekrzeszow</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          wool.on.wood
          <a href="https://www.facebook.com/profile.php?id=100063906589372" rel="noreferrer" target="_blank"> https://www.facebook.com/profile.php?id=100063906589372</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          PODIUM
          <a href="https://www.facebook.com/podium.ultrastylzycia" rel="noreferrer" target="_blank"> https://www.facebook.com/podium.ultrastylzycia</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Agencja Interaktywna InteractiveVision
          <a href="https://www.facebook.com/interactivevision" rel="noreferrer" target="_blank"> https://www.facebook.com/interactivevision</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Rzeszów Rockets
          <a href="https://www.facebook.com/rzeszowrockets" rel="noreferrer" target="_blank"> https://www.facebook.com/rzeszowrockets</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Visanto
          <a href="https://www.facebook.com/VisantoPL" rel="noreferrer" target="_blank"> https://www.facebook.com/VisantoPL</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Bake My Day
          <a href="https://www.facebook.com/bakemydaypl" rel="noreferrer" target="_blank"> https://www.facebook.com/bakemydaypl</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Smaczna Kluska
          <a href="https://www.facebook.com/profile.php?id=100063630215402" rel="noreferrer" target="_blank"> https://www.facebook.com/profile.php?id=100063630215402</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Fryzjerzy Rzeszów
          <a href="https://www.facebook.com/profile.php?id=100063630215402" rel="noreferrer" target="_blank"> https://www.facebook.com/profile.php?id=100063630215402</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Siłownia Cool Gym
          <a href="https://www.facebook.com/coolturystagym" rel="noreferrer" target="_blank"> https://www.facebook.com/coolturystagym</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Fight-Fun Michał Bartosik
          <a href="https://www.facebook.com/fightfunmichalbartusik" rel="noreferrer" target="_blank"> https://www.facebook.com/fightfunmichalbartusik</a>
          <br/>
          <span role="img" aria-label="">🔸 </span>
          Zespół Szkół w Wysokiej Strzyżowskiej
          <a href="https://www.facebook.com/profile.php? id=100057273708132" rel="noreferrer" target="_blank"> https://www.facebook.com/profile.php? id=100057273708132</a>
          <br/>
        </>,
      },
      third: {
        title: "",
        text: <>
          <span role="img" aria-label="">!! </span>
          Ogromne podziękowania kierujemy do każdej osoby prywatnej, która dołożyła od siebie cegiełkę do tej akcji. Dziękujemy za poświęcony czas, za wsparcie rzeczowe i pieniężne! <br/>
          Bez Waszego zaangażowania by się nie udało
          <span role="img" aria-label=""> ❤</span>
          <br/>
          Jesteście najlepsi!!
          <span role="img" aria-label=""> ❤</span>
        </>
      }
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_13.childImageSharp.fluid}/>,
          <Image fluid={images.img_14.childImageSharp.fluid}/>,
          <Image fluid={images.img_15.childImageSharp.fluid}/>,
          <Image fluid={images.img_16.childImageSharp.fluid}/>,
          <Image fluid={images.img_17.childImageSharp.fluid}/>,
          <Image fluid={images.img_18.childImageSharp.fluid}/>,
          <Image fluid={images.img_19.childImageSharp.fluid}/>,
          <Image fluid={images.img_20.childImageSharp.fluid}/>,
          <Image fluid={images.img_21.childImageSharp.fluid}/>,
          <Image fluid={images.img_22.childImageSharp.fluid}/>,
          <Image fluid={images.img_23.childImageSharp.fluid}/>,
          <Image fluid={images.img_24.childImageSharp.fluid}/>,
          <Image fluid={images.img_25.childImageSharp.fluid}/>,
          <Image fluid={images.img_26.childImageSharp.fluid}/>,
          <Image fluid={images.img_27.childImageSharp.fluid}/>,
        ],
      },
    },
  };
};

