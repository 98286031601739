import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_43 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "LICYTACJA CHARYTATYWNA - zbiórka funduszy na obozy konne dla podopiecznych",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "12 / 06 / 2022",
    description:      "Wszystkie przedmioty dostaliśmy za darmo od osób z wielkimi serduchami. Wystawiamy je na Allegro, gdzie podczas aukcji można wylicytować super rzeczy za świetną cenę, a przy okazji wesprzeć naszych podopiecznych, ponieważ całą kwotę przeznaczamy na pokrycie kosztów obozów letnich! ",
    slug:             "/wydarzenia/licytacja-charytatywna",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: 'Po pierwsze… zbiórka przedmiotów na licytację! ',
        text:
               <p>
                 Wszystkie przedmioty dostaliśmy za darmo od osób z wielkimi serduchami. Wystawiamy je na Allegro, gdzie podczas aukcji można wylicytować super rzeczy za świetną cenę, a przy okazji wesprzeć naszych podopiecznych, ponieważ całą kwotę przeznaczamy na pokrycie kosztów obozów letnich!
               </p>

      },
      second: {
        title: 'Do tej pory na licytację otrzymaliśmy:',
        text:
               <ul>
                 <li>
                   <span role='img' aria-label=''>➖</span> zestawy klocków lego i zabawki od przyjaciół;
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> obraz od Pana Roberta Bartusika;
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> poduszkę Sówkę od firmy Salon Meblowy Wermar <a href="https://www.facebook.com/sklepmeblowywermar/" rel="noreferrer" target="_blank">(https://www.facebook.com/sklepmeblowywermar/)</a>;
                 </li>
                 <li>
                   zajęcia z udziałem koni prowadzone przez Uśmiech spod kopyt Hipoterapia - Ośrodek Jeździecki Kamieniec <a href="https://www.facebook.com/usmiechspodkopyt/" target="_blank">(https://www.facebook.com/usmiechspodkopyt/)</a>;
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> sweter wykonany przez Gabrielę Mac;
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> puchar od Pawła Saneckiego - Sanecki Rally Sport <a href="https://www.facebook.com/SaneckiRallySport/" rel="noreferrer" target="_blank">(https://www.facebook.com/SaneckiRallySport/)</a>.
                 </li>
               </ul>
      },
      third:  {
        title: '',
        text:
               <p>
                 <strong>Cały czas przyjmujemy przedmioty / aktywności na licytację, więc jeśli chcesz wspomóc podopiecznych Fundacji w ten sposób, to skontaktuj się z nami, np. telefonicznie <a href="tel:693380754">693380754</a> lub mailowo <a href="mailto:biuro@fundacjaswiatbezbarier.pl">biuro@fundacjaswiatbezbarier.pl</a></strong>
               </p>
      },
      fourth: {
        title: '',
        text:  <p>
                 Z całego serca dziękujemy każdemu, kto do tej pory wsparł naszą akcję - zarówno tym, którzy przekazali nam przedmioty, jak i tym, którzy je wylicytowali!
                 <br/>
                 Dzięki Wam, jesteśmy coraz bliżej celu, jakim jest sfinansowanie letnich obozów jeździeckich dla ośmiu naszych podopiecznych.
               </p>
      },
      fifth:  {
        title: '',
        text:  <p>
                 Wszystkie licytowane przedmioty dodajemy na bieżąco na nasz fanpage na Facebooku - <a href="https://www.facebook.com/FundacjaSwiatBezBarier" target="_blank" rel='noopener'>https://www.facebook.com/FundacjaSwiatBezBarier</a>
               </p>
      }
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
    gallery:          {
      first: {
        images: [
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
        ]
      },
    }
  }
}

