import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_13 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Podziękowania dla CID Rzeszów",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "10 / 12 / 2021",
    description:
                      "Podziękowania dla CID Rzeszów",
    slug:             "/wydarzenia/podziekowania-dla-centrum-inwestycji-deweloperskich-rzeszow",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
    lists:            {
      first: {
        title: "",
        items: [
          <>Ogromne podziękowania za zaangażowanie w tegoroczną akcję Mikołaj 2021 zdecydowanie należą się Centrum Inwestycji Deweloperskich Rzeszów <span role="img" aria-label="heart emoji">❤️</span>
            <br/>
            <span role="img" aria-label="finger pointing right emoji">👉</span> To właśnie dzięki tej firmie ze spokojem mogliśmy dopiąć naszą akcję na 110%!
            Bez ich wsparcia by się nie udało. Dlatego jesteśmy niesamowicie wdzięczni, za zaufanie w naszą fundację i ogromną pomoc. <br/>
            <span role="img" aria-label="dash">▬ </span>10500zł - taką sumę przekazało nam Centrum Inwestycji Deweloperskich
          </>,
          <>
            Takie firmy udowadniają, że działając lokalnie i wspierając lokalną społeczność, możemy razem zdziałać jeszcze więcej. Co tu dużo mówić - dziękujemy i po cichu liczymy, że jeszcze nieraz zorganizujemy coś razem!
          </>,
        ],
      },
    },
  }
}
