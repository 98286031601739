import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_21 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Obóz jeździecki dla podopiecznych",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "21 / 02 / 2022",
    description:
                      "To właśnie dzięki Waszemu wsparciu udało nam się go opłacić obóz jeździecki dla naszych podopiecznych!",
    slug:             "/wydarzenia/pierwsze-przejazdzki-i-warsztaty-robienia-pizzy",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 ▪️Wczoraj Emilka (6 lat) i Zuzia (8 lat) zaczęły obóz jeździecki 🐎 <br/>
                 ▪️To właśnie dzięki Waszemu wsparciu udało nam się go opłacić, za co serdecznie dziękujemy❤️ <br/>
                 ▪️Dziewczyny są bardzo zadowolone, codziennie uczą się jazdy konnej oraz dyscypliny związanej z tym bardzo wymagającym sportem. <br/>
                 ▪️Wczoraj była pierwsza przejażdżka i warsztaty robienia pizzy 😋 <br/>
                 ▪️Będziemy Was informować na bieżąco, jak mija im obóz 🙂 <br/>
                 ▪️Zachęcamy do wsparcia: <a href="https://wsparcie.fundacjaswiatbezbarier.pl/">https://wsparcie.fundacjaswiatbezbarier.pl/</a> dzięki temu będziemy mogli realizować więcej takich projektów ❤️
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_4.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_5.childImageSharp.fluid}/>,
            description: "",
          },

        ],
      },
    },
  }
}
