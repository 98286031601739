import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_42 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "WARSZTATY MAKRAMY + SPRZEDAŻ RĘKODZIEŁA PODOPIECZNYCH",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "01 / 06 / 2022",
    description:      "27.05.2022 nasze dzieciaki miały możliwość uczestniczenia w warsztatach rękodzieła, które poprowadziła dla nas Ola - właścicielka wool.on.wood ",
    slug:             "/wydarzenia/makramy",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:
               <p>
                 27.05.2022 nasze dzieciaki miały możliwość uczestniczenia w warsztatach rękodzieła, które
                 poprowadziła dla nas Ola - właścicielka <b>wool.on.wood</b> - zajrzyjcie do niej - strona
                 na <br/>
                 Facebooku: <a
                 href="https://www.facebook.com/woolonwood-100595148745737" rel="noreferrer"
                 target="_blank">https://www.facebook.com/woolonwood-100595148745737</a>, <br/>
                 Instagram:
                 <a href="https://www.instagram.com/wool.on.wood" rel="noreferrer" target="_blank">
                   https://www.instagram.com/wool.on.wood/</a> .
               </p>

      },
      second: {
        title: '',
        text:
               <p>
                 <span role='img' aria-label=''>→</span>
                 Dzięki wspaniałej atmosferze i wskazówkom Oli, udało im się przygotować piękne rzeczy -
                 samodzielnie! <br/>
                 Dziękujemy za cudowny czas i przygotowanie warsztatów. Mało tego, Ola poprowadziła je dla nas
                 całkowicie za darmo, za co bardzo dziękujemy! Było super! Koniecznie do niej zajrzyjcie :) <br/>

               </p>
      },
      third:  {
        title: '',
        text:
               <p>
                 To jeszcze nie koniec! Nasze dzieci włożyły dużo serca w wykonanie rękodzieła na warsztatach,
                 dlatego też wpadły na pomysł, aby spróbować zdobyć fundusze na swoje marzenia, czyli letnie
                 obozy jeździeckie poprzez sprzedaż swoich pięknych makram. Wy, za pośrednictwem Fundacji,
                 możecie im w tym pomóc.
                 <br/>
                 <b>
                   <span role='img' aria-label=''>→</span>
                   Zasady są bardzo proste - kup rękodzieło naszych podopiecznych!
                 </b>
               </p>
      },
      fourth: {
        title: 'Jak to zrobić?',
        text:  <ul>
                 <li>
                   <span role='img' aria-label=''>➖</span> Wpłać dowolną kwotę na konto Fundacji - <a
                   href="https://wsparcie.fundacjaswiatbezbarier.pl/pomoc"
                   target="_blank">https://wsparcie.fundacjaswiatbezbarier.pl/pomoc</a> - i
                   dołóż swoją cegiełkę do pokrycia kosztów obozów letnich.
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> Prześlij nam potwierdzenie wpłaty i adres w wiadomości prywatnej.
                 </li>
                 <li>
                   <span role='img' aria-label=''>➖</span> Wyślemy do Ciebie list z rękodziełem wybranego dziecka.
                 </li>
               </ul>
      },
      fifth:  {
        title: '',
        text:  <p>
                 Zachęcamy do aktywnego wsparcia. Dzieci dały z siebie wszystko, żeby przygotować te rzeczy -
                 wesprzyjmy je razem! Liczymy na Was! <br/>
                 Zobaczcie galerię i wybierzcie coś dla siebie.
               </p>
      }
    },
    videos:           {
      first: {
        title: '',
        src:   'https://www.youtube.com/watch?v=EZqvb7AQeQs',
      }
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
    gallery:          {
      first: {
        images: [
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
        ]
      },
    }
  }
}

