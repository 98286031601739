import {graphql, useStaticQuery} from 'gatsby';

export const useArticleImages = () => {
  return useStaticQuery(graphql`
      query {
          thumb: file(relativePath: { eq: "img/Blog/article_56/thumb.png" }) {
              childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          event: file(relativePath: { eq: "img/Blog/article_56/event.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }

          hero: file(relativePath: { eq: "img/Blog/article_56/hero.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_1: file(relativePath: { eq: "img/Blog/article_56/img_1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }   
          
          img_2: file(relativePath: { eq: "img/Blog/article_56/img_2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_3: file(relativePath: { eq: "img/Blog/article_56/img_3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_4: file(relativePath: { eq: "img/Blog/article_56/img_4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_5: file(relativePath: { eq: "img/Blog/article_56/img_5.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_6: file(relativePath: { eq: "img/Blog/article_56/img_6.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_7: file(relativePath: { eq: "img/Blog/article_56/img_7.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_8: file(relativePath: { eq: "img/Blog/article_56/img_8.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_9: file(relativePath: { eq: "img/Blog/article_56/img_9.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_10: file(relativePath: { eq: "img/Blog/article_56/img_10.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_11: file(relativePath: { eq: "img/Blog/article_56/img_11.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_12: file(relativePath: { eq: "img/Blog/article_56/img_12.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_13: file(relativePath: { eq: "img/Blog/article_56/img_13.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_14: file(relativePath: { eq: "img/Blog/article_56/img_14.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_15: file(relativePath: { eq: "img/Blog/article_56/img_15.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_16: file(relativePath: { eq: "img/Blog/article_56/img_16.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_17: file(relativePath: { eq: "img/Blog/article_56/img_17.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_18: file(relativePath: { eq: "img/Blog/article_56/img_18.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_19: file(relativePath: { eq: "img/Blog/article_56/img_19.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_20: file(relativePath: { eq: "img/Blog/article_56/img_20.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_21: file(relativePath: { eq: "img/Blog/article_56/img_21.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_22: file(relativePath: { eq: "img/Blog/article_56/img_22.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_23: file(relativePath: { eq: "img/Blog/article_56/img_23.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_24: file(relativePath: { eq: "img/Blog/article_56/img_24.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          
          img_25: file(relativePath: { eq: "img/Blog/article_56/img_25.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
          img_26: file(relativePath: { eq: "img/Blog/article_56/img_26.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
          img_27: file(relativePath: { eq: "img/Blog/article_56/img_27.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
};
