import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_31 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Rzeszów dla Ukrainy - oto nasz kolejny projekt!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "30 / 03 / 2022",
    description:      "Działalność lokalna jest naszym priorytetem. Sprawmy, aby nasze społeczeństwo było tylko lepsze i zaangażowane w pomoc drugiemu człowiekowi!",
    slug:             "/wydarzenia/rzeszow-dla-ukrainy-nowy-projekt",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: null,
        text:  <>
                 <p>Działalność lokalna jest naszym priorytetem. Sprawmy, aby nasze społeczeństwo było tylko lepsze i zaangażowane w pomoc drugiemu człowiekowi!</p>
                 <p>Projekt “Rzeszów dla Ukrainy” polega na stworzeniu świetlicy, w której będziemy prowadzić zajęcia z języka polskiego i języka angielskiego zarówno dla dzieci, jak i ich mam zza naszej wschodniej granicy. Chcemy stworzyć miejsce, w którym nie będzie żadnych barier, a nauka będzie odbywała się w kameralnej i przyjaznej atmosferze 🤗</p>
                 <p>Skąd pomysł na ten projekt? 🤔 &nbsp;&nbsp;Wyrównanie szans edukacyjnych dla każdego dziecka to jeden z najważniejszych celów Fundacji Świat Bez Barier. Uważamy, że wiedza otwiera świat, dlatego chcemy stworzyć odpowiednie warunki do rozwoju dla wszystkich dzieci - niezależnie od tego skąd pochodzą.</p>
               </>
      },
      second: {
        title: <>🔵 Zajęcia będą odbywać się w:</>,
        text:  <>
                 <ul>
                   <li><b>➖ &nbsp;&nbsp;poniedziałek:</b></li>
                   <li>➖ &nbsp;&nbsp;10:15 - 11:00</li>
                   <li>➖ &nbsp;&nbsp;11:15 - 12:00</li>
                   <li>➖ &nbsp;&nbsp;12:15 - 13:00</li>
                 </ul>
                 <br/>
                 <ul>
                   <li><b>➖ &nbsp;&nbsp;piątek:</b></li>
                   <li>➖ &nbsp;&nbsp;9:45 - 10:30</li>
                   <li>➖ &nbsp;&nbsp;10:45 - 11:30</li>
                   <li>➖ &nbsp;&nbsp;11:45 - 12:30</li>
                 </ul>
               </>
      },
      third:  {
        title: <>🟡 Gdzie?</>,
        text:  <>
                 <p>W Badaboom na ul.Architektów 14A w Rzeszowie 🏠</p>
               </>

      },
      fourth: {
        title: <>🔵 Jak możecie pomóc?</>,
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;przekażcie nam książeczki edukacyjne lub artykuły szkolne</li>
                   <li>➖ &nbsp;&nbsp;wpłacajcie pieniądze na konto naszej fundacji, a my wykorzystamy je na cele pokrycia kosztów tego projektu</li>
                 </ul>
               </>
      },
      fifth:  {
        title: <>🟡 Chcesz osobiście zaangażować się w projekt?</>,
        text:  <>
                 <p>Jeśli cechuje Cię kreatywność, wiesz, jak przekazać wiedzę i masz podejście do dzieci, dołącz do nas! Szukamy osób, które chciałyby poprowadzić nie tylko zajęcia z języka polskiego lub angielskiego, ale również inne ciekawe zajęcia, które mogłyby zainteresować dzieci 😊</p>
                 <p><b>Liczymy na Was! 💙💛</b></p>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
    videos:           {
      first: {
        title: '',
        src:   'https://www.youtube.com/embed/NQIyREaqZ3o',
      }
    },
  }
}

