import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_10 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Rozpoczęcie Akcji Mikołaj 2021",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "19 / 11 / 2021",
    description:
                      "Do finału akcji mikołajki 2021 zostało zaledwie 17 dni. Każdy, nawet najdrobniejszy datek ma znaczenie.",
    slug:             "/wydarzenia/rozpoczecie-akcji-mikolaj-2021",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:  <>Pracownia Świętego Mikołaja działa na pełnych obrotach ❤️ Cała armia elfów pracuje, aby przygotować 30 wyjątkowych prezentów dla naszych podopiecznych 🎁
          <br/>
          Do finału akcji zostało zaledwie 1️⃣ 7️⃣ dni ❗ Każdy, nawet najdrobniejszy datek ma znaczenie. Dlatego zachęcamy do wsparcia naszej akcji 🥰
               </>
      },
      second:  {
        title: '',
        text:  'Co tu dużo mówić - radość z pomagania jest niesamowita  🥰 A razem możemy zdziałać jeszcze więcej.',
      }
    },
    videos:           {
      first: {
        title: '',
        src:   'https://www.youtube.com/embed/bbMBaRNeiiM',
      }
    },
    lists:            {
      first: {
        title: "W tamtym roku udało się zebrać środki na:",
        items: [
          '28 wielkich prezentów wypełnionych tym, czego pragnęli nasi podopieczni',
          '10 tabletów',
          '2 laptopy',
          'Kurs prawa jazdy',
          'Obozy konne i dodatkowe lekcje',
          'Elementy wyposażenia ośrodka opiekuńczo-wychowawczego',
        ],
      },
    },
  }
}
