import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_62 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Nasze fundacyjne projekty',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '07 / 03 / 2023',
    description: 'Inwestujemy w naszych podopiecznych - dzieci przebywające w placówkach opiekuńczych , z trudną sytuacją materialną. Chcemy aby miały równy start w dorosłość .',
    slug: '/wydarzenia/nasze-fundacyjne-projekty',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: '',
        text:
          <>
            <p>
              Inwestujemy w naszych podopiecznych - dzieci przebywające w placówkach opiekuńczych , z trudną sytuacją materialną. Chcemy aby miały równy start w dorosłość.
              <br/> <br/>
            </p>

            <p style={{ textAlign: "center", fontWeight: 700, marginBottom: '12px', fontSize: '20px' }}>AKTYWNE PIĄTKI</p>
            <p>
              <span role="img" aria-label=""> 🥰</span> Piątki są nasze! Każdy weekend rozpoczynamy z naszymi podopiecznymi. Organizujemy wspólne wyjścia na różne atrakcje - zazwyczaj jest to ścianka wspinaczkowa lub park trampolin. Poświęcamy czas naszym podopiecznym i opłacamy wszystkie koszta związane z naszym cotygodniowym wyjściem.
              <br/>
              → Koszt jednego wyjścia to około 300 zł.
            </p>
            <br/> <br/>

            <p style={{ textAlign: "center", fontWeight: 700, marginBottom: '12px', fontSize: '20px' }}>EDUKACJA BEZ BARIER</p>
            <p>
              <span role="img" aria-label="">👉 </span> Każdego miesiąca opłacamy wydatki związane z dodatkowymi lekcjami i korepetycjami, to koszt ponad <strong>3 000</strong> złotych miesięcznie. Chcemy, aby każdy z naszych podopiecznych mógł wyrównać swoje szanse.
            </p>
            <br/> <br/>
            <p style={{ textAlign: "center", fontWeight: 700, marginBottom: '12px', fontSize: '20px' }}>ROZWÓJ BEZ BARIER</p>
            <p>
              <span role="img" aria-label="">👉 </span> Starszym podopiecznym opłacamy kursy zawodowe, kursy na prawo jazdy - wszystko to co jest w stanie im pomóc podnieść ich kompetencje i szanse na zatrudnienie
              <br/>
              → Koszt jednego kursu to koszt ponad <strong>2 000</strong> złotych
            </p>
            <br/> <br/>
            <p>
              <strong>WESPRZYJ NASZE DZIAŁANIA NA:</strong>
              <br/>

              <a href="https://zrzutka.pl/scunbr" rel="noopener" target="_blank">
                https://zrzutka.pl/scunbr
              </a>
              <br/>
              <a href="https://wsparcie.fundacjaswiatbezbarier.pl/" rel="noopener" target="_blank">
                https://wsparcie.fundacjaswiatbezbarier.pl/
              </a>
            </p>
          </>
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
  };
};

