import React from "react"
import Image from "gatsby-image"
import { useLocation } from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import { StickyLink } from "sections/News/NewsLayout/styles.js"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import { useNewYearImages } from "./NewYearImages";

export const News_2 = () => {
  const images = useNewYearImages();
  const location = useLocation()
  return {
    title: "Nowy rok to nowe perspektywy.",
    hero_image: <Image fluid={images.hero.childImageSharp.fluid} />,
    detail_image: <Image fluid={images.thumb.childImageSharp.fluid} />,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid} />,
    date: "02 / 01 / 2020",
    description:
      "Nowy Rok to nowe perspektywy. Styczeń jest dla nas czasem planowania, czasem ustalania planu działania na ten rok.",
    slug: "/wydarzenia/nowy-rok",
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon} />
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon} />
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon} />
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: "Chcemy działać, chcemy pomagać, chcemy motywować",
        text: (
          <>
            Nowy Rok to nowe perspektywy. Styczeń jest dla nas czasem planowania i ustalania planu działania. Obecnie jesteśmy pewni jednego - chcemy DZIAŁAĆ, chcemy POMAGAĆ, chcemy MOTYWOWAĆ. Aby zacząć działać, potrzebujemy Was, Waszego wsparcia i zaufania. Mamy nową stronę internetową, za pośrednictwem której możecie dokonać wpłaty (jednorazowej lub cyklicznej) na rzecz naszej fundacji. 100% Waszej wpłaty przekażemy na wydatki związane z naszymi podopiecznymi. Aktualnie planujemy pokryć koszty potrzebne na kurs prawa jazdy, kurs boksu, sommelierstwa, akrobatyki, tańca, śpiewu i gotowania. To tak na dobry początek, a potrzeby rosną i rosną … Dlatego z całego serduszka liczymy na Wasze wsparcie. Do usłyszenia wkrótce! :)
          </>
        ),
      },
    },
  }
}
