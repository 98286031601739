import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_25 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Szukamy wolontariuszy!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "15 / 03 / 2022",
    description:
                      "W związku ze zwiększonymi potrzebami i chęcią niesienia jeszcze większej dawki pomocy niż dotychczas szukamy ludzi o dobrym serduszku, którzy chcieliby do nas dołączyć jako wolontariusze",
    slug:             "/wydarzenia/szukamy-wolontariuszy",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: null,
        text:  <>
                 <p>W związku ze zwiększonymi potrzebami i chęcią niesienia jeszcze większej dawki pomocy niż dotychczas szukamy ludzi o dobrym serduszku, którzy chcieliby do nas dołączyć jako wolontariusze</p>
               </>
      },
      second: {
        title: "Jeśli:",
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;przepełnia Cię energia,</li>
                   <li>➖ &nbsp;&nbsp;pomaganie sprawia Ci radość,</li>
                   <li>➖ &nbsp;&nbsp;masz dobre podejście do dzieci,</li>
                   <li>➖ &nbsp;&nbsp;lubisz planować i organizować,</li>
                   <li>➖ &nbsp;&nbsp;masz w sobie dużo empatii</li>
                   <li>➖ &nbsp;&nbsp;to wolontariat w naszej Fundacji jest dla Ciebie!</li>
                 </ul>
                 <br/>
                 <p>...to wolontariat w naszej Fundacji jest dla Ciebie! 🥰</p>
               </>
      },
      third:  {
        title: "Jakie będą Twoje zadania?",
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;zabieranie dzieci na różne wyjścia opłacane przez Fundację,</li>
                   <li>➖ &nbsp;&nbsp;spędzanie czasu z naszymi podopiecznymi,</li>
                   <li>➖ &nbsp;&nbsp;pomaganie przy realizacji projektów Fundacji,</li>
                   <li>➖ &nbsp;&nbsp;pomaganie przy magazynowaniu i segregowaniu darów w ramach projektu pomocy dla Ukrainy.</li>
                 </ul>
                 <br/>
                 <p>👉&nbsp;&nbsp;&nbsp;&nbsp;Zgłoś się do nas:</p>
                 <br/>
                 <p>✉️&nbsp;&nbsp;&nbsp;&nbsp;biuro@fundacjaswiatbezbarier.pl</p>
                 <br/>
               </>
      }
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
  }
}
