import {graphql, useStaticQuery} from "gatsby"

export const useArticleImages = () => {
    return useStaticQuery(graphql`
        query {
            thumb: file(relativePath: { eq: "img/Blog/article_42/thumb.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            event: file(relativePath: { eq: "img/Blog/article_42/event.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_1: file(relativePath: { eq: "img/Blog/article_42/img_1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_2: file(relativePath: { eq: "img/Blog/article_42/img_2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_3: file(relativePath: { eq: "img/Blog/article_42/img_3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_4: file(relativePath: { eq: "img/Blog/article_42/img_4.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_5: file(relativePath: { eq: "img/Blog/article_42/img_5.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_6: file(relativePath: { eq: "img/Blog/article_42/img_6.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_7: file(relativePath: { eq: "img/Blog/article_42/img_7.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_8: file(relativePath: { eq: "img/Blog/article_42/img_8.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_9: file(relativePath: { eq: "img/Blog/article_42/img_9.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_10: file(relativePath: { eq: "img/Blog/article_42/img_10.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_11: file(relativePath: { eq: "img/Blog/article_42/img_11.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }

            img_12: file(relativePath: { eq: "img/Blog/article_42/img_12.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)
}