import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_37 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Zbiórka dla ukraińskich sierocińców",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "25 / 04 / 2022",
        description: "Tak jak informowaliśmy w ostatnich postach, chcemy objąć stałą pomocą kilka sierocińców na Ukrainie. Są to miejsca, do których wysłaliśmy już poprzedni transport.",
        slug: "/wydarzenia/zbiorka-dla-ukrainskich-sierocincow",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span> Tak jak informowaliśmy w ostatnich postach, chcemy objąć
                        stałą pomocą kilka sierocińców na Ukrainie. Są to miejsca, do których wysłaliśmy już poprzedni
                        transport <span role='img' aria-label=''> 🚚</span>
                    </p>
                </>
            },
            second: {
                title: '',
                text: <p>
                    <span role='img' aria-label=''>→</span> Dzięki Waszemu wsparciu była to naprawdę duża pomoc.
                    Przekazaliśmy rzeczy, które sprawiły wiele dobrego <span role='img' aria-label=''>❤</span> Jednak
                    potrzeby wciąż są duże, a ilość dzieci w sierocińcach rośnie… <br/> <br/>
                    <span role='img' aria-label=''>→</span> Dzieci w podziękowaniu przygotowały piękne prace
                    artystyczne, które możecie zobaczyć na dołączonych zdjęciach. Jesteśmy nimi bardzo wzruszeni i nie
                    przestajemy działać! <span role='img' aria-label=''>🥰</span> <br/> <br/>
                    Ciągle prowadzimy zbiórkę rzeczy z listy poniżej, które dostarczymy bezpośrednio do sierocińców.
                </p>
            },
            third: {
                title: <><span role='img' aria-label=''>🟣 </span>Jak możesz pomóc? </>,
                text: <>
                    <p>
                        <span role='img' aria-label=''>1️⃣</span> Zakup rzeczy z podanej listy i dostarcz je do nas
                        (siedziba firmy InteractiveVision ul.Przemysłowa 14b, Rzeszów) lub zadzwoń do nas (693380754), a
                        my je odbierzemy <span role='img' aria-label=''>😊</span> <br/>
                        <span role='img' aria-label=''>2️⃣</span> Wpłać dowolną kwotę na konto Fundacji, a my kupimy
                        rzeczy z listy.
                        Link do zbiórki: <a
                        href="https://wsparcie.fundacjaswiatbezbarier.pl/?utm_source=FACEBOOK&utm_medium=IV%20-%20WK&utm_campaign=POST%20FB%20IG&fbclid=IwAR3sAc4fWXAvFbLUv4JV0axPXG8WjJjQUymbopWRIcXXHrW1lXIgj0hxicw"
                        target='_blank'>
                        fundacjaswiatbezbarier-wsparcie</a><span role='img' aria-label=''> ❤</span>️
                    </p>
                </>

            },
            fourth: {
                title: <><span role='img' aria-label=''>🟣 </span> Lista rzeczy, których stale przyjmujemy:</>,
                text:
                    <ul>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Żywność długoterminowa (ryż, kasza,
                            konserwy, owsianka, cukier, itp.)
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Słodycze dla dzieci</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Soki i żywność dla niemowląt</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Ołówki, farby, książeczki do kolorowania
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Pieluchy dla dzieci 2-3-4-5-6</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Bielizna dziecięca</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Leki bez recepty dla dzieci i dla
                            dorosłych
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Leki na receptę, antybiotyki, leki na
                            nadciśnienie itp.
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Nutridrink dla dzieci wymagających
                            specjalnego karmienia (Nutridrink Compact Fibre)
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Pampersy dla dzieci i dla dorosłych</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Chemia gospodarcza</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Kosmetyki</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Ręczniki</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Pościel</li>
                    </ul>
            },
            fifth: {
                title: "",
                text: <p>
                    <span role='img' aria-label=''> ▪</span> Z góry dziękujemy za nieustające wsparcie! <span role='img'
                                                                                                              aria-label=''>❤</span>️
                </p>
            },
        },
        gallery: [
            <Image fluid={images.img_1.childImageSharp.fluid}/>,
            <Image fluid={images.img_2.childImageSharp.fluid}/>,
            <Image fluid={images.img_3.childImageSharp.fluid}/>,
            <Image fluid={images.img_4.childImageSharp.fluid}/>,
            <Image fluid={images.img_5.childImageSharp.fluid}/>,
            <Image fluid={images.img_6.childImageSharp.fluid}/>,
        ],
        images:           {
            first: {
                show:  true,
                full:  true,
                image: [
                    {
                        image:       <Image fluid={images.img_7.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            },
        },
    }
}

