import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_47 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Niezły lumpeks",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "02 / 08 / 2022",
    description:      "Przyszedł czas na krótkie podsumowanie wydarzenia Niezły lumpeks - second hand w plenerze LATO",
    slug:             "/wydarzenia/niezly-lumpeks",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️ Przyszedł czas na krótkie podsumowanie wydarzenia Niezły lumpeks - second hand w plenerze LATO <span role="img" aria-label=''>❤️</span>️ <br/> <br/>
                 <span role="img" aria-label=''>🟣️ </span> Chcielibyśmy serdecznie podziękować osobom, które przekazały rzeczy na nasze stoisko <span role="img" aria-label=''>🤗</span>
               </p>
      },
      second: {
        title: '',
        text:
               <ul>
                 <li><span role="img" aria-label=''>➖️</span> zespół Agencja Interaktywna InteractiveVision - ubrania, buty, biżuteria i dodatki,</li>
                 <li><span role="img" aria-label=''>➖️</span> Kolorowy Kołowrotek - piękna listonoszka własnoręcznie wykonana ze sznurka poliestrowego,</li>
                 <li><span role="img" aria-label=''>➖️</span> Fornirovane - fornirowane zakładki do książek,</li>
                 <li><span role="img" aria-label=''>➖️</span> Gabriela Mac - torby własnoręcznie wykonane z włóczki akrylowej oraz kolczyki z modeliny.</li>
               </ul>
      },
      third:  {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>→️</span> Szczególne podziękowania należą się Justynie Kalandyk z Rzeszowskiego Lumpeksu, która dała nam możliwość udziału w tym wspaniałym wydarzeniu i cały czas nas wspierała <span role="img" aria-label=''>❤️</span>
               </p>
      },
      fourth: {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>🟣</span> Dzięki Waszej pomocy zebraliśmy łącznie 790 zł <span role="img" aria-label=''>🥰</span>️ Cała kwota zostanie przeznaczona na letnie obozy jeździeckie dla naszych podopiecznych <span role="img" aria-label=''>😊️</span> Dziękujemy! <span role="img" aria-label=''>❤️</span>️ <br/> <br/>
                 <span role="img" aria-label=''>▪</span>️ Rzeczy, których nie udało nam się sprzedać w niedzielę, zostaną wystawione na licytacje <span role="img" aria-label=''>😊</span> Więcej informacji już wkrótce! <span role="img" aria-label=''>❤️</span>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },
  }
}

