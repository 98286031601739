import {Sticky, StickyContainer} from "react-sticky"
import {SectionWrapper} from "shared/Styles/Wrappers"
import styled from "styled-components"

export const Container = styled.div`
    padding-bottom: 96px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-bottom: 64px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-bottom: 48px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        padding-bottom: 48px;
    }
`
export const Wrapper = styled(SectionWrapper)``

export const HeroImage = styled.div`
    max-width: 1410px;
    width: 100%;
    padding: 0 24px;
    margin: 64px auto 36px auto;
    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin: 24px auto;
    }
`

export const HeaderWrapper = styled.div`
    text-align: center;
    width: 100%;
    margin-bottom: 48px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin-bottom: 24px;
    }
`
export const Title = styled.h1``

export const Date = styled.div`
    font-size: 18px;
    font-weight: 700;
    margin-top: 12px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
        margin-top: 8px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 14px;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        flex-direction: column-reverse;
    }
`

export const StickyColumn = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 140px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 100%;
    }
`

export const StickyBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        padding-bottom: 0px;
        margin-top: 0 !important;
    }
`

export const StyledStickyContainer = styled(StickyContainer)`
    height: 100%;
    width: 100%;
`

export const StyledSticky = styled(Sticky)`
    height: 100%;
`

export const StickyTitle = styled.span`
    font-size: 14px;
    font-weight: 700;
    display: block;
`

export const StickyLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        flex-direction: row;
    }
`

export const StickyLink = styled.a`
    width: 26px;
    height: 26px;
    background-color: #000;
    background-image: url(${({icon}) => icon});
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    border-radius: 100%;
    margin: 4px 0;
    transition: 0.2s background-color ease;
    cursor: pointer;

    &:hover {
        background-color: ${({theme}) => theme.colors.accentColor};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin: 0 6px;
        width: 34px;
        height: 34px;
    }
`

export const TextColumn = styled.div`
    max-width: 1000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`

export const ButtonWrapper = styled.div``
