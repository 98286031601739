import React from "react"
import Image from "gatsby-image"
import { useLocation } from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import { StickyLink } from "sections/News/NewsLayout/styles.js"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import { useMikolajkiImages } from "./MikolajkiImages"

export const News_4 = () => {
  const images = useMikolajkiImages();
  const location = useLocation();

  return {
    title: "Finał pierwszej akcji - Mikołaj 2020.",
    hero_image: <Image fluid={images.hero.childImageSharp.fluid} />,
    detail_image: <Image fluid={images.thumb.childImageSharp.fluid} />,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid} />,
    date: "06 / 12 / 2020",
    description:
      "Dziś odbył się finał naszej pierwszej akcji, którą zorganizowaliśmy już jako Fundacja - Mikołaj 2020. I tak jak w zeszłym roku udało nam się sukcesywnie skompletować wszystkie prezenty, które były spełnieniem indywidualnych marzeń naszych dzieciaków.",
    slug: "/wydarzenia/mikolajki-2020",
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon} />
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon} />
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon} />
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: "Mikołaj 2020",
        text: (
          <>
            Dziś odbył się finał naszej pierwszej akcji, którą zorganizowaliśmy
            już jako Fundacja - Mikołaj 2020. I tak jak w zeszłym roku udało nam
            się sukcesywnie skompletować wszystkie prezenty, które były
            spełnieniem indywidualnych marzeń naszych dzieciaków. Dzięki
            wsparciu przyjaciół, osób indywidualnych i wielkiemu zaangażowaniu
            różnych firm i naszych partnerów udało nam się osiągnąć
            najważniejsze - WIELKIE uśmiechy na twarzach dzieci. To jest właśnie
            sukces, na który pracujemy. Oprócz prezentów dzieci dostały
            zaproszenia na przeróżne kursy: wizażu, akrobatyki, zajęcia na
            siłowni. Jedna z naszych utalentowanych podopiecznych - Wiktoria -
            będzie miała również szansę spełnić swoje największe marzenie i
            zostanie uczennicą Centrum Sztuk Wokalnych. O sukcesach, progresie i
            przebiegu kursów naszych dzieciaków usłyszycie w kolejnych postach.
            Niestety miniony rok, jak również i początek tego aktualnego, nie
            należy do najlżejszych. Realizacja kursów i szkoleń na chwilę obecną
            jest wstrzymana. Ale pamiętajmy, że ta sytuacja nie potrwa wiecznie!
            W końcu przyjdzie moment, w którym wszystko znów wróci do
            normalności. A gdy nadejdzie ten moment, wystartujemy ze zdwojoną
            siłą i energią!
          </>
        ),
      },
    },
    gallery: [
      <Image fluid={images.img_1.childImageSharp.fluid} />,
      <Image fluid={images.img_2.childImageSharp.fluid} />,
      <Image fluid={images.img_3.childImageSharp.fluid} />,
      <Image fluid={images.img_4.childImageSharp.fluid} />,
    ],
  }
}
