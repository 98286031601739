import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_60 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Licytacje charytatywne',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '07 / 03 / 2023',
    description: 'Jeśli chcesz wesprzeć nasze działania, weź udział w licytacji charytatywnej lub przekaż nam przedmiot/y, które będziemy mogli na nich wystawić',
    slug: '/wydarzenia/licytacje-charytatywne',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: '',
        text:
          <>
            <p>
              <span role="img" aria-label="">👉 </span> Jeśli chcesz wesprzeć nasze działania, weź udział w licytacji charytatywnej lub przekaż nam przedmiot/y, które będziemy mogli na nich wystawić.
            </p>
            <br/>
            <p>Do tej pory otrzymaliśmy już między innymi:</p>

            <ul>
              <li><span role="img" aria-label=""> ● </span> różnego rodzaju zestawy klocków LEGO,</li>
              <li><span role="img" aria-label=""> ● </span> puzzle,</li>
              <li><span role="img" aria-label=""> ● </span> rękodzieło,</li>
              <li><span role="img" aria-label=""> ● </span> puchar Pawła Saneckiego,</li>
              <li><span role="img" aria-label=""> ● </span> książki,</li>
              <li><span role="img" aria-label=""> ● </span> obraz,</li>
              <li><span role="img" aria-label=""> ● </span> karnety na siłownię,</li>
              <li><span role="img" aria-label=""> ● </span> różnego rodzaju usługi: sesja fotograficzna, catering z przekąskami itp.</li>
            </ul>
          </>
      },
      second: {
        title: "",
        text: <>
          <p>
            <span role="img" aria-label="">💜 </span>
            Wszystkie przedmioty dostaliśmy za darmo od osób z wielkimi serduchami.
            → Wystawiamy je na Allegro, gdzie podczas aukcji można wylicytować super rzeczy za świetną cenę, a przy okazji wesprzeć naszych podopiecznych. Całą kwotę przeznaczamy na wsparcie naszych fundacyjnych projektów: EDUKACJA BEZ BARIER, ROZWÓJ BEZ BARIER I AKTYWNE PIĄTKI.

            <br/> <br/>
            <span style={{ color: "#D83990" }}>
              Jak widać, na naszych licytacjach jest bardzo różnorodnie, a wszystkie aktualne aukcje znajdziesz pod linkiem:
            </span>
            <br/>
            <a href="https://bit.ly/wszystkie-licytacje" rel="noreferrer" target="_blank">https://bit.ly/wszystkie-licytacje</a>

            <br/> <br/>
            <strong>
              Cały czas przyjmujemy przedmioty / usługi na licytacje, więc jeśli chcesz wspomóc podopiecznych Fundacji w ten sposób, to skontaktuj się z nami, np. telefonicznie <a href="tel:693380754">693380754</a> lub mailowo <a href="mailto:magda@fundacjaswiatbezbarier.pl">magda@fundacjaswiatbezbarier.pl</a>
            </strong>

            <br/> <br/>
            Z całego serca dziękujemy wszystkim, którzy do tej pory wsparli naszą akcję - zarówno tym, którzy przekazali nam przedmioty, jak i tym, którzy je wylicytowali! <span role="img" aria-label=""> 🥰</span>
          </p>
        </>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
  };
};

