import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_35 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Nowy grafik zajęć",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "20 / 04 / 2022",
        description: "Ostatnio dołączyli do nas nowi wolontariusze! Z tego powodu wprowadzamy nowy grafik zajęć z języka polskiego i angielskiego. Zajęcia te będą odbywać się rano i popołudniu.",
        slug: "/wydarzenia/nowy-grafik-zajec",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span> Ostatnio dołączyli do nas nowi wolontariusze! <span
                        role='img' aria-label=''>💜</span> Z tego powodu wprowadzamy nowy grafik zajęć z języka
                        polskiego i angielskiego. Zajęcia te będą odbywać się rano i popołudniu <span role='img'
                                                                                                      aria-label=''>😊</span>
                    </p>
                </>
            },
            second: {
                title: '',
                text: <>
                    <ul>
                        <li><span role='img' aria-label=''>🟣</span> <b>Poniedziałek</b></li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Magda) - 10:15 - 11:00
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język angielski(Magda) - 11:15 - 12:00
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Magda) - 12:15 - 13:00
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li><b><span role='img' aria-label=''>🟡</span> &nbsp;&nbsp;Wtorek:</b></li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Ania) - 17:30 - 18:15</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Ania) - 18:30 - 19:15</li>
                    </ul>
                    <br/>
                    <ul>
                        <li><b><span role='img' aria-label=''>🟣</span> &nbsp;&nbsp;Środa:</b></li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Agnieszka) - 18:00 - 18:45
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li><b><span role='img' aria-label=''>🟡</span> &nbsp;&nbsp;Czwartek:</b></li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski - 18:00 - 18:45</li>
                    </ul>
                    <br/>
                    <ul>
                        <li><b><span role='img' aria-label=''>🟣</span> &nbsp;&nbsp;Piątek:</b></li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Magda) - 9:45 - 10:30</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język angielski (Magda) - 10:45 - 11:30
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Magda) - 11:45 - 12:30
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Agnieszka) - 15:00 - 15:45
                        </li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język polski (Ania) - 17:15 - 18:00</li>
                        <li><span role='img' aria-label=''>➖</span> &nbsp;&nbsp;Język angielski (Ania) - 18:15 - 19:00
                        </li>
                    </ul>
                </>
            },
            third: {
                title: '',
                text: <>
                    <p>
                        <span role='img' aria-label=''> ▪</span> Zajęcia odbywają się w Bada Boom na ul.Architektów 14A
                        w Rzeszowie <span role='img' aria-label=''> 🏠</span> Zapraszamy! <span role='img'
                                                                                                aria-label=''>😊</span>
                    </p>
                </>

            },
            fourth: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>✉️</span> <a
                        href="mailto: biuro@fundacjaswiatbezbarier.pl">biuro@fundacjaswiatbezbarier.pl</a> <br/>
                        <span role='img' aria-label=''>☎️</span> <a href="tel:693380754 ">693380754</a> <br/>
                    </p>
            },
        },
        images:           {
            first: {
                show:  true,
                full:  true,
                image: [
                    {
                        image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            },
        },
        gallery: [
            <Image fluid={images.img_2.childImageSharp.fluid} />,
            <Image fluid={images.img_3.childImageSharp.fluid} />,
            <Image fluid={images.img_4.childImageSharp.fluid} />,
            <Image fluid={images.img_5.childImageSharp.fluid} />,
            <Image fluid={images.img_6.childImageSharp.fluid} />,
        ],
    }
}

