import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_7 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Nasze podopieczne na szkoleniu",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "31 / 03 / 2021",
    description:
                      "Dzięki uprzejmości Makijaż/Charakteryzacja/Fotografia by Maja Gajda Rzeszów nasze podopieczne spędziły wspaniały dzień podczas pierwszego dnia ze szkolenia z wizażu.",
    slug:             "/wydarzenia/szkolenie-z-wizazu",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>Dzięki uprzejmości <a href="https://www.facebook.com/krzywyschemat8/" target="_blank" rel="noopener ">Makijaż/Charakteryzacja/Fotografia by Maja Gajda Rzeszów</a> nasze podopieczne spędziły wspaniały dzień podczas pierwszego dnia ze szkolenia z wizażu <span role="img" aria-label="palette emoji">🎨</span>
          <br/>
          Maja zaplanowała dla dziewczyn intensywny dzień pełen wrażeń. Dziękujemy serdecznie za zaangażowanie i super zabawę <span role="img" aria-label="smiling face emoji">😊</span>
               </>,
      },
    },

    lists:  {
      first: {
        title: "Jak?",
        items: [
          'Podaruj nam książkę, która jest w dobrym stanie',
          'Kup nową książke z zasugerowanej przez nas listy. Z wielką uwagą staraliśmy sie dobrać tytuły książek, jakie chcielibyśmy podarowac naszym podpiecznym. Bardzo zależy nam na ich zdobyciu, więc będziemy niezmiernie wdzięczni za zakupienie chodź jednego tytułu',
        ],
      },
    },
    images: {
      first: {
        show:  false,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
