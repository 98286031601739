import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_39 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Wywiad z Vasylem",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "06 / 05 / 2022",
        description: "Przeprowadziliśmy wywiad z Vasylem - osobą, która regularnie jeździ z transportami humanitarnymi na Ukrainę, głównie do sierocińców i szpitali",
        slug: "/wydarzenia/wywiad-z-vasylem",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>▪</span>️ Przeprowadziliśmy wywiad z Vasylem - osobą, która
                        regularnie jeździ z transportami
                        humanitarnymi na Ukrainę, głównie do sierocińców i szpitali
                        <span role='img' aria-label=''>🇺🇦</span>
                    </p>

            },
            second: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>→</span>
                        W wywiadzie opowiedział nam o tym, jak obecnie wygląda sytuacja w tym kraju, a w szczególności
                        w placówkach dla dzieci. Zachęcamy do obejrzenia.
                    </p>
            },
            third: {
                title: <></>,
                text:
                    <p>
                        <span role='img' aria-label=''>🟣</span> Dziękujemy Marek Maśniak oraz Polskie Radio Rzeszów za
                        materiały, które zostały użyte w filmie
                        <span role='img' aria-label=''>💙</span>
                        <span role='img' aria-label=''>💛</span>
                    </p>
            },
        },
        videos:           {
            first: {
                title: '',
                src:   'https://www.youtube.com/embed/EZqvb7AQeQs',
            }
        },
        images: {
           first: {
               show:  true,
               full:  true,
               image: [
                   {
                       image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
                       description: "",
                   }
               ],
           }
        }
    }
}

