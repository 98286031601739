import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_58 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Kolędowanie 2022',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '05 / 01 / 2023',
    description: 'Grudzień zakończyliśmy wyjątkowym wydarzeniem',
    slug: '/wydarzenia/koledowanie-2022',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉 </span>
            Grudzień zakończyliśmy wyjątkowym wydarzeniem
            <span role="img" aria-label=""> 🎄 </span>
            <br/>
            22 grudnia przy przepięknie udekorowanym Rynku odbyło się Kolędowanie przy
            Rzeszowskim Fortepianie, które mieliśmy przyjemność organizować&nbsp;z:
            <br/>
            <ul>
              <li>
                <span role="img" aria-label="">🔸 </span>
                Wiceprezydent Rzeszowa Krystyną Stachowską
                <br/>
                <a href="https://www.facebook.com/profile.php?id=100076017763699" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100076017763699</a>
              </li>
              <li>
                <span role="img" aria-label="">🔸 </span>
                Rzeszów - Stolica Innowacji
                <br/>
                <a href="https://www.facebook.com/Rzeszow.stolica.innowacji" target="_blank" rel="noreferrer">https://www.facebook.com/Rzeszow.stolica.innowacji</a>
              </li>
              <li>
                <span role="img" aria-label="">🔸 </span>
                Estrada Rzeszowska
                <br/>
                <a href="https://www.facebook.com/EstradaRzeszowska" target="_blank" rel="noreferrer">https://www.facebook.com/EstradaRzeszowska</a>
              </li>
              <li>
                <span role="img" aria-label="">🔸 </span>
                Kultura w Rzeszowie
                <br/>
                <a href="https://www.facebook.com/kulturawrzeszowie" target="_blank" rel="noreferrer">https://www.facebook.com/kulturawrzeszowie</a>
              </li>
            </ul>
          </p>
      },
      second: {
        title: <>Podczas tego wydarzenia mieliśmy przyjemność posłuchać wykonań dzieci i młodzieży
          ze:</>,
        text: <>
          <ul>
            <li>
              <span role="img" aria-label="">➡ </span>
              <strong>Szkoły muzycznej Music Team:</strong>
              <br/>
              <a href="https://www.facebook.com/szkolamusicteam" target="_blank" rel="noreferrer">https://www.facebook.com/szkolamusicteam</a>
              <br/>
              <p>
                <strong>O Szkole:</strong> <br/>
                Centrum edukacji muzycznej MusicTeam mieści się w Rzeszowie przy ulicy Ofiar
                Katynia 6a. W szkole tej prowadzone są zajęcia dla dzieci, młodzieży i dorosłych. Są to
                głównie zajęcia instrumentalne, można nauczyć się grać na pianinie, perkusji, gitarze
                akustycznej i elektrycznej, ukulele. Sa także prowadzone zajęcia dla najmłodszych - w
                grupach MusicTeam Junior - są to zajęcia umuzykalniające dla dzieci w wieku 4-6 lat.
                <ol>
                  <li>
                    1. Skrzypi wóz - wykonanie Marika Róg
                  </li>
                  <li>
                    2. Dzisiaj w Betlejem - wykonanie Tosia Rojek
                  </li>
                  <li>
                    3. Gdy się Chrystus rodzi - wykonanie Tosia Rojek
                  </li>
                  <li>
                    4. Przybieżeli do Betlejem - wykonanie Tosia Rojek
                  </li>
                  <li>
                    5. Feliz Navidad - wykonanie Tosia Rojek
                  </li>
                </ol>
                <br/>
              </p>
            </li>

            <li>
              <span role="img" aria-label="">➡ </span>
              <strong>Szkoły muzycznej Yamaha w Sędziszowie Małopolskim:</strong>
              <br/>
              <a href="https://www.facebook.com/profile.php?id=100069575766513" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100069575766513</a>
              <br/>
              <p>
                Uczniów do występu przygotowali: Pani Paulina Książek i Pan Damian Pazdan <br/>
                <ol>
                  <li>
                    1. <strong>Emilia Adler</strong> - ma 18 lat jest wokalistką i multiinstrumentalistką uczy się w klasie
                    wokalu, gitary elektrycznej i basowej, zagra na fortepianie i zaśpiewa utwór pt." WINTER
                    SONG"
                  </li>
                  <li>
                    2. <strong>Kacper Osiadły</strong> to niezwykle wrażliwy i muzykalny młody pianista ma 15 lat, wykona
                    utwór pt:" CAROL OF THE BELLS"
                  </li>
                </ol>
              </p>
              <br/>
            </li>
            <li>
              <span role="img" aria-label="">➡ </span>
              <strong>Zespołu Szkół im. Księdza Doktora Jana Zwierza w Ropczycach</strong>
              <br/>
              <a href="https://www.facebook.com/profile.php?id=100069575766513" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100069575766513</a>
              <br/>
              <p>
                Uczniów do występu przygotowała : pani Joanna Froń i Aneta Dylsk <br/>
                <ol>
                  <li>
                    1. Kolęda dla nieobecnych - wykonanie: Katarzyna Walczyk, Karolina Stanek
                  </li>
                  <li>
                    2. Kołysanka Józefa - wykonanie: Konrad Kujda
                  </li>
                  <li>
                    3. Uciekali uciekali - wykonanie: wszyscy
                  </li>
                  <li>
                    4. Gdy śliczna panna - wykonanie: Natalia Saj
                  </li>
                  <li>
                    5. Maleńka przyszła miłość - wykonanie: wszyscy
                  </li>
                  <li>
                    6. Pastorałka od serca do ucha - wykonanie: Emilia Suchy
                  </li>
                </ol>
              </p>
            </li>
          </ul>
          <br/>
          <br/>
          <p>
            Wszystkie wykonania były świetnie przygotowane, a dzieciaki dały z siebie wszystko,
          </p>
        </>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
          <Image fluid={images.img_12.childImageSharp.fluid}/>,
          <Image fluid={images.img_16.childImageSharp.fluid}/>,
          <Image fluid={images.img_14.childImageSharp.fluid}/>,
          <Image fluid={images.img_13.childImageSharp.fluid}/>,
          <Image fluid={images.img_15.childImageSharp.fluid}/>,
          <Image fluid={images.img_17.childImageSharp.fluid}/>,
          <Image fluid={images.img_18.childImageSharp.fluid}/>,
          <Image fluid={images.img_19.childImageSharp.fluid}/>,
        ],
      },
    },
  };
};

