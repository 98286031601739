import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_19 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Radość w Centrum Zabaw Fantazja!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "11 / 02 / 2022",
    description:
                      "Dziś cała nasza uwaga skupiła się szczególnie na naszych najmłodszych podopiecznych!",
    slug:             "/wydarzenia/radosc-w-centrum-zabaw-fantazja",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 Dziś cała nasza uwaga skupiła się szczególnie na naszych najmłodszych podopiecznych! ☺️
                 Wspólnie wybraliśmy się do centrum zabaw. Fajnie było oderwać się na chwilę od rzeczywistości i spędzić dzień w Fantazji 😎
                 Wiktoria, Filip, Daniel i Wiktor świetnie się bawili, a uśmiechy nie schodziły im z twarzy przez cały dzień! 🥰
               </>
      },
    },
    images:           {
      first:  {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
      second: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
