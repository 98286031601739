import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_28 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Pomoc dla dzieci z Ukrainy",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "24 / 03 / 2022",
    description:      "Nasza fundacja zaangażowała się w pomoc dzieciom z Ukrainy!",
    slug:             "/wydarzenia/pomoc-dla-dzieci-z-ukrainy",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: null,
        text:  <>
                 <p>Nasza Fundacja zaangażowała się również w pomoc dzieciom z Ukrainy 🇺🇦</p>
               </>
      },
      second: {
        title: null,
        text:  <>
                 <p>Chcemy, by nasze wsparcie było długoterminowe, dlatego stworzyliśmy magazyn z następującymi rzeczami:</p>
                 <ul>
                   <li>➖ &nbsp;&nbsp;Paczki dla dzieci w wieku 0-15 lat, a w nich ubrania dobrane pod konkretny wiek i płeć</li>
                   <li>➖ &nbsp;&nbsp;Książki edukacyjne wspomagające proces uczenia się języka polskiego</li>
                   <li>➖ &nbsp;&nbsp;Słodycze, zabawki i gry</li>
                   <li>➖ &nbsp;&nbsp;Pampersy, śpiworki oraz akcesoria dla najmłodszych dzieci</li>
                 </ul>
               </>
      },
      third:  {
        title: null,
        text:  <>
                 <p>Paczki regularnie przekazujemy potrzebującym rodzinom. <br/>Jeśli potrzebujesz wsparcia, prosimy o kontakt, a z przyjemnością przekażemy Ci taką paczkę!</p>
                 <br/>
                 <a href="mailto:biuro@fundacjaswiatbezbarier.pl">✉️ &nbsp;&nbsp;biuro@fundacjaswiatbezbarier.pl</a>
                 <br/>
                 <a href="tel:693380754">☎️ &nbsp;&nbsp;693380754</a>
               </>
      },
      fourth: {
        title: ' Jeśli chcesz pomóc, możesz zostać darczyńcą. Przyjmujemy rzeczy takie jak: ',
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;książki edukacyjne</li>
                   <li>➖ &nbsp;&nbsp;żywność długoterminowa</li>
                   <li>➖ &nbsp;&nbsp;leki</li>
                   <li>➖ &nbsp;&nbsp;żywność dla dzieci</li>
                   <li>➖ &nbsp;&nbsp;słodycze</li>
                   <li>➖ &nbsp;&nbsp;pampersy</li>
                 </ul>
                 <br/>
                 <p>▪️ &nbsp;&nbsp;W razie jakichkolwiek pytań służymy pomocą!</p>
                 <p>📌 &nbsp;&nbsp;<b>Punkt odbioru: Rzeszów, ul. Przemysłowa 14b</b>🏠</p>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
  }
}

