import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_27 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Szukamy pełnych zapału korepetytorów",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "22 / 03 / 2022",
    description:      "Nasi podopieczni z Rzeszowa potrzebują pomocy w nauce. Dlatego szukamy osób, które chciałyby ich wesprzeć w tym zakresie",
    slug:             "/wydarzenia/szukamy-korepetytorow",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: null,
        text:  <>
                 <p>Jak już wiecie, nasza Fundacja kieruje się 3 podstawowymi zasadami: POMOC, MOTYWACJA i WSPARCIE ❤️</p>
                 <p>Aktualnie nasi podopieczni z Rzeszowa potrzebują pomocy w nauce. Dlatego szukamy osób, które chciałyby ich wesprzeć w tym zakresie.</p>
               </>
      },
      second: {
        title: 'Do Twoich obowiązków będzie należało:',
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;pomaganie w odrabianiu zadań domowych</li>
                   <li>➖ &nbsp;&nbsp;Wyjaśnianie bieżącego materiału z zakresu szkoły podstawowej i technikum.</li>
                 </ul>
               </>
      },
      third:  {
        title: 'Kto może pomóc? Każdy, kto:',
        text:  <>
                 <ul>
                   <li>➖ &nbsp;&nbsp;jest cierpliwy</li>
                   <li>➖ &nbsp;&nbsp;posiada wiedzę z przedmiotów takich jak: fizyka, chemia, biologia, matematyka, język angielski, język polski</li>
                   <li>➖ &nbsp;&nbsp;potrafi przekazać wiedzę w zrozumiały i jasny sposób</li>
                   <li>➖ &nbsp;&nbsp;ma dobre podejście do dzieci</li>
                 </ul>
               </>
      },
      fourth: {
        title: null,
        text:  <>
                 <p><b> Zachęcamy również STUDENTÓW - istnieje możliwość odbycia praktyk zawodowych 🌟</b></p>
                 <br/>
                 <p>Jeśli chcesz zostać korepetytorem i okazać naszym podopiecznym wsparcie edukacyjne, zgłoś się do nas, a razem ustalimy plan działania!
                 </p>
                 <br />
                 <p><b>🏠 &nbsp;&nbsp;RZESZÓW</b></p>
                 <a href="mailto:biuro@fundacjaswiatbezbarier.pl">✉️ &nbsp;&nbsp;biuro@fundacjaswiatbezbarier.pl</a>
                 <br/>
                 <a href="tel:693380754">☎️ &nbsp;&nbsp;693380754</a>
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
    },
  }
}
