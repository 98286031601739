import {graphql, useStaticQuery} from "gatsby"

export const useArticleImages = () => {
    return useStaticQuery(graphql`
      query {
          thumb: file(relativePath: { eq: "img/Blog/article_35/thumb.png" }) {
              childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          event: file(relativePath: { eq: "img/Blog/article_35/event.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img_1: file(relativePath: { eq: "img/Blog/article_35/img_1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          } 
             img_2: file(relativePath: { eq: "img/Blog/article_35/img_2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
             img_3: file(relativePath: { eq: "img/Blog/article_35/img_3.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
             img_4: file(relativePath: { eq: "img/Blog/article_35/img_4.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
             img_5: file(relativePath: { eq: "img/Blog/article_35/img_5.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
             img_6: file(relativePath: { eq: "img/Blog/article_35/img_6.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }                                                  
      }
  `)
}
