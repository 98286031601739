import React from "react"
import { PrimaryButton } from "components/Buttons"

import {
  Container,
  Wrapper,
  ButtonWrapper,
  SlideWrapper,
  ImageBox,
  TextBox,
  Date,
  Title,
  Hover,
} from "./styles"

import { Swiper, SwiperSlide } from "swiper/react"
import useNewsData from "../../shared/Hooks/News/useNewsData"
import { HeaderWithNav } from "components/HeaderWithNav"
import { TitleSvg } from "components/TitleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import CircleSvg from "assets/img/PageHeader/o.svg"

const options = {
  speed: 1000,
  spaceBetween: 60,
  loop: true,
  slidesPerView: 3,
  initialSlide: 1,
  navigation: {
    nextEl: ".next-event",
    prevEl: ".prev-event",
  },
  autoplay: {
    delay: 8000,
  },
  breakpoints: {
    1920: {
      slidesPerView: 3,
    },
    1080: {
      spaceBetween: 30,
    },
    600: {
      spaceBetween: 30,
      slidesPerView: 2,
    },
    120: {
      spaceBetween: 30,
      slidesPerView: 1,
    },
  },
}

export const NewsSlider = ({ title }) => {
  const data = useNewsData()
  const data_array = Object.values(data)

  return (
    <Container>
      <Wrapper>
        <HeaderWithNav
          align="left"
          title={title}
          prevClass="prev-event"
          nextClass="next-event"
          length={data_array.length} //
        />
        <Swiper {...options}>
          {data_array.reverse().map((item, i) => (
            <SwiperSlide key={i}>
              <SlideWrapper to={item.slug}>
                <ImageBox>
                  {item.detail_image}
                  <Hover>Zobacz</Hover>
                </ImageBox>
                <TextBox>
                  <Date>{item.date}</Date>
                  <Title>{item.title}</Title>
                </TextBox>
              </SlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
        <ButtonWrapper>
          <PrimaryButton to="/aktualnosci" type="right_arrow">
            Zobacz wszystkie wydarzenia
          </PrimaryButton>
        </ButtonWrapper>

        <TitleSvg image={CircleSvg} bottom="-1%" right="10%" duration="5s" />
        <TitleSvg
          image={PipeSvg}
          bottom="10%"
          left="15%"
          duration="3s"
          rotation="60deg"
        />
        <TitleSvg
          image={PlusSvg}
          top="-5%"
          right="32%"
          duration="3s"
          rotation="50deg"
        />
      </Wrapper>
    </Container>
  )
}
