import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  text-align: ${({ align }) => (align ? align : "center")};
  position: relative;
  margin-bottom: 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 48px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    text-align: center !important;
  }
`
