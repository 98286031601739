import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_8 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Prawo Jazdy dla Oli  ",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "11 / 08 / 2021",
    description:
                      "Dzięki funduszom, uzyskanym od naszych darczyńców, udało się opłacić kurs prawa jazdy dla Oli.",
    slug:             "/wydarzenia/kurs-prawa-jazdy-oli",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: '',
        text:  <>Teraz trzymamy kciuki, żeby egzamin zdała za pierwszym razem! <br/>
          <strong> (EDIT : Otrzymaliśmy informacje, że Ola zdała za pierwszym razem, Gratulacje !)</strong>
               </>
      }
    },
    lists:            {
      first: {
        title: "",
        items: [
          'Dzięki funduszom, uzyskanym od naszych darczyńców, udało się opłacić kurs prawa jazdy dla Oli',
          <>Było to jej marzeniem, aby zostać kierowcą i stać się bardziej samodzielną <span role="img" aria-label="face with glasses emoji">😎</span></>,
        ],
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
