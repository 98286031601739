import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_18 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Wyjście do Fly Wall",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "10 / 02 / 2022",
    description:
                      "Dziś zabraliśmy Julę, Elę, Filipa i Karola na ściankę wspinaczkową",
    slug:             "/wydarzenia/wyjscie-do-fly-wall",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "",
        text:  <>
                 Dziś zabraliśmy Julę, Elę, Filipa i Karola na ściankę wspinaczkową 🧗‍♂️<br/>
                 To było bardzo intensywnie spędzone popołudnie, ale przede wszystkim - wspaniały czas dla naszych podopiecznych! Takie zajęcia nie tylko sprawiają radość, a również uczą dyscypliny, wytrzymałości oraz pokory.
                 Dlatego naszym planem są regularne wyjścia z dziećmi na tego typu atrakcje. Sprawiają im ogromną frajdą, a ich radość jest bezcenna!🥰
                 Z całego serca prosimy więc o Wasze wsparcie! ❤️
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          }, {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
