import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_12 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Podziękowania za zaangażowanie w Akcję Mikołaj 2021",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "16 / 12 / 2021",
    description:
                      "Podsumowanie akcji Mikołajki 2021",
    slug:             "/wydarzenia/podziekowania-za-akcje-mikolaj-2021",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: '',
        text:  <><span role="img" aria-label="heart emoji">❤️</span> Dziękujemy bardzo za zrzutkę <span role="img" aria-label="smiling face emoji with closed eyes">😁</span> Za okazane wsparcie i zaufanie Jesteście wielcy!</>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
    lists:            {
      first: {
        title: "",
        items: [
          <>Podziękowania zdecydowanie należą się wspaniałym osobom, które poświęciły swój czas i bardzo postarały się, aby kupić i przygotować prezenty dla naszych podopiecznych <span role="img" aria-label="present emoji">🎁</span> </>,
          <>
            Taka forma pomocy jest wspaniała. Bardzo cenimy tak indywidualne zaangażowanie. Wasze prezenty przekazane dzieciom sprawiły ogromną radość! <span role="img" aria-label="smiling face emoji with closed eyes">😃</span>
            <br/>
            Szczególne podziękowania należą się Ewelince, Asi i Asi, Kubie, Klaudii i Pawłowi, Grzesiowi i Andżelice, Patrykowi, Rafałowi, Patrycji i Jarkowi, Bogusi i Mariuszowi oraz Ewci i Piotrkowi 🙏🏻
          </>,
          <>Chcielibyśmy również bardzo podziękować wszystkim, którzy przyczynili się do sprawienia radości naszym Podopiecznym poprzez finansowe wsparcie! <span role="img" aria-label="praying emoji"> 🙏🏻</span></>,
          <>To bez znaczenia, czy kwota przez Was przeznaczona była duża czy mała - dla nas liczy się każdy gest. Bo każdy - nawet ten najdrobniejszy - pozwala nam działać i tworzyć Świat Bez Barier <span role="img" aria-label="face with hearts emoji"> 🥰</span></>
        ],
      },
    },
  }
}
