import {useLocation} from "@reach/router"

import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_17 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Nasze cele na rok 2022!",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "31 / 01 / 2022",
    description:
                      "Co chcemy osiągnąć w tym roku? Oto nasz plan",
    slug:             "/wydarzenia/nasze-cele-na-rok-2022",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: "Wkraczamy w nowy rok z nowymi celami❗️",
        text:  <>
                 ️Co chcemy osiągnąć w tym roku? Oto nasz plan 👇<br/>
                 Pozyskanie funduszy na kursy dla dzieci - Sandra marzy o kursie wokalnym, Julia o kursie plastycznym, a lista zainteresowań naszych podopiecznych jest o wiele dłuższa 📜
                 Wysłanie dzieciaków na tematyczne kolonie letnie - przybliżony koszt jednego uczestnika to 1600 zł 💸 <br/>
                 Już wiele razy pokazaliście swoje wielkie serce, dlatego i tym razem liczymy na Waszą pomoc ❤️
               </>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}
