import {useLocation} from "@reach/router"

import FbIcon      from "assets/img/svg/fb.svg"
import LinkedIcon  from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image       from "gatsby-image"
import React       from "react"

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink}                                                    from "sections/News/NewsLayout/styles.js"
import {useArticleImages}                                              from "./ArticleImages";

export const News_11 = () => {
  const images = useArticleImages();
  const location = useLocation();

  return {
    title:            "Listy do Mikołaja",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "24 / 11 / 2021",
    description:
                      "Razem z nami sprawcie, aby 6 grudnia nasi podopieczni poczuli się wyjątkowo!",
    slug:             "/wydarzenia/listy-do-swietego-mikolaja",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: '',
        text:  <>Razem z nami sprawcie, aby 6 grudnia nasi podopieczni poczuli się wyjątkowo! ❤️
          <br/>
          Dołożymy wszelkich starań, aby każde dziecko dostało to, o czym marzy, lecz potrzebujemy do tego Waszej pomocy 🙏🏻
               </>
      },
    },
    videos: {
      first: {
        title: '',
        src: 'https://www.youtube.com/embed/f1lFZxm93z0',
      }
    },
    lists:            {
      first: {
        title: "",
        items: [
          'Nie ma nic bardziej wzruszającego niż dziecięce listy do Świętego Mikołaja ',
          'Zobaczcie fIlmik, na którym przeczytaliśmy marzenia naszych podopieczych',
        ],
      },
    },
  }
}
