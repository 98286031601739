import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_36 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Nowi członkowie Fundacji",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "21 / 04 / 2022",
        description: "Z radością informujemy, że nasza Fundacja powiększyła się o kilku nowych członków. Mamy aż 4 nowych wolontariuszy!",
        slug: "/wydarzenia/nowi-wolontariusze",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span> Z radością informujemy, że nasza Fundacja powiększyła się
                        o kilku nowych członków. Mamy aż 4 nowych wolontariuszy! <span role='img'
                                                                                       aria-label=''>😊</span>
                    </p>
                </>
            },
            second: {
                title: '',
                text: <p>
                    <span role='img' aria-label=''>🟣</span> Iza poświęca swój wolny czas i dwa razy w tygodniu odwiedza
                    ośrodek w Rzeszowie. Pomaga naszym podopiecznym w lekcjach oraz prowadzi z nimi różne plastyczne
                    zajęcia. Dziękujemy Ci bardzo za to, że dzięki Tobie i z Tobą nasi podopieczni wspaniale spędzają
                    czas! <span role='img' aria-label=''>❤</span>️
                </p>
            },
            third: {
                title: '',
                text: <>
                    <p>
                        <span role='img' aria-label=''>🟡</span> Agnieszka, Anna i Agnieszka zgłosiły się do nas, aby
                        pomóc w prowadzeniu lekcji języka polskiego i już zaczynają zajęcia ze swoimi grupami osób z
                        Ukrainy. Dziękujemy serdecznie za Waszą piękną inicjatywę <span role='img'
                                                                                        aria-label=''>🤗</span> <br/>
                        <br/>
                        <span role='img' aria-label=''>▪</span> Z całego serca dziękujemy wszystkim, którzy pomagają nam
                        w tworzeniu naszej społeczności <span role='img' aria-label=''>😊</span>
                    </p>
                </>

            },
            fourth: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>✉️</span> <a
                        href="mailto: biuro@fundacjaswiatbezbarier.pl">biuro@fundacjaswiatbezbarier.pl</a> <br/>
                        <span role='img' aria-label=''>☎️</span> <a href="tel:693380754 ">693380754</a> <br/>
                    </p>
            },
        },
        images:           {
            first: {
                show:  true,
                full:  true,
                image: [
                    {
                        image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
                        description: "",
                    }
                ],
            },
        },
    }
}

