import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_30 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Wyjście do Muzeum Dobranocek",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "29 / 03 / 2022",
    description:      "Nasze piątkowe wyjścia, w których całą uwagę skupiamy na podopiecznych Fundacji, stały się już naszą tradycją!",
    slug:             "/wydarzenia/wyjsie-do-muzeum-dobranocek",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first: {
        title: null,
        text:  <>
                 <p>Nasze piątkowe wyjścia, w których całą uwagę skupiamy na podopiecznych Fundacji, stały się już tradycją 💜 W tym tygodniu był to czas dla tych najmłodszych 🤗</p>
                 <p>Z inicjatywy Patrycji, której serdecznie dziękujemy, wybraliśmy się odkrywać magiczny świat bajek w naszym rzeszowskim Muzeum Dobranocek 🧸 Miejsce to sprawiło ogromną radość zarówno dzieciakom, jak i nam. W końcu sami kiedyś z niecierpliwością czekaliśmy na ulubione bajki i bardzo miło było powrócić wspomnieniami do beztroskiego czasu dzieciństwa 😊</p>
                 <p>A dzieciaki? Ich radość z każdego wyjścia jest bezcenna! 🥰 Nic tak nie motywuje do działania, jak widok naszych podopiecznych, którzy z wielkim uśmiechem w każdy piątek równo o 16:00 czekają przy drzwiach gotowi do wyjścia. Dzięki temu widzimy, że to, co robimy, ma ogromny sens ❤️</p>
                 <p>Każde wyjście łączy się z wydatkami finansowymi, które pokrywami dzięki Waszemu wsparciu. Jeśli chcesz nam pomóc, prosimy o nawet najdrobniejsze wpłaty: <a href="https://wsparcie.fundacjaswiatbezbarier.pl" target="_blank">wsparcie.fundacjaswiatbezbarier.pl</a>💕</p>
               </>
      },
    },
    images:           {
      first:  {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.img_1.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      },
      second: {
        show:  true,
        full:  false,
        image: [
          {
            image:       <Image fluid={images.img_2.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_3.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_4.childImageSharp.fluid}/>,
            description: "",
          },
          {
            image:       <Image fluid={images.img_5.childImageSharp.fluid}/>,
            description: "",
          },
        ],
      },
    },
  }
}

