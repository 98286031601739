import { useLocation } from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { StickyLink } from 'sections/News/NewsLayout/styles.js';
import { useArticleImages } from './ArticleImages';

export const News_61 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Alternatywne formy wsparcia',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '07 / 03 / 2023',
    description: 'Chcesz wesprzeć działania naszej Fundacji inaczej niż finansowo? To bardzo łatwe! Jako Fundacja Świat Bez Barier bierzemy udział w trzech akcjach, dzięki którym możesz nas wesprzeć w alternatywny sposób.',
    slug: '/wydarzenia/alternatywne-formy-wsparcia',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: '',
        text:
          <>
            <p>
              Chcesz wesprzeć działania naszej Fundacji inaczej niż finansowo?
              <br/>
              To bardzo łatwe! Jako <strong>Fundacja Świat Bez Barier</strong> bierzemy udział w trzech akcjach, dzięki którym możesz nas wesprzeć w alternatywny sposób.
            </p>
            <br/>
            <p>
              1. Ubrania do oddania:
              <a href="https://www.ubraniadooddania.pl/campaigns/1356" rel="noreferrer" target="_blank"> https://www.ubraniadooddania.pl/campaigns/1356</a>

              <br/>
              Wejdź na <a href="http://www.ubraniadooddania.pl" target="_blank" rel="noreferrer">http://www.ubraniadooddania.pl</a> i załóż tam swoje konto.
              <br/>
              Spakuj ubrania i tekstylia, których już nie potrzebujesz w kartony.
              <br/>
              Kurier za darmo odbierze od Ciebie spakowane paczki, Ty pozbędziesz się niepotrzebnych ubrań, a my dzięki temu otrzymamy wsparcie finansowe na nasze konto.
              <br/>
              Każdy 𝟭 <strong>kg</strong> przekazanych ubrań to 𝟭 zł dla Fundacji <span role="img" aria-label=""> 💜 </span> Pamiętaj jednak, że karton musi mieć minimalną wagę: 10kg.
              <br/> <br/>
              2. Fani Mani:
              <a href="https://fanimani.pl/fundacjaswiatbezbarier/" rel="noreferrer" target="_blank"> https://fanimani.pl/fundacjaswiatbezbarier/</a>
              <br/>
              Zainstaluj w swoim telefonie aplikację mobilną <strong>FaniMani</strong>, wybierz Fundację Świat Bez Barier jako organizację, którą chcesz wesprzeć i przejdź z niej przed zakupami prosto do sklepu, w którym chcesz dokonać zakupu.
              <br/>
              → Jeśli zakupy robisz na komputerze, wystarczy, że dodasz rozszerzenie <strong>Przypominajka FaniMani</strong> do swojej przeglądarki.
              <br/> <br/>
              <span role="img" aria-label="">🔺 </span> Korzystanie z aplikacji jest zupełnie darmowe. Nic nie dopłacasz ponad kwotę zakupów. Dzięki wtyczce, sklep przekazuje odpowiedni procent od zakupów naszej Fundacji, a my za pozyskane środki tworzymy lepszy świat dla naszych podopiecznych <span role="img" aria-label=""> 🥰</span>
              <br/> <br/>
              3. Zbiórka plastikowych nakrętek
              <br/>

              <span role="img" aria-label="">🔺 </span> Zorganizuj w swoim domu, miejscu pracy, szkole zbiórkę plastikowych nakrętek dla Fundacji Świat Bez Barier.
              <br/>
              Jeśli uda Ci się uzbierać większą liczbę nakrętek - my odbierzemy je od Ciebie i zawieziemy do skupu.
              <br/>
              TERMIN ODBIORU: 14 kwietnia 2023 <br/>
              Potrzebujesz plakatu, którym wypromujesz zbiórkę? Skontaktuj się z nami :)
            </p>
          </>
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },
  };
};

