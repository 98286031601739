import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_50 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title:            "Podsumowanie wydarzenia w Fosie",
    hero_image:       null,
    detail_image:     <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
    date:             "02 / 09 / 2022",
    description:      "Jedną z najpiękniejszych zalet działania w Fundacji jest to, że możemy spotkać cudownych ludzi, którzy oddają innym cząstkę siebie - zupełnie bezinteresownie! ",
    slug:             "/wydarzenia/podsumowanie-wydarzenia-w-fosie",
    share:            [
      {
        button: (
                  <FacebookShareButton url={location.href}>
                    <StickyLink icon={FbIcon}/>
                  </FacebookShareButton>
                ),
      },
      {
        button: (
                  <LinkedinShareButton url={location.href}>
                    <StickyLink icon={LinkedIcon}/>
                  </LinkedinShareButton>
                ),
      },
      {
        button: (
                  <TwitterShareButton url={location.href}>
                    <StickyLink icon={TwitterIcon}/>
                  </TwitterShareButton>
                ),
      },
    ],
    texts:            {
      first:  {
        title: <></>,
        text:
               <p>
                 <span role="img" aria-label=''>▪</span>️️ Jedną z najpiękniejszych zalet działania w Fundacji jest to, że możemy spotkać cudownych ludzi, którzy oddają innym cząstkę siebie - zupełnie bezinteresownie! <span role="img" aria-label=''>❤️</span>
               </p>
      },
      second: {
        title: <></>,
        text:
               <p>
                 28 sierpnia 2022 roku organizowaliśmy wydarzenie w rzeszowskiej FOSIE - <a href="https://www.facebook.com/FOSA-103668981438970/" rel="noreferrer" target="_blank">https://www.facebook.com/FOSA-103668981438970/</a> - to był dzień pełen wrażeń! Zarówno dla najmłodszych, jak i tych nieco starszych <span role="img" aria-label=''>😄️</span> <br/> <br/>
                 → Od godziny 12 do godziny 16 odbywały się animacje dla dzieci, które poprowadził niezawodny Paweł z B3Team - <a href="https://www.facebook.com/B3TeamPL/" rel="noreferrer" target="_blank">https://www.facebook.com/B3TeamPL/</a> <span role="img" aria-label=''>💪🏻</span> <br/> <br/>
                 Na najmłodszych czekało m.in. morze baniek mydlanych, przeciąganie liny, strzelanie do lewitujących kulek, a nawet zabawa w nadmuchiwanych kulach Bumper Ball <span role="img" aria-label=''>🤩</span> <br/> <br/>

                 Z atrakcji skorzystała także czwórka naszych podopiecznych <span role="img" aria-label=''>😊</span> <br/>
                 <span role="img" aria-label=''>▪</span>️️️ Dzieci bawiły się świetnie, a rodzice mogli odpocząć na wygodnych leżakach, popijając lemoniadę <span role="img" aria-label=''>🍋</span>️️ <br/>
                 To był naprawdę udany dzień! <span role="img" aria-label=''>☀️</span>️️
               </p>
      },
      third:  {
        title: <>Wieczorem natomiast przyszedł czas na koncert… <span role="img" aria-label=''>🎸</span></>,
        text:
               <p>
                 → Bartłomiej 'Gibson' Filip <a href="https://www.facebook.com/profile/100076465104314" target="_blank" rel="noreferrer">(https://www.facebook.com/profile/100076465104314)</a> - dziękujemy Ci za ten magiczny wieczór 🖤 <br/> <br/>
                 Nie mogliśmy go sobie lepiej wymarzyć! <br/>
                 A jeśli jeszcze nie znacie twórczości Bartka - koniecznie sprawdźcie! Nas zaczarował swoją interpretacją utworów - <a href="https://www.youtube.com/channel/UC2PfP_ego-mOxRQy43-jjuw." target="_blank" rel="noreferrer">https://www.youtube.com/channel/UC2PfP_ego-mOxRQy43-jjuw.</a> <br/>

                 <a href="intro.media" target="_blank" rel="noreferrer">Intro.media</a> - dziękujemy za zdjęcia i relacje z wydarzenia.
               </p>
      },
      fourth: {
        title: <><span role="img" aria-label=''>📸</span> Autorzy fotografii:</>,
        text:
               <p>
                 Zdjęcia na miniaturach oraz główne zdjęcie w artykule: <br/>
                 VINCENT PRZYWARA (Intro.media) - <a href="www.instagram.com/vincent_v_photo/" rel="noreferrer" target="_blank">www.instagram.com/vincent_v_photo/</a> <br/>
                 Pozostałe zdjęcia: M. MAŁEK, P. BILSKA, J. ŻMUDA <br/> <br/>

                 Zajrzyjcie też na nasze media społecznościowe: <br/>
                 → Facebook: <a href="https://www.facebook.com/FundacjaSwiatBezBarier" rel="noreferrer" target="_blank">https://www.facebook.com/FundacjaSwiatBezBarier</a>< br/>
                 → Instagram (sprawdźcie relacje wyróżnione): <a href="https://www.instagram.com/fundacjaswiatbezbarier/" rel="noreferrer" target="_blank">@fundacjaswiatbezbarier</a>
               </p>
      },
    },
    images:           {
      first: {
        show:  true,
        full:  true,
        image: [
          {
            image:       <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: "",
          }
        ],
      }
    },

    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
          <Image fluid={images.img_2.childImageSharp.fluid}/>,
          <Image fluid={images.img_3.childImageSharp.fluid}/>,
          <Image fluid={images.img_4.childImageSharp.fluid}/>,
          <Image fluid={images.img_5.childImageSharp.fluid}/>,
          <Image fluid={images.img_6.childImageSharp.fluid}/>,
          <Image fluid={images.img_7.childImageSharp.fluid}/>,
          <Image fluid={images.img_8.childImageSharp.fluid}/>,
          <Image fluid={images.img_9.childImageSharp.fluid}/>,
          <Image fluid={images.img_10.childImageSharp.fluid}/>,
          <Image fluid={images.img_11.childImageSharp.fluid}/>,
        ]
      },
    }
  }
}

