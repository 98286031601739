import {useLocation} from "@reach/router"
import FbIcon from "assets/img/svg/fb.svg"
import LinkedIcon from "assets/img/svg/linkedin.svg"
import TwitterIcon from "assets/img/svg/tw.svg"
import Image from "gatsby-image"
import React from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from "react-share"
import {StickyLink} from "sections/News/NewsLayout/styles.js"
import {useArticleImages} from "./ArticleImages";

export const News_34 = () => {
    const images = useArticleImages();
    const location = useLocation();
    return {
        title: "Współpraca z siłownią CoolGym - Coolturysta",
        hero_image: null,
        detail_image: <Image fluid={images.thumb.childImageSharp.fluid}/>,
        home_event_image: <Image fluid={images.event.childImageSharp.fluid}/>,
        date: "15 / 04 / 2022",
        description: "Nasza współpraca z Siłownia CoolGym - Coolturysta trwa już od jakiegoś czasu, ale tak naprawdę nie mogliśmy realnie działać przez trwającą prawie 2 lata pandemię. Na szczęście od tego roku możemy już współpracować w pełnym zakresie.",
        slug: "/wydarzenia/wspolpraca-z-silownia-coolgym-coolturysta",
        share: [
            {
                button: (
                    <FacebookShareButton url={location.href}>
                        <StickyLink icon={FbIcon}/>
                    </FacebookShareButton>
                ),
            },
            {
                button: (
                    <LinkedinShareButton url={location.href}>
                        <StickyLink icon={LinkedIcon}/>
                    </LinkedinShareButton>
                ),
            },
            {
                button: (
                    <TwitterShareButton url={location.href}>
                        <StickyLink icon={TwitterIcon}/>
                    </TwitterShareButton>
                ),
            },
        ],
        texts: {
            first: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>▪</span>&nbsp;Nasza współpraca z <a
                        href="https://www.facebook.com/coolturystagym/" target='_blank' rel='noopener'>Siłownia
                        CoolGym -
                        Coolturysta</a> trwa już od jakiegoś czasu, ale tak naprawdę nie mogliśmy realnie działać przez
                        trwającą prawie 2 lata pandemię. Na szczęście od tego roku możemy już współpracować w pełnym
                        zakresie! <span role='img' aria-label=''>😊</span> <br/> <br/>
                        <span role='img' aria-label=''> → </span>Tomek to właściciel siłowni Cool Gym w Rzeszowie. Jest
                        bardzo pozytywną osobą o wielkim serduszku, bardzo zaangażowaną w działania naszej
                        fundacji <span role='img' aria-label=''>❤️</span>
                    </p>
                </>
            },
            second: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''> 🟣</span> Tomek całkowicie non profit wziął pod swoje skrzydła
                        naszych podopiecznych i organizuje dla nich treningi na swojej siłowni. A w końcu nie ma nic
                        lepszego dla zdrowia jak dobra i odpowiednio ukierunkowana aktywność fizyczna <span role='img'
                                                                                                            aria-label=''> 💪</span>
                    </p>
                </>
            },
            third: {
                title: '',
                text: <>
                    <p><span role='img' aria-label=''>→</span> Cool Gym, bardzo dziękujemy za ogromne zaangażowanie i
                        opiekę nad naszymi podopiecznymi. Ta współpraca udowadnia nam, że działając wspólnymi lokalnymi
                        siłami, możemy tworzyć wspaniałe projekty! <span role='img' aria-label=''>🤗</span>
                    </p>
                </>

            },
            fourth: {
                title: '',
                text:
                    <p>
                        <span role='img' aria-label=''>▪</span> Chcesz wesprzeć Fundację Świat Bez Barier? Na siłowni
                        znajdziesz skarbonkę, do której możesz wrzucić dobrowolne datki. Wykorzystamy je w 100% na
                        potrzeby naszych podopiecznych! <span role='img' aria-label=''>😊</span>
                    </p>
            },
            fifth: {
                title: '',
                text: <>
                    <p>
                        <span role='img' aria-label=''>➖</span> <br/>
                        <span role='img' aria-label=''>✉️</span> <a
                        href="mailto: biuro@fundacjaswiatbezbarier.pl">biuro@fundacjaswiatbezbarier.pl</a> <br/>
                        <span role='img' aria-label=''>☎️</span> <a href="tel:693380754 ">693380754</a> <br/>
                    </p>
                </>
            },
        },
    }
}

