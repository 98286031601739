import {useLocation} from '@reach/router';
import FbIcon from 'assets/img/svg/fb.svg';
import LinkedIcon from 'assets/img/svg/linkedin.svg';
import TwitterIcon from 'assets/img/svg/tw.svg';
import Image from 'gatsby-image';
import React from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {StickyLink} from 'sections/News/NewsLayout/styles.js';
import {useArticleImages} from './ArticleImages';

export const News_53 = () => {
  const images = useArticleImages();
  const location = useLocation();
  return {
    title: 'Charytatywny Bieg \'Piątka dla Fundacji\'',
    hero_image: null,
    detail_image:
      <Image fluid={images.thumb.childImageSharp.fluid}/>,
    home_event_image:
      <Image fluid={images.event.childImageSharp.fluid}/>,
    date: '19 / 10 / 2022',
    description: 'Już 22 października razem z Parkrun Rzeszów oraz PODIUM organizujemy Charytatywny Bieg „Piątka dla Fundacji” na dystansie 5 km.',
    slug: '/wydarzenia/charytatywny-bieg-piatka-dla-fundacji',
    share: [
      {
        button: (
          <FacebookShareButton url={location.href}>
            <StickyLink icon={FbIcon}/>
          </FacebookShareButton>
        ),
      },
      {
        button: (
          <LinkedinShareButton url={location.href}>
            <StickyLink icon={LinkedIcon}/>
          </LinkedinShareButton>
        ),
      },
      {
        button: (
          <TwitterShareButton url={location.href}>
            <StickyLink icon={TwitterIcon}/>
          </TwitterShareButton>
        ),
      },
    ],
    texts: {
      first: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">👉️</span>
            Już 22 października razem z Parkrun Rzeszów oraz PODIUM organizujemy Charytatywny Bieg „Piątka dla Fundacji” na dystansie 5 km. <br/>
            <br/>
            <span role="img" aria-label="">🟣 </span>
            Link do wydarzenia na FB: <a href="https://www.facebook.com/events/781003049857432" rel="noreferrer" target="_blank">https://www.facebook.com/events/781003049857432</a>
          </p>,
      },
      second: {
        title: <></>,
        text:
          <p>
            Aby wziąć udział w biegu i otrzymać numer startowy, należy zarejestrować się na stronie Parkrun oraz wpłacić minumum 10 zł na wsparcie Fundacji - jest to równoznaczne z zapisem na bieg i opłaceniem cegiełki.
          </p>,
      },
      third: {
        title: <></>,
        text:
          <p>
            <span role="img" aria-label="">▪ </span>
            ️ Wydarzenie rozpocznie się o godzinie 8:30 rejestracją dla osób, które nie zdążą się wcześniej zapisać. Nasi wolontariusze na miejscu startu będą wyposażeni w telefony i tablety, dzięki którym proces rejestracji przejdzie sprawnie - pomożemy każdemu. Później zrobimy krótką rozgrzewkę i przejdziemy na miejsce startu. <br/>
            <br/>
            W czasie biegu dla dorosłych odbędzie się również bieg dla dzieciaków. Dla każdego dziecka biorącego udział w biegu przewidzieliśmy słodki upominek i medal. <br/>
            <br/>
            Po biegu zapraszamy wszystkich chętnych na rodzinną jogę oraz zajęcia i zabawy sportowe dla wszystkich chętnych dzieci. <br/>
            <br/>
            Bieg dla dorosłych rozpocznie się o godzinie 9:00. <br/>
            <br/>
            <span role="img" aria-label="">▪ </span>
            ️Na mecie na każdego uczestnika będzie czekać słodki poczęstunek, gorąca kawa oraz herbata i woda.
            <br/>
            <br/>
            Aby podziękować biegaczom za okazane wsparcie dla Fundacji, wraz z partnerami wydarzenia przygotowaliśmy upominki oraz loterię z nagrodami-niespodziankami. <br/>
            <br/>
            Mamy nadzieję, że we wspólnym gronie aktywnie rozpoczniemy weekend <span role="img" aria-label="">💪 </span>
            <span role="img" aria-label="">🏁</span>
          </p>,
      },
      fourth: {
        title: 'Organizatorzy:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Fundacja Świat Bez Barier - <a href="https://www.facebook.com/FundacjaSwiatBezBarier" target="_blank" rel="noreferrer">https://www.facebook.com/FundacjaSwiatBezBarier</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Parkrun Rzeszów - <a href="https://www.facebook.com/parkrunrzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/parkrunrzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              PODIUM - <a href="https://www.facebook.com/podium.ultrastylzycia" target="_blank" rel="noreferrer">https://www.facebook.com/podium.ultrastylzycia</a>
            </li>
          </ul>,
      },
      fifth: {
        title: 'Partnerzy:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Manufaktura Smaku Patryk Mach - <a href="https://www.facebook.com/profile.php?id=100063733760824" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100063733760824</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszów Rockets - <a href="https://www.facebook.com/rzeszowrockets" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowrockets</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              ButyJana - <a href="https://www.facebook.com/butyjana" target="_blank" rel="noreferrer">https://www.facebook.com/butyjana</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Wyższa Szkoła Informatyki i Zarządzania w Rzeszowie - <a href="https://www.facebook.com/WSIiZwRzeszowie" target="_blank" rel="noreferrer">https://www.facebook.com/WSIiZwRzeszowie</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszowska Szkoła Salsy - <a href="https://www.facebook.com/rzeszowskaszkolasalsy" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowskaszkolasalsy</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Lindy dOt - <a href="https://www.facebook.com/lindydOt" target="_blank" rel="noreferrer">https://www.facebook.com/lindydOt</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Zuzove - kosmetyki naturalne - <a href="https://www.facebook.com/profile.php?id=100039929813675" target="_blank" rel="noreferrer">https://www.facebook.com/profile.php?id=100039929813675</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              JeMy Rzeszów - <a href="https://www.facebook.com/FundacjaSwiatBezBarier" target="_blank" rel="noreferrer">https://www.facebook.com/FundacjaSwiatBezBarier</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Salon Fryzjerski Lady’s D&E - <a href="https://www.facebook.com/Ladysstudio" target="_blank" rel="noreferrer">https://www.facebook.com/Ladysstudio</a>
            </li>


            <li>
              <span role="img" aria-label="">🔸</span>
              Sanomed - <a href="https://www.facebook.com/SANOMED.gabinet.rehabilitacji" target="_blank" rel="noreferrer">https://www.facebook.com/SANOMED.gabinet.rehabilitacji</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Umbrella Pole Dance Studio - <a href="https://www.facebook.com/umbrellapoledance" target="_blank" rel="noreferrer">https://www.facebook.com/umbrellapoledance</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Lord Jack - <a href="🔸 Lord Jack - https://www.facebook.com/LordJackRzeszow" target="_blank" rel="noreferrer">🔸 Lord Jack - https://www.facebook.com/LordJackRzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Ania Catering - <a href="https://www.facebook.com/aniacatering" target="_blank" rel="noreferrer">https://www.facebook.com/aniacatering</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Peron Baltoro - <a href="https://www.facebook.com/peronbaltoro.rzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/peronbaltoro.rzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Stary Browar Rzeszowski - <a href="https://www.facebook.com/BrowarRzeszowski" target="_blank" rel="noreferrer">https://www.facebook.com/BrowarRzeszowski</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Agencja Interaktywna InteractiveVision - <a href="https://www.facebook.com/interactivevision" target="_blank" rel="noreferrer">https://www.facebook.com/interactivevision</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Siłownia CoolGym - <a href="https://www.facebook.com/coolturystagym" target="_blank" rel="noreferrer">https://www.facebook.com/coolturystagym</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Kozlovna Rzeszów - <a href="https://www.facebook.com/kozlovnarzeszow" target="_blank" rel="noreferrer">https://www.facebook.com/kozlovnarzeszow</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Sylveco - <a href="https://www.facebook.com/Sylvecopl" target="_blank" rel="noreferrer">https://www.facebook.com/Sylvecopl</a>
            </li>
          </ul>,
      },
      sixth: {
        title: 'Patroni medialni:',
        text:
          <ul>
            <li>
              <span role="img" aria-label="">🔸</span>
              Super Nowości - <a href="https://www.facebook.com/SuperNowosci24" target="_blank" rel="noreferrer">https://www.facebook.com/SuperNowosci24</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Rzeszów Info - <a href="https://www.facebook.com/rzeszowinfo" target="_blank" rel="noreferrer">https://www.facebook.com/rzeszowinfo</a>
            </li>
            <li>
              <span role="img" aria-label="">🔸</span>
              Intro.Media - <a href="https://www.facebook.com/intro.mediaWSIiZ" target="_blank" rel="noreferrer">https://www.facebook.com/intro.mediaWSIiZ</a>
            </li>
          </ul>,
      },
    },
    images: {
      first: {
        show: true,
        full: true,
        image: [
          {
            image:
              <Image fluid={images.hero.childImageSharp.fluid}/>,
            description: '',
          },
        ],
      },
    },

    gallery: {
      first: {
        images: [
          <Image fluid={images.img_1.childImageSharp.fluid}/>,
        ],
      },
    },
  };
};

