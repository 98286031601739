import React from "react"

import { Container, PrevButton, NextButton } from "./styles"

export const SliderNav = ({ prevClass, nextClass, length }) => {
  return (
    <>
      {length > 2 && (
        <Container>
          <PrevButton className={prevClass} />
          <NextButton className={nextClass} />
        </Container>
      )}
    </>
  )
}
